@import "theme-variables";
@import "breakpoints";

.paginator.p-paginator {
  display: flex;
  justify-content: flex-end;
  @include respond-to(lower-than-small-screens) {
    justify-content: center;
  }
}
