@import "theme-variables";
@import "padding-margin";
@import "mixins";

/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

html {
  height: 100%;
  font-family: $root-font-family;
  font-size: $root-font-size;
  color: $primary-text-color;
}

* {
  text-size-adjust: 100%; // ios safari
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100%;

  @include font-light;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
