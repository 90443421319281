/* stylelint-disable */
@for $i from 0 through 100 {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .pt-#{$i}-i {
    padding-top: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .pr-#{$i}-i {
    padding-right: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
  .pl-#{$i}-i {
    padding-left: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
  .pb-#{$i}-i {
    padding-bottom: #{$i}px !important;
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mt-#{$i}-i {
    margin-top: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .mr-#{$i}-i {
    margin-right: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
  .mb-#{$i}-i {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .ml-#{$i}-i {
    margin-left: #{$i}px !important;
  }
}

.mt-auto {
  margin-top: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
