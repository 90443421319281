@import "theme-variables";

app-toggle-button {
  .p-togglebutton.p-button .p-button-icon-left,
  .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
    .p-button-icon-left {
    color: $primary;
  }

  .p-button.p-button-icon-only,
  .p-togglebutton.p-button.p-highlight {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;

    .pi {
      font-size: 0.75rem;
    }
  }
}
