@import "breakpoints";
@import "theme-variables";

$toast-message-error-color: #ff5757;
$lower-than-extra-large-screens-top: calc($header-height + 2rem);
$lower-than-large-screens-top: calc(
  $header-height + $sidebar-height-large-screens + 1rem
);
$lower-than-small-screens-top: calc(
  $header-height-small-screens + $sidebar-height-small-screens + 1rem
);

.p-toast {
  opacity: 1;

  &.p-toast-top-right {
    top: $lower-than-extra-large-screens-top;
    right: 2rem;

    @include respond-to(lower-than-large-screens) {
      top: $lower-than-large-screens-top;
      right: 1rem;
    }

    @include respond-to(lower-than-small-screens) {
      top: $lower-than-small-screens-top;
      width: 20rem;
    }
  }

  .p-toast-message {
    &.p-toast-message-error {
      border: solid $toast-message-error-color;
      border-width: 0 0 0 0.5rem;
      border-radius: 0.5rem;
    }

    .p-toast-message-content {
      padding: 1rem;
      align-items: center;

      .p-toast-message-icon {
        font-size: 1.5rem;
      }

      .p-toast-detail {
        margin: unset;
      }
    }
  }
}
