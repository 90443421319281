@import "theme-variables";

.p-inputtextarea {
  overflow: auto;
  padding: $space-16 $space-24;
  display: block;

  &:enabled:focus,
  &.ng-dirty.ng-invalid,
  &:enabled:focus.ng-invalid.ng-dirty {
    box-shadow: unset;
  }
}
