@import "theme-variables";
@import "breakpoints";

.html-content .ql-editor {
  img {
    max-width: 100%;
  }

  a {
    color: $primary;
  }
}

p-editor {
  .p-editor-container {
    border-radius: 2rem;
    box-shadow: $secondary-shadow;
  }

  .p-editor-container .p-editor-toolbar.ql-snow {
    padding: $space-16;
    border: none;
    background-color: $background;
    border-radius: 2rem 2rem 0 0;

    .ql-picker-label,
    .ql-bold,
    .ql-italic,
    .ql-underline,
    .ql-list,
    .ql-code-block {
      &.ql-active {
        color: $primary;

        svg .ql-stroke {
          stroke: $primary;
        }
      }
    }
  }

  .p-editor-container .p-editor-content {
    display: flex;
    justify-content: center;
    background-color: $white;
    border-radius: 0 0 2rem 2rem;

    .editor-wrapper {
      width: 100%;
      height: 16.5rem;
      overflow: hidden;
      border-radius: 0 0 2rem 2rem;

      .ql-editor {
        border: none;
        padding: $space-16 $space-24;
        font-size: 1rem;
        font-family: $root-font-family;
        border-radius: 0 0 2rem 2rem;

        &:focus {
          box-shadow: $secondary-shadow, $focus-shadow;
        }

        &::before {
          left: 1.5rem;
          right: 1.5rem;
          font: normal 1rem $root-font-family;
        }
      }
    }
  }

  .p-editor-container .p-editor-content.ql-snow {
    border: none;

    .ql-tooltip.ql-editing {
      left: unset !important;
      z-index: 1;
    }
  }
}
