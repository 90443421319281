@import "theme-variables";

.p-sidebar {
  &.p-sidebar-active {
    width: $sidebar-width;
  }

  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0 1rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
}
