@import "theme-variables";

// Sonar: "strong" and "em" tags should be used instead of "b" and "i"
// Use "span" instead of "i" for all icon
.p-input-icon-left > span {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  left: 1rem;
  color: rgb(0 0 0 / 60%);
}

.p-inputtext {
  border: none;

  &:disabled {
    opacity: unset;
    border: $input-border;
    background-color: $background;
  }

  &:enabled:focus,
  &.ng-dirty.ng-invalid,
  &:enabled:focus.ng-invalid.ng-dirty {
    box-shadow: $secondary-shadow;
  }
}

.p-error {
  font-size: 1rem;
  color: $warning;
}
