@import "breakpoints";

@mixin center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin sticky {
  position: sticky;
  z-index: 1;
}

@mixin fade-out-element($start, $end, $direction: to right) {
  mask-image: linear-gradient($direction, transparent $start, black $end);
}

@mixin fade-out-text() {
  background: linear-gradient(
    90deg,
    currentcolor calc(100% - 20px),
    rgb(0 0 0 / 10%)
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin content-layout-padding {
  padding-left: 8.5rem;
  padding-right: 8.5rem;

  @include respond-to(lower-than-extra-large-screens) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @include respond-to(lower-than-large-screens) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  @include respond-to(lower-than-small-screens) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
