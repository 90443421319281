@import "theme-variables";
@import "breakpoints";

.p-tabview {
  .p-tabview-nav {
    background: transparent;
    justify-content: flex-end;
    border-bottom: 2px solid $gray;

    @include respond-to(lower-than-small-screens) {
      justify-content: flex-start;
    }

    li {
      & .p-tabview-nav-link {
        border-bottom-color: transparent;
      }

      &,
      &.p-highlight {
        .p-tabview-nav-link {
          color: $primary-text-color;
          background: transparent;
          margin-bottom: -2px;
          padding: 0.7rem 0;
        }
      }

      &.p-highlight .p-tabview-nav-link {
        color: $primary;
        border-bottom: 2px solid $primary;
      }

      &.p-tabview-ink-bar {
        display: none;
      }

      &:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
        background: unset;
        color: $primary;
      }
    }
  }

  .p-tabview-panels {
    background: transparent;
    padding: 1rem 0;
  }
}
