@import "breakpoints";
@import "theme-variables";

.field {
  position: relative;
  margin-bottom: 1.5rem;

  label {
    color: $primary;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}

@include respond-to(medium-screens-and-higher) {
  .field > small {
    position: absolute;
  }
}
