@import "theme-variables";

$background-gray: #eceef1;

p-inputswitch {
  .p-inputswitch {
    height: 2.35rem;
    width: 4.5rem;

    .p-inputswitch-slider {
      border-radius: 2rem;
      background: $background-gray !important;

      &::before {
        box-shadow: unset !important;
        background: $primary;
        width: 2rem;
        height: 2rem;
        left: 0.25rem;
        margin-top: -1rem;
      }

      &::after {
        content: "aus";
        text-transform: uppercase;
        font-size: 0.75rem;
        color: $primary;
        display: flex;
        justify-content: flex-end;
        line-height: 2.35rem;
        margin-right: 0.75rem;
      }
    }

    &.p-inputswitch-checked {
      .p-inputswitch-slider {
        background: $primary !important;

        &::before {
          box-shadow: unset !important;
          background: $white;
          left: 0.75rem;
        }

        &::after {
          content: "ein";
          color: $white;
          justify-content: flex-start;
          margin-left: 0.75rem;
        }
      }
    }
  }
}
