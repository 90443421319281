@import "theme-variables";

@mixin respond-to($media) {
  @if $media==lower-than-extra-large-screens {
    @media only screen and (max-width: ($viewport-xl - 1)) {
      @content;
    }
  } @else if $media==lower-than-large-screens {
    @media only screen and (max-width: ($viewport-lg - 1)) {
      @content;
    }
  } @else if $media==lower-than-medium-screens {
    @media only screen and (max-width: ($viewport-md - 1)) {
      @content;
    }
  } @else if $media==lower-than-small-screens {
    @media only screen and (max-width: ($viewport-sm - 1)) {
      @content;
    }
  } @else if $media==small-screens-and-higher {
    @media only screen and (min-width: $viewport-sm) {
      @content;
    }
  } @else if $media==medium-screens-and-higher {
    @media only screen and (min-width: $viewport-md) {
      @content;
    }
  } @else if $media==large-screens-and-higher {
    @media only screen and (min-width: $viewport-lg) {
      @content;
    }
  } @else if $media==extra-large-screens-and-higher {
    @media only screen and (min-width: $viewport-xl) {
      @content;
    }
  }
}
