@import "theme-variables";
@import "breakpoints";

.p-multiselect {
  border: none;
  width: 100%;
  height: $input-height;
  border-radius: $input-border-radius;
  box-shadow: $secondary-shadow;

  &.p-multiselect-open,
  &:not(.p-disabled).p-focus {
    box-shadow: $secondary-shadow, $focus-shadow;
  }

  &.primary {
    background-color: $primary;

    .p-dropdown-label {
      color: $white;
    }
  }

  &.ng-dirty.ng-invalid &.ng-dirty.ng-invalid {
    background-color: $warning !important;
  }

  .p-multiselect-label-container {
    width: 0;
    display: flex;
    align-items: center;
  }

  .p-multiselect-label {
    padding: $input-padding;

    @include respond-to(lower-than-small-screens) {
      width: calc(100vw - 6rem);
    }
  }

  .p-multiselect-trigger {
    width: 2.875rem;

    .fa-solid.fa-angle-down {
      @include center-items;

      background-color: $primary-light;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 0.7rem;
      color: $secondary-text-color;
    }
  }

  &.p-multiselect-chip .p-multiselect-token {
    border-radius: 1rem;
  }
}

.p-multiselect-panel {
  border-radius: $input-border-radius;
  overflow: hidden;
  box-shadow: $secondary-shadow;

  .p-multiselect-header {
    padding: 0 1rem;

    .p-multiselect-filter:enabled:focus {
      box-shadow: unset;
    }

    .p-multiselect-filter-icon {
      display: none;
    }
  }

  .p-multiselect-items .p-multiselect-item {
    &.p-highlight,
    &:focus.p-highlight {
      color: inherit;
      background: transparent;
    }

    .p-checkbox .p-checkbox-box {
      border-radius: 50%;
      border: 1px solid $primary;

      &.p-highlight {
        background-color: $primary;
      }
    }
  }
}
