@import "theme-variables";

.p-button {
  display: unset;
  padding: 0.5rem;

  &.p-button-icon-only {
    width: 2rem;
    padding: 0.5rem;

    &.p-button-text:enabled,
    &.p-button-outlined:enabled {
      color: $primary;
      background: transparent;

      &:focus,
      &:hover,
      &:active {
        color: $primary;
        background: transparent;
      }
    }
  }

  .p-button-label {
    flex: unset;
  }

  &.required .p-button-label::after {
    content: "*";
    color: $warning;
  }
}

button {
  .p-button-icon {
    font-size: 1.4rem;
  }
}
