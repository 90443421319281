/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
@import "~primeng/resources/primeng.min.css";
@import "~primeicons/primeicons.css";
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.grid > .col,
.grid > [class*=col] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .sm\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .sm\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .sm\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .sm\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .sm\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .sm\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .sm\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .sm\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .sm\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .sm\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .sm\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .sm\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .md\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .md\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .md\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .md\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .md\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .md\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .md\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .md\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .md\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .md\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .md\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .md\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .lg\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .lg\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .lg\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .lg\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .lg\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .lg\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .lg\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .lg\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .lg\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .lg\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .lg\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xl\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xl\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xl\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xl\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xl\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xl\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xl\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xl\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xl\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xl\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xl\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }
  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }
  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }
  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }
  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }
  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .md\:col-offset-3 {
    margin-left: 25% !important;
  }
  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .md\:col-offset-6 {
    margin-left: 50% !important;
  }
  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .md\:col-offset-9 {
    margin-left: 75% !important;
  }
  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }
  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }
  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }
  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }
  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus {
  color: var(--surface-0) !important;
}

.hover\:text-0:hover {
  color: var(--surface-0) !important;
}

.active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus {
  color: var(--surface-50) !important;
}

.hover\:text-50:hover {
  color: var(--surface-50) !important;
}

.active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus {
  color: var(--surface-100) !important;
}

.hover\:text-100:hover {
  color: var(--surface-100) !important;
}

.active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus {
  color: var(--surface-200) !important;
}

.hover\:text-200:hover {
  color: var(--surface-200) !important;
}

.active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus {
  color: var(--surface-300) !important;
}

.hover\:text-300:hover {
  color: var(--surface-300) !important;
}

.active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus {
  color: var(--surface-400) !important;
}

.hover\:text-400:hover {
  color: var(--surface-400) !important;
}

.active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus {
  color: var(--surface-500) !important;
}

.hover\:text-500:hover {
  color: var(--surface-500) !important;
}

.active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus {
  color: var(--surface-600) !important;
}

.hover\:text-600:hover {
  color: var(--surface-600) !important;
}

.active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus {
  color: var(--surface-700) !important;
}

.hover\:text-700:hover {
  color: var(--surface-700) !important;
}

.active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus {
  color: var(--surface-800) !important;
}

.hover\:text-800:hover {
  color: var(--surface-800) !important;
}

.active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus {
  color: var(--surface-900) !important;
}

.hover\:text-900:hover {
  color: var(--surface-900) !important;
}

.active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus {
  background-color: var(--surface-0) !important;
}

.hover\:surface-0:hover {
  background-color: var(--surface-0) !important;
}

.active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus {
  background-color: var(--surface-50) !important;
}

.hover\:surface-50:hover {
  background-color: var(--surface-50) !important;
}

.active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus {
  background-color: var(--surface-100) !important;
}

.hover\:surface-100:hover {
  background-color: var(--surface-100) !important;
}

.active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus {
  background-color: var(--surface-200) !important;
}

.hover\:surface-200:hover {
  background-color: var(--surface-200) !important;
}

.active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus {
  background-color: var(--surface-300) !important;
}

.hover\:surface-300:hover {
  background-color: var(--surface-300) !important;
}

.active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus {
  background-color: var(--surface-400) !important;
}

.hover\:surface-400:hover {
  background-color: var(--surface-400) !important;
}

.active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus {
  background-color: var(--surface-500) !important;
}

.hover\:surface-500:hover {
  background-color: var(--surface-500) !important;
}

.active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus {
  background-color: var(--surface-600) !important;
}

.hover\:surface-600:hover {
  background-color: var(--surface-600) !important;
}

.active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus {
  background-color: var(--surface-700) !important;
}

.hover\:surface-700:hover {
  background-color: var(--surface-700) !important;
}

.active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus {
  background-color: var(--surface-800) !important;
}

.hover\:surface-800:hover {
  background-color: var(--surface-800) !important;
}

.active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus {
  background-color: var(--surface-900) !important;
}

.hover\:surface-900:hover {
  background-color: var(--surface-900) !important;
}

.active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus {
  border-color: var(--surface-0) !important;
}

.hover\:border-0:hover {
  border-color: var(--surface-0) !important;
}

.active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus {
  border-color: var(--surface-50) !important;
}

.hover\:border-50:hover {
  border-color: var(--surface-50) !important;
}

.active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus {
  border-color: var(--surface-100) !important;
}

.hover\:border-100:hover {
  border-color: var(--surface-100) !important;
}

.active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus {
  border-color: var(--surface-200) !important;
}

.hover\:border-200:hover {
  border-color: var(--surface-200) !important;
}

.active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus {
  border-color: var(--surface-300) !important;
}

.hover\:border-300:hover {
  border-color: var(--surface-300) !important;
}

.active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus {
  border-color: var(--surface-400) !important;
}

.hover\:border-400:hover {
  border-color: var(--surface-400) !important;
}

.active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus {
  border-color: var(--surface-500) !important;
}

.hover\:border-500:hover {
  border-color: var(--surface-500) !important;
}

.active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus {
  border-color: var(--surface-600) !important;
}

.hover\:border-600:hover {
  border-color: var(--surface-600) !important;
}

.active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus {
  border-color: var(--surface-700) !important;
}

.hover\:border-700:hover {
  border-color: var(--surface-700) !important;
}

.active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus {
  border-color: var(--surface-800) !important;
}

.hover\:border-800:hover {
  border-color: var(--surface-800) !important;
}

.active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus {
  border-color: var(--surface-900) !important;
}

.hover\:border-900:hover {
  border-color: var(--surface-900) !important;
}

.active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
.border-transparent {
  border-color: transparent !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-transparent {
    border-color: transparent !important;
  }
}
.text-blue-50 {
  color: var(--blue-50) !important;
}
.text-blue-100 {
  color: var(--blue-100) !important;
}
.text-blue-200 {
  color: var(--blue-200) !important;
}
.text-blue-300 {
  color: var(--blue-300) !important;
}
.text-blue-400 {
  color: var(--blue-400) !important;
}
.text-blue-500 {
  color: var(--blue-500) !important;
}
.text-blue-600 {
  color: var(--blue-600) !important;
}
.text-blue-700 {
  color: var(--blue-700) !important;
}
.text-blue-800 {
  color: var(--blue-800) !important;
}
.text-blue-900 {
  color: var(--blue-900) !important;
}

.focus\:text-blue-50:focus {
  color: var(--blue-50) !important;
}
.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}
.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}
.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}
.focus\:text-blue-400:focus {
  color: var(--blue-400) !important;
}
.focus\:text-blue-500:focus {
  color: var(--blue-500) !important;
}
.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}
.focus\:text-blue-700:focus {
  color: var(--blue-700) !important;
}
.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}
.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-50:hover {
  color: var(--blue-50) !important;
}
.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}
.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}
.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}
.hover\:text-blue-400:hover {
  color: var(--blue-400) !important;
}
.hover\:text-blue-500:hover {
  color: var(--blue-500) !important;
}
.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}
.hover\:text-blue-700:hover {
  color: var(--blue-700) !important;
}
.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}
.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-50:active {
  color: var(--blue-50) !important;
}
.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}
.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}
.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}
.active\:text-blue-400:active {
  color: var(--blue-400) !important;
}
.active\:text-blue-500:active {
  color: var(--blue-500) !important;
}
.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}
.active\:text-blue-700:active {
  color: var(--blue-700) !important;
}
.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}
.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.text-green-50 {
  color: var(--green-50) !important;
}
.text-green-100 {
  color: var(--green-100) !important;
}
.text-green-200 {
  color: var(--green-200) !important;
}
.text-green-300 {
  color: var(--green-300) !important;
}
.text-green-400 {
  color: var(--green-400) !important;
}
.text-green-500 {
  color: var(--green-500) !important;
}
.text-green-600 {
  color: var(--green-600) !important;
}
.text-green-700 {
  color: var(--green-700) !important;
}
.text-green-800 {
  color: var(--green-800) !important;
}
.text-green-900 {
  color: var(--green-900) !important;
}

.focus\:text-green-50:focus {
  color: var(--green-50) !important;
}
.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}
.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}
.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}
.focus\:text-green-400:focus {
  color: var(--green-400) !important;
}
.focus\:text-green-500:focus {
  color: var(--green-500) !important;
}
.focus\:text-green-600:focus {
  color: var(--green-600) !important;
}
.focus\:text-green-700:focus {
  color: var(--green-700) !important;
}
.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}
.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-50:hover {
  color: var(--green-50) !important;
}
.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}
.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}
.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}
.hover\:text-green-400:hover {
  color: var(--green-400) !important;
}
.hover\:text-green-500:hover {
  color: var(--green-500) !important;
}
.hover\:text-green-600:hover {
  color: var(--green-600) !important;
}
.hover\:text-green-700:hover {
  color: var(--green-700) !important;
}
.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}
.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-50:active {
  color: var(--green-50) !important;
}
.active\:text-green-100:active {
  color: var(--green-100) !important;
}
.active\:text-green-200:active {
  color: var(--green-200) !important;
}
.active\:text-green-300:active {
  color: var(--green-300) !important;
}
.active\:text-green-400:active {
  color: var(--green-400) !important;
}
.active\:text-green-500:active {
  color: var(--green-500) !important;
}
.active\:text-green-600:active {
  color: var(--green-600) !important;
}
.active\:text-green-700:active {
  color: var(--green-700) !important;
}
.active\:text-green-800:active {
  color: var(--green-800) !important;
}
.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.text-yellow-50 {
  color: var(--yellow-50) !important;
}
.text-yellow-100 {
  color: var(--yellow-100) !important;
}
.text-yellow-200 {
  color: var(--yellow-200) !important;
}
.text-yellow-300 {
  color: var(--yellow-300) !important;
}
.text-yellow-400 {
  color: var(--yellow-400) !important;
}
.text-yellow-500 {
  color: var(--yellow-500) !important;
}
.text-yellow-600 {
  color: var(--yellow-600) !important;
}
.text-yellow-700 {
  color: var(--yellow-700) !important;
}
.text-yellow-800 {
  color: var(--yellow-800) !important;
}
.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-50:focus {
  color: var(--yellow-50) !important;
}
.focus\:text-yellow-100:focus {
  color: var(--yellow-100) !important;
}
.focus\:text-yellow-200:focus {
  color: var(--yellow-200) !important;
}
.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}
.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}
.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}
.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}
.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}
.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}
.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-50:hover {
  color: var(--yellow-50) !important;
}
.hover\:text-yellow-100:hover {
  color: var(--yellow-100) !important;
}
.hover\:text-yellow-200:hover {
  color: var(--yellow-200) !important;
}
.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}
.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}
.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}
.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}
.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}
.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}
.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-50:active {
  color: var(--yellow-50) !important;
}
.active\:text-yellow-100:active {
  color: var(--yellow-100) !important;
}
.active\:text-yellow-200:active {
  color: var(--yellow-200) !important;
}
.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}
.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}
.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}
.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}
.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}
.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}
.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.text-cyan-50 {
  color: var(--cyan-50) !important;
}
.text-cyan-100 {
  color: var(--cyan-100) !important;
}
.text-cyan-200 {
  color: var(--cyan-200) !important;
}
.text-cyan-300 {
  color: var(--cyan-300) !important;
}
.text-cyan-400 {
  color: var(--cyan-400) !important;
}
.text-cyan-500 {
  color: var(--cyan-500) !important;
}
.text-cyan-600 {
  color: var(--cyan-600) !important;
}
.text-cyan-700 {
  color: var(--cyan-700) !important;
}
.text-cyan-800 {
  color: var(--cyan-800) !important;
}
.text-cyan-900 {
  color: var(--cyan-900) !important;
}

.focus\:text-cyan-50:focus {
  color: var(--cyan-50) !important;
}
.focus\:text-cyan-100:focus {
  color: var(--cyan-100) !important;
}
.focus\:text-cyan-200:focus {
  color: var(--cyan-200) !important;
}
.focus\:text-cyan-300:focus {
  color: var(--cyan-300) !important;
}
.focus\:text-cyan-400:focus {
  color: var(--cyan-400) !important;
}
.focus\:text-cyan-500:focus {
  color: var(--cyan-500) !important;
}
.focus\:text-cyan-600:focus {
  color: var(--cyan-600) !important;
}
.focus\:text-cyan-700:focus {
  color: var(--cyan-700) !important;
}
.focus\:text-cyan-800:focus {
  color: var(--cyan-800) !important;
}
.focus\:text-cyan-900:focus {
  color: var(--cyan-900) !important;
}

.hover\:text-cyan-50:hover {
  color: var(--cyan-50) !important;
}
.hover\:text-cyan-100:hover {
  color: var(--cyan-100) !important;
}
.hover\:text-cyan-200:hover {
  color: var(--cyan-200) !important;
}
.hover\:text-cyan-300:hover {
  color: var(--cyan-300) !important;
}
.hover\:text-cyan-400:hover {
  color: var(--cyan-400) !important;
}
.hover\:text-cyan-500:hover {
  color: var(--cyan-500) !important;
}
.hover\:text-cyan-600:hover {
  color: var(--cyan-600) !important;
}
.hover\:text-cyan-700:hover {
  color: var(--cyan-700) !important;
}
.hover\:text-cyan-800:hover {
  color: var(--cyan-800) !important;
}
.hover\:text-cyan-900:hover {
  color: var(--cyan-900) !important;
}

.active\:text-cyan-50:active {
  color: var(--cyan-50) !important;
}
.active\:text-cyan-100:active {
  color: var(--cyan-100) !important;
}
.active\:text-cyan-200:active {
  color: var(--cyan-200) !important;
}
.active\:text-cyan-300:active {
  color: var(--cyan-300) !important;
}
.active\:text-cyan-400:active {
  color: var(--cyan-400) !important;
}
.active\:text-cyan-500:active {
  color: var(--cyan-500) !important;
}
.active\:text-cyan-600:active {
  color: var(--cyan-600) !important;
}
.active\:text-cyan-700:active {
  color: var(--cyan-700) !important;
}
.active\:text-cyan-800:active {
  color: var(--cyan-800) !important;
}
.active\:text-cyan-900:active {
  color: var(--cyan-900) !important;
}

.text-pink-50 {
  color: var(--pink-50) !important;
}
.text-pink-100 {
  color: var(--pink-100) !important;
}
.text-pink-200 {
  color: var(--pink-200) !important;
}
.text-pink-300 {
  color: var(--pink-300) !important;
}
.text-pink-400 {
  color: var(--pink-400) !important;
}
.text-pink-500 {
  color: var(--pink-500) !important;
}
.text-pink-600 {
  color: var(--pink-600) !important;
}
.text-pink-700 {
  color: var(--pink-700) !important;
}
.text-pink-800 {
  color: var(--pink-800) !important;
}
.text-pink-900 {
  color: var(--pink-900) !important;
}

.focus\:text-pink-50:focus {
  color: var(--pink-50) !important;
}
.focus\:text-pink-100:focus {
  color: var(--pink-100) !important;
}
.focus\:text-pink-200:focus {
  color: var(--pink-200) !important;
}
.focus\:text-pink-300:focus {
  color: var(--pink-300) !important;
}
.focus\:text-pink-400:focus {
  color: var(--pink-400) !important;
}
.focus\:text-pink-500:focus {
  color: var(--pink-500) !important;
}
.focus\:text-pink-600:focus {
  color: var(--pink-600) !important;
}
.focus\:text-pink-700:focus {
  color: var(--pink-700) !important;
}
.focus\:text-pink-800:focus {
  color: var(--pink-800) !important;
}
.focus\:text-pink-900:focus {
  color: var(--pink-900) !important;
}

.hover\:text-pink-50:hover {
  color: var(--pink-50) !important;
}
.hover\:text-pink-100:hover {
  color: var(--pink-100) !important;
}
.hover\:text-pink-200:hover {
  color: var(--pink-200) !important;
}
.hover\:text-pink-300:hover {
  color: var(--pink-300) !important;
}
.hover\:text-pink-400:hover {
  color: var(--pink-400) !important;
}
.hover\:text-pink-500:hover {
  color: var(--pink-500) !important;
}
.hover\:text-pink-600:hover {
  color: var(--pink-600) !important;
}
.hover\:text-pink-700:hover {
  color: var(--pink-700) !important;
}
.hover\:text-pink-800:hover {
  color: var(--pink-800) !important;
}
.hover\:text-pink-900:hover {
  color: var(--pink-900) !important;
}

.active\:text-pink-50:active {
  color: var(--pink-50) !important;
}
.active\:text-pink-100:active {
  color: var(--pink-100) !important;
}
.active\:text-pink-200:active {
  color: var(--pink-200) !important;
}
.active\:text-pink-300:active {
  color: var(--pink-300) !important;
}
.active\:text-pink-400:active {
  color: var(--pink-400) !important;
}
.active\:text-pink-500:active {
  color: var(--pink-500) !important;
}
.active\:text-pink-600:active {
  color: var(--pink-600) !important;
}
.active\:text-pink-700:active {
  color: var(--pink-700) !important;
}
.active\:text-pink-800:active {
  color: var(--pink-800) !important;
}
.active\:text-pink-900:active {
  color: var(--pink-900) !important;
}

.text-indigo-50 {
  color: var(--indigo-50) !important;
}
.text-indigo-100 {
  color: var(--indigo-100) !important;
}
.text-indigo-200 {
  color: var(--indigo-200) !important;
}
.text-indigo-300 {
  color: var(--indigo-300) !important;
}
.text-indigo-400 {
  color: var(--indigo-400) !important;
}
.text-indigo-500 {
  color: var(--indigo-500) !important;
}
.text-indigo-600 {
  color: var(--indigo-600) !important;
}
.text-indigo-700 {
  color: var(--indigo-700) !important;
}
.text-indigo-800 {
  color: var(--indigo-800) !important;
}
.text-indigo-900 {
  color: var(--indigo-900) !important;
}

.focus\:text-indigo-50:focus {
  color: var(--indigo-50) !important;
}
.focus\:text-indigo-100:focus {
  color: var(--indigo-100) !important;
}
.focus\:text-indigo-200:focus {
  color: var(--indigo-200) !important;
}
.focus\:text-indigo-300:focus {
  color: var(--indigo-300) !important;
}
.focus\:text-indigo-400:focus {
  color: var(--indigo-400) !important;
}
.focus\:text-indigo-500:focus {
  color: var(--indigo-500) !important;
}
.focus\:text-indigo-600:focus {
  color: var(--indigo-600) !important;
}
.focus\:text-indigo-700:focus {
  color: var(--indigo-700) !important;
}
.focus\:text-indigo-800:focus {
  color: var(--indigo-800) !important;
}
.focus\:text-indigo-900:focus {
  color: var(--indigo-900) !important;
}

.hover\:text-indigo-50:hover {
  color: var(--indigo-50) !important;
}
.hover\:text-indigo-100:hover {
  color: var(--indigo-100) !important;
}
.hover\:text-indigo-200:hover {
  color: var(--indigo-200) !important;
}
.hover\:text-indigo-300:hover {
  color: var(--indigo-300) !important;
}
.hover\:text-indigo-400:hover {
  color: var(--indigo-400) !important;
}
.hover\:text-indigo-500:hover {
  color: var(--indigo-500) !important;
}
.hover\:text-indigo-600:hover {
  color: var(--indigo-600) !important;
}
.hover\:text-indigo-700:hover {
  color: var(--indigo-700) !important;
}
.hover\:text-indigo-800:hover {
  color: var(--indigo-800) !important;
}
.hover\:text-indigo-900:hover {
  color: var(--indigo-900) !important;
}

.active\:text-indigo-50:active {
  color: var(--indigo-50) !important;
}
.active\:text-indigo-100:active {
  color: var(--indigo-100) !important;
}
.active\:text-indigo-200:active {
  color: var(--indigo-200) !important;
}
.active\:text-indigo-300:active {
  color: var(--indigo-300) !important;
}
.active\:text-indigo-400:active {
  color: var(--indigo-400) !important;
}
.active\:text-indigo-500:active {
  color: var(--indigo-500) !important;
}
.active\:text-indigo-600:active {
  color: var(--indigo-600) !important;
}
.active\:text-indigo-700:active {
  color: var(--indigo-700) !important;
}
.active\:text-indigo-800:active {
  color: var(--indigo-800) !important;
}
.active\:text-indigo-900:active {
  color: var(--indigo-900) !important;
}

.text-teal-50 {
  color: var(--teal-50) !important;
}
.text-teal-100 {
  color: var(--teal-100) !important;
}
.text-teal-200 {
  color: var(--teal-200) !important;
}
.text-teal-300 {
  color: var(--teal-300) !important;
}
.text-teal-400 {
  color: var(--teal-400) !important;
}
.text-teal-500 {
  color: var(--teal-500) !important;
}
.text-teal-600 {
  color: var(--teal-600) !important;
}
.text-teal-700 {
  color: var(--teal-700) !important;
}
.text-teal-800 {
  color: var(--teal-800) !important;
}
.text-teal-900 {
  color: var(--teal-900) !important;
}

.focus\:text-teal-50:focus {
  color: var(--teal-50) !important;
}
.focus\:text-teal-100:focus {
  color: var(--teal-100) !important;
}
.focus\:text-teal-200:focus {
  color: var(--teal-200) !important;
}
.focus\:text-teal-300:focus {
  color: var(--teal-300) !important;
}
.focus\:text-teal-400:focus {
  color: var(--teal-400) !important;
}
.focus\:text-teal-500:focus {
  color: var(--teal-500) !important;
}
.focus\:text-teal-600:focus {
  color: var(--teal-600) !important;
}
.focus\:text-teal-700:focus {
  color: var(--teal-700) !important;
}
.focus\:text-teal-800:focus {
  color: var(--teal-800) !important;
}
.focus\:text-teal-900:focus {
  color: var(--teal-900) !important;
}

.hover\:text-teal-50:hover {
  color: var(--teal-50) !important;
}
.hover\:text-teal-100:hover {
  color: var(--teal-100) !important;
}
.hover\:text-teal-200:hover {
  color: var(--teal-200) !important;
}
.hover\:text-teal-300:hover {
  color: var(--teal-300) !important;
}
.hover\:text-teal-400:hover {
  color: var(--teal-400) !important;
}
.hover\:text-teal-500:hover {
  color: var(--teal-500) !important;
}
.hover\:text-teal-600:hover {
  color: var(--teal-600) !important;
}
.hover\:text-teal-700:hover {
  color: var(--teal-700) !important;
}
.hover\:text-teal-800:hover {
  color: var(--teal-800) !important;
}
.hover\:text-teal-900:hover {
  color: var(--teal-900) !important;
}

.active\:text-teal-50:active {
  color: var(--teal-50) !important;
}
.active\:text-teal-100:active {
  color: var(--teal-100) !important;
}
.active\:text-teal-200:active {
  color: var(--teal-200) !important;
}
.active\:text-teal-300:active {
  color: var(--teal-300) !important;
}
.active\:text-teal-400:active {
  color: var(--teal-400) !important;
}
.active\:text-teal-500:active {
  color: var(--teal-500) !important;
}
.active\:text-teal-600:active {
  color: var(--teal-600) !important;
}
.active\:text-teal-700:active {
  color: var(--teal-700) !important;
}
.active\:text-teal-800:active {
  color: var(--teal-800) !important;
}
.active\:text-teal-900:active {
  color: var(--teal-900) !important;
}

.text-orange-50 {
  color: var(--orange-50) !important;
}
.text-orange-100 {
  color: var(--orange-100) !important;
}
.text-orange-200 {
  color: var(--orange-200) !important;
}
.text-orange-300 {
  color: var(--orange-300) !important;
}
.text-orange-400 {
  color: var(--orange-400) !important;
}
.text-orange-500 {
  color: var(--orange-500) !important;
}
.text-orange-600 {
  color: var(--orange-600) !important;
}
.text-orange-700 {
  color: var(--orange-700) !important;
}
.text-orange-800 {
  color: var(--orange-800) !important;
}
.text-orange-900 {
  color: var(--orange-900) !important;
}

.focus\:text-orange-50:focus {
  color: var(--orange-50) !important;
}
.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}
.focus\:text-orange-200:focus {
  color: var(--orange-200) !important;
}
.focus\:text-orange-300:focus {
  color: var(--orange-300) !important;
}
.focus\:text-orange-400:focus {
  color: var(--orange-400) !important;
}
.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}
.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}
.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}
.focus\:text-orange-800:focus {
  color: var(--orange-800) !important;
}
.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-50:hover {
  color: var(--orange-50) !important;
}
.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}
.hover\:text-orange-200:hover {
  color: var(--orange-200) !important;
}
.hover\:text-orange-300:hover {
  color: var(--orange-300) !important;
}
.hover\:text-orange-400:hover {
  color: var(--orange-400) !important;
}
.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}
.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}
.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}
.hover\:text-orange-800:hover {
  color: var(--orange-800) !important;
}
.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-50:active {
  color: var(--orange-50) !important;
}
.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}
.active\:text-orange-200:active {
  color: var(--orange-200) !important;
}
.active\:text-orange-300:active {
  color: var(--orange-300) !important;
}
.active\:text-orange-400:active {
  color: var(--orange-400) !important;
}
.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}
.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}
.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}
.active\:text-orange-800:active {
  color: var(--orange-800) !important;
}
.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.text-bluegray-50 {
  color: var(--bluegray-50) !important;
}
.text-bluegray-100 {
  color: var(--bluegray-100) !important;
}
.text-bluegray-200 {
  color: var(--bluegray-200) !important;
}
.text-bluegray-300 {
  color: var(--bluegray-300) !important;
}
.text-bluegray-400 {
  color: var(--bluegray-400) !important;
}
.text-bluegray-500 {
  color: var(--bluegray-500) !important;
}
.text-bluegray-600 {
  color: var(--bluegray-600) !important;
}
.text-bluegray-700 {
  color: var(--bluegray-700) !important;
}
.text-bluegray-800 {
  color: var(--bluegray-800) !important;
}
.text-bluegray-900 {
  color: var(--bluegray-900) !important;
}

.focus\:text-bluegray-50:focus {
  color: var(--bluegray-50) !important;
}
.focus\:text-bluegray-100:focus {
  color: var(--bluegray-100) !important;
}
.focus\:text-bluegray-200:focus {
  color: var(--bluegray-200) !important;
}
.focus\:text-bluegray-300:focus {
  color: var(--bluegray-300) !important;
}
.focus\:text-bluegray-400:focus {
  color: var(--bluegray-400) !important;
}
.focus\:text-bluegray-500:focus {
  color: var(--bluegray-500) !important;
}
.focus\:text-bluegray-600:focus {
  color: var(--bluegray-600) !important;
}
.focus\:text-bluegray-700:focus {
  color: var(--bluegray-700) !important;
}
.focus\:text-bluegray-800:focus {
  color: var(--bluegray-800) !important;
}
.focus\:text-bluegray-900:focus {
  color: var(--bluegray-900) !important;
}

.hover\:text-bluegray-50:hover {
  color: var(--bluegray-50) !important;
}
.hover\:text-bluegray-100:hover {
  color: var(--bluegray-100) !important;
}
.hover\:text-bluegray-200:hover {
  color: var(--bluegray-200) !important;
}
.hover\:text-bluegray-300:hover {
  color: var(--bluegray-300) !important;
}
.hover\:text-bluegray-400:hover {
  color: var(--bluegray-400) !important;
}
.hover\:text-bluegray-500:hover {
  color: var(--bluegray-500) !important;
}
.hover\:text-bluegray-600:hover {
  color: var(--bluegray-600) !important;
}
.hover\:text-bluegray-700:hover {
  color: var(--bluegray-700) !important;
}
.hover\:text-bluegray-800:hover {
  color: var(--bluegray-800) !important;
}
.hover\:text-bluegray-900:hover {
  color: var(--bluegray-900) !important;
}

.active\:text-bluegray-50:active {
  color: var(--bluegray-50) !important;
}
.active\:text-bluegray-100:active {
  color: var(--bluegray-100) !important;
}
.active\:text-bluegray-200:active {
  color: var(--bluegray-200) !important;
}
.active\:text-bluegray-300:active {
  color: var(--bluegray-300) !important;
}
.active\:text-bluegray-400:active {
  color: var(--bluegray-400) !important;
}
.active\:text-bluegray-500:active {
  color: var(--bluegray-500) !important;
}
.active\:text-bluegray-600:active {
  color: var(--bluegray-600) !important;
}
.active\:text-bluegray-700:active {
  color: var(--bluegray-700) !important;
}
.active\:text-bluegray-800:active {
  color: var(--bluegray-800) !important;
}
.active\:text-bluegray-900:active {
  color: var(--bluegray-900) !important;
}

.text-purple-50 {
  color: var(--purple-50) !important;
}
.text-purple-100 {
  color: var(--purple-100) !important;
}
.text-purple-200 {
  color: var(--purple-200) !important;
}
.text-purple-300 {
  color: var(--purple-300) !important;
}
.text-purple-400 {
  color: var(--purple-400) !important;
}
.text-purple-500 {
  color: var(--purple-500) !important;
}
.text-purple-600 {
  color: var(--purple-600) !important;
}
.text-purple-700 {
  color: var(--purple-700) !important;
}
.text-purple-800 {
  color: var(--purple-800) !important;
}
.text-purple-900 {
  color: var(--purple-900) !important;
}

.focus\:text-purple-50:focus {
  color: var(--purple-50) !important;
}
.focus\:text-purple-100:focus {
  color: var(--purple-100) !important;
}
.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}
.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}
.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}
.focus\:text-purple-500:focus {
  color: var(--purple-500) !important;
}
.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}
.focus\:text-purple-700:focus {
  color: var(--purple-700) !important;
}
.focus\:text-purple-800:focus {
  color: var(--purple-800) !important;
}
.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-50:hover {
  color: var(--purple-50) !important;
}
.hover\:text-purple-100:hover {
  color: var(--purple-100) !important;
}
.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}
.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}
.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}
.hover\:text-purple-500:hover {
  color: var(--purple-500) !important;
}
.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}
.hover\:text-purple-700:hover {
  color: var(--purple-700) !important;
}
.hover\:text-purple-800:hover {
  color: var(--purple-800) !important;
}
.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-50:active {
  color: var(--purple-50) !important;
}
.active\:text-purple-100:active {
  color: var(--purple-100) !important;
}
.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}
.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}
.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}
.active\:text-purple-500:active {
  color: var(--purple-500) !important;
}
.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}
.active\:text-purple-700:active {
  color: var(--purple-700) !important;
}
.active\:text-purple-800:active {
  color: var(--purple-800) !important;
}
.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.text-gray-50 {
  color: var(--gray-50) !important;
}
.text-gray-100 {
  color: var(--gray-100) !important;
}
.text-gray-200 {
  color: var(--gray-200) !important;
}
.text-gray-300 {
  color: var(--gray-300) !important;
}
.text-gray-400 {
  color: var(--gray-400) !important;
}
.text-gray-500 {
  color: var(--gray-500) !important;
}
.text-gray-600 {
  color: var(--gray-600) !important;
}
.text-gray-700 {
  color: var(--gray-700) !important;
}
.text-gray-800 {
  color: var(--gray-800) !important;
}
.text-gray-900 {
  color: var(--gray-900) !important;
}

.focus\:text-gray-50:focus {
  color: var(--gray-50) !important;
}
.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}
.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}
.focus\:text-gray-300:focus {
  color: var(--gray-300) !important;
}
.focus\:text-gray-400:focus {
  color: var(--gray-400) !important;
}
.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}
.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}
.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}
.focus\:text-gray-800:focus {
  color: var(--gray-800) !important;
}
.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-50:hover {
  color: var(--gray-50) !important;
}
.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}
.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}
.hover\:text-gray-300:hover {
  color: var(--gray-300) !important;
}
.hover\:text-gray-400:hover {
  color: var(--gray-400) !important;
}
.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}
.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}
.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}
.hover\:text-gray-800:hover {
  color: var(--gray-800) !important;
}
.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-50:active {
  color: var(--gray-50) !important;
}
.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}
.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}
.active\:text-gray-300:active {
  color: var(--gray-300) !important;
}
.active\:text-gray-400:active {
  color: var(--gray-400) !important;
}
.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}
.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}
.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}
.active\:text-gray-800:active {
  color: var(--gray-800) !important;
}
.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.bg-blue-50 {
  background-color: var(--blue-50) !important;
}
.bg-blue-100 {
  background-color: var(--blue-100) !important;
}
.bg-blue-200 {
  background-color: var(--blue-200) !important;
}
.bg-blue-300 {
  background-color: var(--blue-300) !important;
}
.bg-blue-400 {
  background-color: var(--blue-400) !important;
}
.bg-blue-500 {
  background-color: var(--blue-500) !important;
}
.bg-blue-600 {
  background-color: var(--blue-600) !important;
}
.bg-blue-700 {
  background-color: var(--blue-700) !important;
}
.bg-blue-800 {
  background-color: var(--blue-800) !important;
}
.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-50:focus {
  background-color: var(--blue-50) !important;
}
.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}
.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}
.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}
.focus\:bg-blue-400:focus {
  background-color: var(--blue-400) !important;
}
.focus\:bg-blue-500:focus {
  background-color: var(--blue-500) !important;
}
.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}
.focus\:bg-blue-700:focus {
  background-color: var(--blue-700) !important;
}
.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}
.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-50:hover {
  background-color: var(--blue-50) !important;
}
.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}
.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}
.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}
.hover\:bg-blue-400:hover {
  background-color: var(--blue-400) !important;
}
.hover\:bg-blue-500:hover {
  background-color: var(--blue-500) !important;
}
.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}
.hover\:bg-blue-700:hover {
  background-color: var(--blue-700) !important;
}
.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}
.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-50:active {
  background-color: var(--blue-50) !important;
}
.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}
.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}
.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}
.active\:bg-blue-400:active {
  background-color: var(--blue-400) !important;
}
.active\:bg-blue-500:active {
  background-color: var(--blue-500) !important;
}
.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}
.active\:bg-blue-700:active {
  background-color: var(--blue-700) !important;
}
.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}
.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.bg-green-50 {
  background-color: var(--green-50) !important;
}
.bg-green-100 {
  background-color: var(--green-100) !important;
}
.bg-green-200 {
  background-color: var(--green-200) !important;
}
.bg-green-300 {
  background-color: var(--green-300) !important;
}
.bg-green-400 {
  background-color: var(--green-400) !important;
}
.bg-green-500 {
  background-color: var(--green-500) !important;
}
.bg-green-600 {
  background-color: var(--green-600) !important;
}
.bg-green-700 {
  background-color: var(--green-700) !important;
}
.bg-green-800 {
  background-color: var(--green-800) !important;
}
.bg-green-900 {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-50:focus {
  background-color: var(--green-50) !important;
}
.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}
.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}
.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}
.focus\:bg-green-400:focus {
  background-color: var(--green-400) !important;
}
.focus\:bg-green-500:focus {
  background-color: var(--green-500) !important;
}
.focus\:bg-green-600:focus {
  background-color: var(--green-600) !important;
}
.focus\:bg-green-700:focus {
  background-color: var(--green-700) !important;
}
.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}
.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-50:hover {
  background-color: var(--green-50) !important;
}
.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}
.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}
.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}
.hover\:bg-green-400:hover {
  background-color: var(--green-400) !important;
}
.hover\:bg-green-500:hover {
  background-color: var(--green-500) !important;
}
.hover\:bg-green-600:hover {
  background-color: var(--green-600) !important;
}
.hover\:bg-green-700:hover {
  background-color: var(--green-700) !important;
}
.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}
.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-50:active {
  background-color: var(--green-50) !important;
}
.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}
.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}
.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}
.active\:bg-green-400:active {
  background-color: var(--green-400) !important;
}
.active\:bg-green-500:active {
  background-color: var(--green-500) !important;
}
.active\:bg-green-600:active {
  background-color: var(--green-600) !important;
}
.active\:bg-green-700:active {
  background-color: var(--green-700) !important;
}
.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}
.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.bg-yellow-50 {
  background-color: var(--yellow-50) !important;
}
.bg-yellow-100 {
  background-color: var(--yellow-100) !important;
}
.bg-yellow-200 {
  background-color: var(--yellow-200) !important;
}
.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}
.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}
.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}
.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}
.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}
.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}
.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-50:focus {
  background-color: var(--yellow-50) !important;
}
.focus\:bg-yellow-100:focus {
  background-color: var(--yellow-100) !important;
}
.focus\:bg-yellow-200:focus {
  background-color: var(--yellow-200) !important;
}
.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}
.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}
.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}
.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}
.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}
.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}
.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-50:hover {
  background-color: var(--yellow-50) !important;
}
.hover\:bg-yellow-100:hover {
  background-color: var(--yellow-100) !important;
}
.hover\:bg-yellow-200:hover {
  background-color: var(--yellow-200) !important;
}
.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}
.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}
.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}
.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}
.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}
.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}
.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-50:active {
  background-color: var(--yellow-50) !important;
}
.active\:bg-yellow-100:active {
  background-color: var(--yellow-100) !important;
}
.active\:bg-yellow-200:active {
  background-color: var(--yellow-200) !important;
}
.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}
.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}
.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}
.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}
.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}
.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}
.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.bg-cyan-50 {
  background-color: var(--cyan-50) !important;
}
.bg-cyan-100 {
  background-color: var(--cyan-100) !important;
}
.bg-cyan-200 {
  background-color: var(--cyan-200) !important;
}
.bg-cyan-300 {
  background-color: var(--cyan-300) !important;
}
.bg-cyan-400 {
  background-color: var(--cyan-400) !important;
}
.bg-cyan-500 {
  background-color: var(--cyan-500) !important;
}
.bg-cyan-600 {
  background-color: var(--cyan-600) !important;
}
.bg-cyan-700 {
  background-color: var(--cyan-700) !important;
}
.bg-cyan-800 {
  background-color: var(--cyan-800) !important;
}
.bg-cyan-900 {
  background-color: var(--cyan-900) !important;
}

.focus\:bg-cyan-50:focus {
  background-color: var(--cyan-50) !important;
}
.focus\:bg-cyan-100:focus {
  background-color: var(--cyan-100) !important;
}
.focus\:bg-cyan-200:focus {
  background-color: var(--cyan-200) !important;
}
.focus\:bg-cyan-300:focus {
  background-color: var(--cyan-300) !important;
}
.focus\:bg-cyan-400:focus {
  background-color: var(--cyan-400) !important;
}
.focus\:bg-cyan-500:focus {
  background-color: var(--cyan-500) !important;
}
.focus\:bg-cyan-600:focus {
  background-color: var(--cyan-600) !important;
}
.focus\:bg-cyan-700:focus {
  background-color: var(--cyan-700) !important;
}
.focus\:bg-cyan-800:focus {
  background-color: var(--cyan-800) !important;
}
.focus\:bg-cyan-900:focus {
  background-color: var(--cyan-900) !important;
}

.hover\:bg-cyan-50:hover {
  background-color: var(--cyan-50) !important;
}
.hover\:bg-cyan-100:hover {
  background-color: var(--cyan-100) !important;
}
.hover\:bg-cyan-200:hover {
  background-color: var(--cyan-200) !important;
}
.hover\:bg-cyan-300:hover {
  background-color: var(--cyan-300) !important;
}
.hover\:bg-cyan-400:hover {
  background-color: var(--cyan-400) !important;
}
.hover\:bg-cyan-500:hover {
  background-color: var(--cyan-500) !important;
}
.hover\:bg-cyan-600:hover {
  background-color: var(--cyan-600) !important;
}
.hover\:bg-cyan-700:hover {
  background-color: var(--cyan-700) !important;
}
.hover\:bg-cyan-800:hover {
  background-color: var(--cyan-800) !important;
}
.hover\:bg-cyan-900:hover {
  background-color: var(--cyan-900) !important;
}

.active\:bg-cyan-50:active {
  background-color: var(--cyan-50) !important;
}
.active\:bg-cyan-100:active {
  background-color: var(--cyan-100) !important;
}
.active\:bg-cyan-200:active {
  background-color: var(--cyan-200) !important;
}
.active\:bg-cyan-300:active {
  background-color: var(--cyan-300) !important;
}
.active\:bg-cyan-400:active {
  background-color: var(--cyan-400) !important;
}
.active\:bg-cyan-500:active {
  background-color: var(--cyan-500) !important;
}
.active\:bg-cyan-600:active {
  background-color: var(--cyan-600) !important;
}
.active\:bg-cyan-700:active {
  background-color: var(--cyan-700) !important;
}
.active\:bg-cyan-800:active {
  background-color: var(--cyan-800) !important;
}
.active\:bg-cyan-900:active {
  background-color: var(--cyan-900) !important;
}

.bg-pink-50 {
  background-color: var(--pink-50) !important;
}
.bg-pink-100 {
  background-color: var(--pink-100) !important;
}
.bg-pink-200 {
  background-color: var(--pink-200) !important;
}
.bg-pink-300 {
  background-color: var(--pink-300) !important;
}
.bg-pink-400 {
  background-color: var(--pink-400) !important;
}
.bg-pink-500 {
  background-color: var(--pink-500) !important;
}
.bg-pink-600 {
  background-color: var(--pink-600) !important;
}
.bg-pink-700 {
  background-color: var(--pink-700) !important;
}
.bg-pink-800 {
  background-color: var(--pink-800) !important;
}
.bg-pink-900 {
  background-color: var(--pink-900) !important;
}

.focus\:bg-pink-50:focus {
  background-color: var(--pink-50) !important;
}
.focus\:bg-pink-100:focus {
  background-color: var(--pink-100) !important;
}
.focus\:bg-pink-200:focus {
  background-color: var(--pink-200) !important;
}
.focus\:bg-pink-300:focus {
  background-color: var(--pink-300) !important;
}
.focus\:bg-pink-400:focus {
  background-color: var(--pink-400) !important;
}
.focus\:bg-pink-500:focus {
  background-color: var(--pink-500) !important;
}
.focus\:bg-pink-600:focus {
  background-color: var(--pink-600) !important;
}
.focus\:bg-pink-700:focus {
  background-color: var(--pink-700) !important;
}
.focus\:bg-pink-800:focus {
  background-color: var(--pink-800) !important;
}
.focus\:bg-pink-900:focus {
  background-color: var(--pink-900) !important;
}

.hover\:bg-pink-50:hover {
  background-color: var(--pink-50) !important;
}
.hover\:bg-pink-100:hover {
  background-color: var(--pink-100) !important;
}
.hover\:bg-pink-200:hover {
  background-color: var(--pink-200) !important;
}
.hover\:bg-pink-300:hover {
  background-color: var(--pink-300) !important;
}
.hover\:bg-pink-400:hover {
  background-color: var(--pink-400) !important;
}
.hover\:bg-pink-500:hover {
  background-color: var(--pink-500) !important;
}
.hover\:bg-pink-600:hover {
  background-color: var(--pink-600) !important;
}
.hover\:bg-pink-700:hover {
  background-color: var(--pink-700) !important;
}
.hover\:bg-pink-800:hover {
  background-color: var(--pink-800) !important;
}
.hover\:bg-pink-900:hover {
  background-color: var(--pink-900) !important;
}

.active\:bg-pink-50:active {
  background-color: var(--pink-50) !important;
}
.active\:bg-pink-100:active {
  background-color: var(--pink-100) !important;
}
.active\:bg-pink-200:active {
  background-color: var(--pink-200) !important;
}
.active\:bg-pink-300:active {
  background-color: var(--pink-300) !important;
}
.active\:bg-pink-400:active {
  background-color: var(--pink-400) !important;
}
.active\:bg-pink-500:active {
  background-color: var(--pink-500) !important;
}
.active\:bg-pink-600:active {
  background-color: var(--pink-600) !important;
}
.active\:bg-pink-700:active {
  background-color: var(--pink-700) !important;
}
.active\:bg-pink-800:active {
  background-color: var(--pink-800) !important;
}
.active\:bg-pink-900:active {
  background-color: var(--pink-900) !important;
}

.bg-indigo-50 {
  background-color: var(--indigo-50) !important;
}
.bg-indigo-100 {
  background-color: var(--indigo-100) !important;
}
.bg-indigo-200 {
  background-color: var(--indigo-200) !important;
}
.bg-indigo-300 {
  background-color: var(--indigo-300) !important;
}
.bg-indigo-400 {
  background-color: var(--indigo-400) !important;
}
.bg-indigo-500 {
  background-color: var(--indigo-500) !important;
}
.bg-indigo-600 {
  background-color: var(--indigo-600) !important;
}
.bg-indigo-700 {
  background-color: var(--indigo-700) !important;
}
.bg-indigo-800 {
  background-color: var(--indigo-800) !important;
}
.bg-indigo-900 {
  background-color: var(--indigo-900) !important;
}

.focus\:bg-indigo-50:focus {
  background-color: var(--indigo-50) !important;
}
.focus\:bg-indigo-100:focus {
  background-color: var(--indigo-100) !important;
}
.focus\:bg-indigo-200:focus {
  background-color: var(--indigo-200) !important;
}
.focus\:bg-indigo-300:focus {
  background-color: var(--indigo-300) !important;
}
.focus\:bg-indigo-400:focus {
  background-color: var(--indigo-400) !important;
}
.focus\:bg-indigo-500:focus {
  background-color: var(--indigo-500) !important;
}
.focus\:bg-indigo-600:focus {
  background-color: var(--indigo-600) !important;
}
.focus\:bg-indigo-700:focus {
  background-color: var(--indigo-700) !important;
}
.focus\:bg-indigo-800:focus {
  background-color: var(--indigo-800) !important;
}
.focus\:bg-indigo-900:focus {
  background-color: var(--indigo-900) !important;
}

.hover\:bg-indigo-50:hover {
  background-color: var(--indigo-50) !important;
}
.hover\:bg-indigo-100:hover {
  background-color: var(--indigo-100) !important;
}
.hover\:bg-indigo-200:hover {
  background-color: var(--indigo-200) !important;
}
.hover\:bg-indigo-300:hover {
  background-color: var(--indigo-300) !important;
}
.hover\:bg-indigo-400:hover {
  background-color: var(--indigo-400) !important;
}
.hover\:bg-indigo-500:hover {
  background-color: var(--indigo-500) !important;
}
.hover\:bg-indigo-600:hover {
  background-color: var(--indigo-600) !important;
}
.hover\:bg-indigo-700:hover {
  background-color: var(--indigo-700) !important;
}
.hover\:bg-indigo-800:hover {
  background-color: var(--indigo-800) !important;
}
.hover\:bg-indigo-900:hover {
  background-color: var(--indigo-900) !important;
}

.active\:bg-indigo-50:active {
  background-color: var(--indigo-50) !important;
}
.active\:bg-indigo-100:active {
  background-color: var(--indigo-100) !important;
}
.active\:bg-indigo-200:active {
  background-color: var(--indigo-200) !important;
}
.active\:bg-indigo-300:active {
  background-color: var(--indigo-300) !important;
}
.active\:bg-indigo-400:active {
  background-color: var(--indigo-400) !important;
}
.active\:bg-indigo-500:active {
  background-color: var(--indigo-500) !important;
}
.active\:bg-indigo-600:active {
  background-color: var(--indigo-600) !important;
}
.active\:bg-indigo-700:active {
  background-color: var(--indigo-700) !important;
}
.active\:bg-indigo-800:active {
  background-color: var(--indigo-800) !important;
}
.active\:bg-indigo-900:active {
  background-color: var(--indigo-900) !important;
}

.bg-teal-50 {
  background-color: var(--teal-50) !important;
}
.bg-teal-100 {
  background-color: var(--teal-100) !important;
}
.bg-teal-200 {
  background-color: var(--teal-200) !important;
}
.bg-teal-300 {
  background-color: var(--teal-300) !important;
}
.bg-teal-400 {
  background-color: var(--teal-400) !important;
}
.bg-teal-500 {
  background-color: var(--teal-500) !important;
}
.bg-teal-600 {
  background-color: var(--teal-600) !important;
}
.bg-teal-700 {
  background-color: var(--teal-700) !important;
}
.bg-teal-800 {
  background-color: var(--teal-800) !important;
}
.bg-teal-900 {
  background-color: var(--teal-900) !important;
}

.focus\:bg-teal-50:focus {
  background-color: var(--teal-50) !important;
}
.focus\:bg-teal-100:focus {
  background-color: var(--teal-100) !important;
}
.focus\:bg-teal-200:focus {
  background-color: var(--teal-200) !important;
}
.focus\:bg-teal-300:focus {
  background-color: var(--teal-300) !important;
}
.focus\:bg-teal-400:focus {
  background-color: var(--teal-400) !important;
}
.focus\:bg-teal-500:focus {
  background-color: var(--teal-500) !important;
}
.focus\:bg-teal-600:focus {
  background-color: var(--teal-600) !important;
}
.focus\:bg-teal-700:focus {
  background-color: var(--teal-700) !important;
}
.focus\:bg-teal-800:focus {
  background-color: var(--teal-800) !important;
}
.focus\:bg-teal-900:focus {
  background-color: var(--teal-900) !important;
}

.hover\:bg-teal-50:hover {
  background-color: var(--teal-50) !important;
}
.hover\:bg-teal-100:hover {
  background-color: var(--teal-100) !important;
}
.hover\:bg-teal-200:hover {
  background-color: var(--teal-200) !important;
}
.hover\:bg-teal-300:hover {
  background-color: var(--teal-300) !important;
}
.hover\:bg-teal-400:hover {
  background-color: var(--teal-400) !important;
}
.hover\:bg-teal-500:hover {
  background-color: var(--teal-500) !important;
}
.hover\:bg-teal-600:hover {
  background-color: var(--teal-600) !important;
}
.hover\:bg-teal-700:hover {
  background-color: var(--teal-700) !important;
}
.hover\:bg-teal-800:hover {
  background-color: var(--teal-800) !important;
}
.hover\:bg-teal-900:hover {
  background-color: var(--teal-900) !important;
}

.active\:bg-teal-50:active {
  background-color: var(--teal-50) !important;
}
.active\:bg-teal-100:active {
  background-color: var(--teal-100) !important;
}
.active\:bg-teal-200:active {
  background-color: var(--teal-200) !important;
}
.active\:bg-teal-300:active {
  background-color: var(--teal-300) !important;
}
.active\:bg-teal-400:active {
  background-color: var(--teal-400) !important;
}
.active\:bg-teal-500:active {
  background-color: var(--teal-500) !important;
}
.active\:bg-teal-600:active {
  background-color: var(--teal-600) !important;
}
.active\:bg-teal-700:active {
  background-color: var(--teal-700) !important;
}
.active\:bg-teal-800:active {
  background-color: var(--teal-800) !important;
}
.active\:bg-teal-900:active {
  background-color: var(--teal-900) !important;
}

.bg-orange-50 {
  background-color: var(--orange-50) !important;
}
.bg-orange-100 {
  background-color: var(--orange-100) !important;
}
.bg-orange-200 {
  background-color: var(--orange-200) !important;
}
.bg-orange-300 {
  background-color: var(--orange-300) !important;
}
.bg-orange-400 {
  background-color: var(--orange-400) !important;
}
.bg-orange-500 {
  background-color: var(--orange-500) !important;
}
.bg-orange-600 {
  background-color: var(--orange-600) !important;
}
.bg-orange-700 {
  background-color: var(--orange-700) !important;
}
.bg-orange-800 {
  background-color: var(--orange-800) !important;
}
.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-50:focus {
  background-color: var(--orange-50) !important;
}
.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}
.focus\:bg-orange-200:focus {
  background-color: var(--orange-200) !important;
}
.focus\:bg-orange-300:focus {
  background-color: var(--orange-300) !important;
}
.focus\:bg-orange-400:focus {
  background-color: var(--orange-400) !important;
}
.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}
.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}
.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}
.focus\:bg-orange-800:focus {
  background-color: var(--orange-800) !important;
}
.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-50:hover {
  background-color: var(--orange-50) !important;
}
.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}
.hover\:bg-orange-200:hover {
  background-color: var(--orange-200) !important;
}
.hover\:bg-orange-300:hover {
  background-color: var(--orange-300) !important;
}
.hover\:bg-orange-400:hover {
  background-color: var(--orange-400) !important;
}
.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}
.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}
.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}
.hover\:bg-orange-800:hover {
  background-color: var(--orange-800) !important;
}
.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-50:active {
  background-color: var(--orange-50) !important;
}
.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}
.active\:bg-orange-200:active {
  background-color: var(--orange-200) !important;
}
.active\:bg-orange-300:active {
  background-color: var(--orange-300) !important;
}
.active\:bg-orange-400:active {
  background-color: var(--orange-400) !important;
}
.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}
.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}
.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}
.active\:bg-orange-800:active {
  background-color: var(--orange-800) !important;
}
.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.bg-bluegray-50 {
  background-color: var(--bluegray-50) !important;
}
.bg-bluegray-100 {
  background-color: var(--bluegray-100) !important;
}
.bg-bluegray-200 {
  background-color: var(--bluegray-200) !important;
}
.bg-bluegray-300 {
  background-color: var(--bluegray-300) !important;
}
.bg-bluegray-400 {
  background-color: var(--bluegray-400) !important;
}
.bg-bluegray-500 {
  background-color: var(--bluegray-500) !important;
}
.bg-bluegray-600 {
  background-color: var(--bluegray-600) !important;
}
.bg-bluegray-700 {
  background-color: var(--bluegray-700) !important;
}
.bg-bluegray-800 {
  background-color: var(--bluegray-800) !important;
}
.bg-bluegray-900 {
  background-color: var(--bluegray-900) !important;
}

.focus\:bg-bluegray-50:focus {
  background-color: var(--bluegray-50) !important;
}
.focus\:bg-bluegray-100:focus {
  background-color: var(--bluegray-100) !important;
}
.focus\:bg-bluegray-200:focus {
  background-color: var(--bluegray-200) !important;
}
.focus\:bg-bluegray-300:focus {
  background-color: var(--bluegray-300) !important;
}
.focus\:bg-bluegray-400:focus {
  background-color: var(--bluegray-400) !important;
}
.focus\:bg-bluegray-500:focus {
  background-color: var(--bluegray-500) !important;
}
.focus\:bg-bluegray-600:focus {
  background-color: var(--bluegray-600) !important;
}
.focus\:bg-bluegray-700:focus {
  background-color: var(--bluegray-700) !important;
}
.focus\:bg-bluegray-800:focus {
  background-color: var(--bluegray-800) !important;
}
.focus\:bg-bluegray-900:focus {
  background-color: var(--bluegray-900) !important;
}

.hover\:bg-bluegray-50:hover {
  background-color: var(--bluegray-50) !important;
}
.hover\:bg-bluegray-100:hover {
  background-color: var(--bluegray-100) !important;
}
.hover\:bg-bluegray-200:hover {
  background-color: var(--bluegray-200) !important;
}
.hover\:bg-bluegray-300:hover {
  background-color: var(--bluegray-300) !important;
}
.hover\:bg-bluegray-400:hover {
  background-color: var(--bluegray-400) !important;
}
.hover\:bg-bluegray-500:hover {
  background-color: var(--bluegray-500) !important;
}
.hover\:bg-bluegray-600:hover {
  background-color: var(--bluegray-600) !important;
}
.hover\:bg-bluegray-700:hover {
  background-color: var(--bluegray-700) !important;
}
.hover\:bg-bluegray-800:hover {
  background-color: var(--bluegray-800) !important;
}
.hover\:bg-bluegray-900:hover {
  background-color: var(--bluegray-900) !important;
}

.active\:bg-bluegray-50:active {
  background-color: var(--bluegray-50) !important;
}
.active\:bg-bluegray-100:active {
  background-color: var(--bluegray-100) !important;
}
.active\:bg-bluegray-200:active {
  background-color: var(--bluegray-200) !important;
}
.active\:bg-bluegray-300:active {
  background-color: var(--bluegray-300) !important;
}
.active\:bg-bluegray-400:active {
  background-color: var(--bluegray-400) !important;
}
.active\:bg-bluegray-500:active {
  background-color: var(--bluegray-500) !important;
}
.active\:bg-bluegray-600:active {
  background-color: var(--bluegray-600) !important;
}
.active\:bg-bluegray-700:active {
  background-color: var(--bluegray-700) !important;
}
.active\:bg-bluegray-800:active {
  background-color: var(--bluegray-800) !important;
}
.active\:bg-bluegray-900:active {
  background-color: var(--bluegray-900) !important;
}

.bg-purple-50 {
  background-color: var(--purple-50) !important;
}
.bg-purple-100 {
  background-color: var(--purple-100) !important;
}
.bg-purple-200 {
  background-color: var(--purple-200) !important;
}
.bg-purple-300 {
  background-color: var(--purple-300) !important;
}
.bg-purple-400 {
  background-color: var(--purple-400) !important;
}
.bg-purple-500 {
  background-color: var(--purple-500) !important;
}
.bg-purple-600 {
  background-color: var(--purple-600) !important;
}
.bg-purple-700 {
  background-color: var(--purple-700) !important;
}
.bg-purple-800 {
  background-color: var(--purple-800) !important;
}
.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-50:focus {
  background-color: var(--purple-50) !important;
}
.focus\:bg-purple-100:focus {
  background-color: var(--purple-100) !important;
}
.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}
.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}
.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}
.focus\:bg-purple-500:focus {
  background-color: var(--purple-500) !important;
}
.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}
.focus\:bg-purple-700:focus {
  background-color: var(--purple-700) !important;
}
.focus\:bg-purple-800:focus {
  background-color: var(--purple-800) !important;
}
.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-50:hover {
  background-color: var(--purple-50) !important;
}
.hover\:bg-purple-100:hover {
  background-color: var(--purple-100) !important;
}
.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}
.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}
.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}
.hover\:bg-purple-500:hover {
  background-color: var(--purple-500) !important;
}
.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}
.hover\:bg-purple-700:hover {
  background-color: var(--purple-700) !important;
}
.hover\:bg-purple-800:hover {
  background-color: var(--purple-800) !important;
}
.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-50:active {
  background-color: var(--purple-50) !important;
}
.active\:bg-purple-100:active {
  background-color: var(--purple-100) !important;
}
.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}
.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}
.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}
.active\:bg-purple-500:active {
  background-color: var(--purple-500) !important;
}
.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}
.active\:bg-purple-700:active {
  background-color: var(--purple-700) !important;
}
.active\:bg-purple-800:active {
  background-color: var(--purple-800) !important;
}
.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.bg-gray-50 {
  background-color: var(--gray-50) !important;
}
.bg-gray-100 {
  background-color: var(--gray-100) !important;
}
.bg-gray-200 {
  background-color: var(--gray-200) !important;
}
.bg-gray-300 {
  background-color: var(--gray-300) !important;
}
.bg-gray-400 {
  background-color: var(--gray-400) !important;
}
.bg-gray-500 {
  background-color: var(--gray-500) !important;
}
.bg-gray-600 {
  background-color: var(--gray-600) !important;
}
.bg-gray-700 {
  background-color: var(--gray-700) !important;
}
.bg-gray-800 {
  background-color: var(--gray-800) !important;
}
.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-50:focus {
  background-color: var(--gray-50) !important;
}
.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}
.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}
.focus\:bg-gray-300:focus {
  background-color: var(--gray-300) !important;
}
.focus\:bg-gray-400:focus {
  background-color: var(--gray-400) !important;
}
.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}
.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}
.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}
.focus\:bg-gray-800:focus {
  background-color: var(--gray-800) !important;
}
.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-50:hover {
  background-color: var(--gray-50) !important;
}
.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}
.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}
.hover\:bg-gray-300:hover {
  background-color: var(--gray-300) !important;
}
.hover\:bg-gray-400:hover {
  background-color: var(--gray-400) !important;
}
.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}
.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}
.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}
.hover\:bg-gray-800:hover {
  background-color: var(--gray-800) !important;
}
.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-50:active {
  background-color: var(--gray-50) !important;
}
.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}
.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}
.active\:bg-gray-300:active {
  background-color: var(--gray-300) !important;
}
.active\:bg-gray-400:active {
  background-color: var(--gray-400) !important;
}
.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}
.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}
.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}
.active\:bg-gray-800:active {
  background-color: var(--gray-800) !important;
}
.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.border-blue-50 {
  border-color: var(--blue-50) !important;
}
.border-blue-100 {
  border-color: var(--blue-100) !important;
}
.border-blue-200 {
  border-color: var(--blue-200) !important;
}
.border-blue-300 {
  border-color: var(--blue-300) !important;
}
.border-blue-400 {
  border-color: var(--blue-400) !important;
}
.border-blue-500 {
  border-color: var(--blue-500) !important;
}
.border-blue-600 {
  border-color: var(--blue-600) !important;
}
.border-blue-700 {
  border-color: var(--blue-700) !important;
}
.border-blue-800 {
  border-color: var(--blue-800) !important;
}
.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-50:focus {
  border-color: var(--blue-50) !important;
}
.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}
.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}
.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}
.focus\:border-blue-400:focus {
  border-color: var(--blue-400) !important;
}
.focus\:border-blue-500:focus {
  border-color: var(--blue-500) !important;
}
.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}
.focus\:border-blue-700:focus {
  border-color: var(--blue-700) !important;
}
.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}
.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-50:hover {
  border-color: var(--blue-50) !important;
}
.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}
.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}
.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}
.hover\:border-blue-400:hover {
  border-color: var(--blue-400) !important;
}
.hover\:border-blue-500:hover {
  border-color: var(--blue-500) !important;
}
.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}
.hover\:border-blue-700:hover {
  border-color: var(--blue-700) !important;
}
.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}
.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-50:active {
  border-color: var(--blue-50) !important;
}
.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}
.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}
.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}
.active\:border-blue-400:active {
  border-color: var(--blue-400) !important;
}
.active\:border-blue-500:active {
  border-color: var(--blue-500) !important;
}
.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}
.active\:border-blue-700:active {
  border-color: var(--blue-700) !important;
}
.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}
.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.border-green-50 {
  border-color: var(--green-50) !important;
}
.border-green-100 {
  border-color: var(--green-100) !important;
}
.border-green-200 {
  border-color: var(--green-200) !important;
}
.border-green-300 {
  border-color: var(--green-300) !important;
}
.border-green-400 {
  border-color: var(--green-400) !important;
}
.border-green-500 {
  border-color: var(--green-500) !important;
}
.border-green-600 {
  border-color: var(--green-600) !important;
}
.border-green-700 {
  border-color: var(--green-700) !important;
}
.border-green-800 {
  border-color: var(--green-800) !important;
}
.border-green-900 {
  border-color: var(--green-900) !important;
}

.focus\:border-green-50:focus {
  border-color: var(--green-50) !important;
}
.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}
.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}
.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}
.focus\:border-green-400:focus {
  border-color: var(--green-400) !important;
}
.focus\:border-green-500:focus {
  border-color: var(--green-500) !important;
}
.focus\:border-green-600:focus {
  border-color: var(--green-600) !important;
}
.focus\:border-green-700:focus {
  border-color: var(--green-700) !important;
}
.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}
.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-50:hover {
  border-color: var(--green-50) !important;
}
.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}
.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}
.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}
.hover\:border-green-400:hover {
  border-color: var(--green-400) !important;
}
.hover\:border-green-500:hover {
  border-color: var(--green-500) !important;
}
.hover\:border-green-600:hover {
  border-color: var(--green-600) !important;
}
.hover\:border-green-700:hover {
  border-color: var(--green-700) !important;
}
.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}
.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-50:active {
  border-color: var(--green-50) !important;
}
.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}
.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}
.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}
.active\:border-green-400:active {
  border-color: var(--green-400) !important;
}
.active\:border-green-500:active {
  border-color: var(--green-500) !important;
}
.active\:border-green-600:active {
  border-color: var(--green-600) !important;
}
.active\:border-green-700:active {
  border-color: var(--green-700) !important;
}
.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}
.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.border-yellow-50 {
  border-color: var(--yellow-50) !important;
}
.border-yellow-100 {
  border-color: var(--yellow-100) !important;
}
.border-yellow-200 {
  border-color: var(--yellow-200) !important;
}
.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}
.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}
.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}
.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}
.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}
.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}
.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-50:focus {
  border-color: var(--yellow-50) !important;
}
.focus\:border-yellow-100:focus {
  border-color: var(--yellow-100) !important;
}
.focus\:border-yellow-200:focus {
  border-color: var(--yellow-200) !important;
}
.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}
.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}
.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}
.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}
.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}
.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}
.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-50:hover {
  border-color: var(--yellow-50) !important;
}
.hover\:border-yellow-100:hover {
  border-color: var(--yellow-100) !important;
}
.hover\:border-yellow-200:hover {
  border-color: var(--yellow-200) !important;
}
.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}
.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}
.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}
.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}
.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}
.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}
.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-50:active {
  border-color: var(--yellow-50) !important;
}
.active\:border-yellow-100:active {
  border-color: var(--yellow-100) !important;
}
.active\:border-yellow-200:active {
  border-color: var(--yellow-200) !important;
}
.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}
.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}
.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}
.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}
.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}
.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}
.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.border-cyan-50 {
  border-color: var(--cyan-50) !important;
}
.border-cyan-100 {
  border-color: var(--cyan-100) !important;
}
.border-cyan-200 {
  border-color: var(--cyan-200) !important;
}
.border-cyan-300 {
  border-color: var(--cyan-300) !important;
}
.border-cyan-400 {
  border-color: var(--cyan-400) !important;
}
.border-cyan-500 {
  border-color: var(--cyan-500) !important;
}
.border-cyan-600 {
  border-color: var(--cyan-600) !important;
}
.border-cyan-700 {
  border-color: var(--cyan-700) !important;
}
.border-cyan-800 {
  border-color: var(--cyan-800) !important;
}
.border-cyan-900 {
  border-color: var(--cyan-900) !important;
}

.focus\:border-cyan-50:focus {
  border-color: var(--cyan-50) !important;
}
.focus\:border-cyan-100:focus {
  border-color: var(--cyan-100) !important;
}
.focus\:border-cyan-200:focus {
  border-color: var(--cyan-200) !important;
}
.focus\:border-cyan-300:focus {
  border-color: var(--cyan-300) !important;
}
.focus\:border-cyan-400:focus {
  border-color: var(--cyan-400) !important;
}
.focus\:border-cyan-500:focus {
  border-color: var(--cyan-500) !important;
}
.focus\:border-cyan-600:focus {
  border-color: var(--cyan-600) !important;
}
.focus\:border-cyan-700:focus {
  border-color: var(--cyan-700) !important;
}
.focus\:border-cyan-800:focus {
  border-color: var(--cyan-800) !important;
}
.focus\:border-cyan-900:focus {
  border-color: var(--cyan-900) !important;
}

.hover\:border-cyan-50:hover {
  border-color: var(--cyan-50) !important;
}
.hover\:border-cyan-100:hover {
  border-color: var(--cyan-100) !important;
}
.hover\:border-cyan-200:hover {
  border-color: var(--cyan-200) !important;
}
.hover\:border-cyan-300:hover {
  border-color: var(--cyan-300) !important;
}
.hover\:border-cyan-400:hover {
  border-color: var(--cyan-400) !important;
}
.hover\:border-cyan-500:hover {
  border-color: var(--cyan-500) !important;
}
.hover\:border-cyan-600:hover {
  border-color: var(--cyan-600) !important;
}
.hover\:border-cyan-700:hover {
  border-color: var(--cyan-700) !important;
}
.hover\:border-cyan-800:hover {
  border-color: var(--cyan-800) !important;
}
.hover\:border-cyan-900:hover {
  border-color: var(--cyan-900) !important;
}

.active\:border-cyan-50:active {
  border-color: var(--cyan-50) !important;
}
.active\:border-cyan-100:active {
  border-color: var(--cyan-100) !important;
}
.active\:border-cyan-200:active {
  border-color: var(--cyan-200) !important;
}
.active\:border-cyan-300:active {
  border-color: var(--cyan-300) !important;
}
.active\:border-cyan-400:active {
  border-color: var(--cyan-400) !important;
}
.active\:border-cyan-500:active {
  border-color: var(--cyan-500) !important;
}
.active\:border-cyan-600:active {
  border-color: var(--cyan-600) !important;
}
.active\:border-cyan-700:active {
  border-color: var(--cyan-700) !important;
}
.active\:border-cyan-800:active {
  border-color: var(--cyan-800) !important;
}
.active\:border-cyan-900:active {
  border-color: var(--cyan-900) !important;
}

.border-pink-50 {
  border-color: var(--pink-50) !important;
}
.border-pink-100 {
  border-color: var(--pink-100) !important;
}
.border-pink-200 {
  border-color: var(--pink-200) !important;
}
.border-pink-300 {
  border-color: var(--pink-300) !important;
}
.border-pink-400 {
  border-color: var(--pink-400) !important;
}
.border-pink-500 {
  border-color: var(--pink-500) !important;
}
.border-pink-600 {
  border-color: var(--pink-600) !important;
}
.border-pink-700 {
  border-color: var(--pink-700) !important;
}
.border-pink-800 {
  border-color: var(--pink-800) !important;
}
.border-pink-900 {
  border-color: var(--pink-900) !important;
}

.focus\:border-pink-50:focus {
  border-color: var(--pink-50) !important;
}
.focus\:border-pink-100:focus {
  border-color: var(--pink-100) !important;
}
.focus\:border-pink-200:focus {
  border-color: var(--pink-200) !important;
}
.focus\:border-pink-300:focus {
  border-color: var(--pink-300) !important;
}
.focus\:border-pink-400:focus {
  border-color: var(--pink-400) !important;
}
.focus\:border-pink-500:focus {
  border-color: var(--pink-500) !important;
}
.focus\:border-pink-600:focus {
  border-color: var(--pink-600) !important;
}
.focus\:border-pink-700:focus {
  border-color: var(--pink-700) !important;
}
.focus\:border-pink-800:focus {
  border-color: var(--pink-800) !important;
}
.focus\:border-pink-900:focus {
  border-color: var(--pink-900) !important;
}

.hover\:border-pink-50:hover {
  border-color: var(--pink-50) !important;
}
.hover\:border-pink-100:hover {
  border-color: var(--pink-100) !important;
}
.hover\:border-pink-200:hover {
  border-color: var(--pink-200) !important;
}
.hover\:border-pink-300:hover {
  border-color: var(--pink-300) !important;
}
.hover\:border-pink-400:hover {
  border-color: var(--pink-400) !important;
}
.hover\:border-pink-500:hover {
  border-color: var(--pink-500) !important;
}
.hover\:border-pink-600:hover {
  border-color: var(--pink-600) !important;
}
.hover\:border-pink-700:hover {
  border-color: var(--pink-700) !important;
}
.hover\:border-pink-800:hover {
  border-color: var(--pink-800) !important;
}
.hover\:border-pink-900:hover {
  border-color: var(--pink-900) !important;
}

.active\:border-pink-50:active {
  border-color: var(--pink-50) !important;
}
.active\:border-pink-100:active {
  border-color: var(--pink-100) !important;
}
.active\:border-pink-200:active {
  border-color: var(--pink-200) !important;
}
.active\:border-pink-300:active {
  border-color: var(--pink-300) !important;
}
.active\:border-pink-400:active {
  border-color: var(--pink-400) !important;
}
.active\:border-pink-500:active {
  border-color: var(--pink-500) !important;
}
.active\:border-pink-600:active {
  border-color: var(--pink-600) !important;
}
.active\:border-pink-700:active {
  border-color: var(--pink-700) !important;
}
.active\:border-pink-800:active {
  border-color: var(--pink-800) !important;
}
.active\:border-pink-900:active {
  border-color: var(--pink-900) !important;
}

.border-indigo-50 {
  border-color: var(--indigo-50) !important;
}
.border-indigo-100 {
  border-color: var(--indigo-100) !important;
}
.border-indigo-200 {
  border-color: var(--indigo-200) !important;
}
.border-indigo-300 {
  border-color: var(--indigo-300) !important;
}
.border-indigo-400 {
  border-color: var(--indigo-400) !important;
}
.border-indigo-500 {
  border-color: var(--indigo-500) !important;
}
.border-indigo-600 {
  border-color: var(--indigo-600) !important;
}
.border-indigo-700 {
  border-color: var(--indigo-700) !important;
}
.border-indigo-800 {
  border-color: var(--indigo-800) !important;
}
.border-indigo-900 {
  border-color: var(--indigo-900) !important;
}

.focus\:border-indigo-50:focus {
  border-color: var(--indigo-50) !important;
}
.focus\:border-indigo-100:focus {
  border-color: var(--indigo-100) !important;
}
.focus\:border-indigo-200:focus {
  border-color: var(--indigo-200) !important;
}
.focus\:border-indigo-300:focus {
  border-color: var(--indigo-300) !important;
}
.focus\:border-indigo-400:focus {
  border-color: var(--indigo-400) !important;
}
.focus\:border-indigo-500:focus {
  border-color: var(--indigo-500) !important;
}
.focus\:border-indigo-600:focus {
  border-color: var(--indigo-600) !important;
}
.focus\:border-indigo-700:focus {
  border-color: var(--indigo-700) !important;
}
.focus\:border-indigo-800:focus {
  border-color: var(--indigo-800) !important;
}
.focus\:border-indigo-900:focus {
  border-color: var(--indigo-900) !important;
}

.hover\:border-indigo-50:hover {
  border-color: var(--indigo-50) !important;
}
.hover\:border-indigo-100:hover {
  border-color: var(--indigo-100) !important;
}
.hover\:border-indigo-200:hover {
  border-color: var(--indigo-200) !important;
}
.hover\:border-indigo-300:hover {
  border-color: var(--indigo-300) !important;
}
.hover\:border-indigo-400:hover {
  border-color: var(--indigo-400) !important;
}
.hover\:border-indigo-500:hover {
  border-color: var(--indigo-500) !important;
}
.hover\:border-indigo-600:hover {
  border-color: var(--indigo-600) !important;
}
.hover\:border-indigo-700:hover {
  border-color: var(--indigo-700) !important;
}
.hover\:border-indigo-800:hover {
  border-color: var(--indigo-800) !important;
}
.hover\:border-indigo-900:hover {
  border-color: var(--indigo-900) !important;
}

.active\:border-indigo-50:active {
  border-color: var(--indigo-50) !important;
}
.active\:border-indigo-100:active {
  border-color: var(--indigo-100) !important;
}
.active\:border-indigo-200:active {
  border-color: var(--indigo-200) !important;
}
.active\:border-indigo-300:active {
  border-color: var(--indigo-300) !important;
}
.active\:border-indigo-400:active {
  border-color: var(--indigo-400) !important;
}
.active\:border-indigo-500:active {
  border-color: var(--indigo-500) !important;
}
.active\:border-indigo-600:active {
  border-color: var(--indigo-600) !important;
}
.active\:border-indigo-700:active {
  border-color: var(--indigo-700) !important;
}
.active\:border-indigo-800:active {
  border-color: var(--indigo-800) !important;
}
.active\:border-indigo-900:active {
  border-color: var(--indigo-900) !important;
}

.border-teal-50 {
  border-color: var(--teal-50) !important;
}
.border-teal-100 {
  border-color: var(--teal-100) !important;
}
.border-teal-200 {
  border-color: var(--teal-200) !important;
}
.border-teal-300 {
  border-color: var(--teal-300) !important;
}
.border-teal-400 {
  border-color: var(--teal-400) !important;
}
.border-teal-500 {
  border-color: var(--teal-500) !important;
}
.border-teal-600 {
  border-color: var(--teal-600) !important;
}
.border-teal-700 {
  border-color: var(--teal-700) !important;
}
.border-teal-800 {
  border-color: var(--teal-800) !important;
}
.border-teal-900 {
  border-color: var(--teal-900) !important;
}

.focus\:border-teal-50:focus {
  border-color: var(--teal-50) !important;
}
.focus\:border-teal-100:focus {
  border-color: var(--teal-100) !important;
}
.focus\:border-teal-200:focus {
  border-color: var(--teal-200) !important;
}
.focus\:border-teal-300:focus {
  border-color: var(--teal-300) !important;
}
.focus\:border-teal-400:focus {
  border-color: var(--teal-400) !important;
}
.focus\:border-teal-500:focus {
  border-color: var(--teal-500) !important;
}
.focus\:border-teal-600:focus {
  border-color: var(--teal-600) !important;
}
.focus\:border-teal-700:focus {
  border-color: var(--teal-700) !important;
}
.focus\:border-teal-800:focus {
  border-color: var(--teal-800) !important;
}
.focus\:border-teal-900:focus {
  border-color: var(--teal-900) !important;
}

.hover\:border-teal-50:hover {
  border-color: var(--teal-50) !important;
}
.hover\:border-teal-100:hover {
  border-color: var(--teal-100) !important;
}
.hover\:border-teal-200:hover {
  border-color: var(--teal-200) !important;
}
.hover\:border-teal-300:hover {
  border-color: var(--teal-300) !important;
}
.hover\:border-teal-400:hover {
  border-color: var(--teal-400) !important;
}
.hover\:border-teal-500:hover {
  border-color: var(--teal-500) !important;
}
.hover\:border-teal-600:hover {
  border-color: var(--teal-600) !important;
}
.hover\:border-teal-700:hover {
  border-color: var(--teal-700) !important;
}
.hover\:border-teal-800:hover {
  border-color: var(--teal-800) !important;
}
.hover\:border-teal-900:hover {
  border-color: var(--teal-900) !important;
}

.active\:border-teal-50:active {
  border-color: var(--teal-50) !important;
}
.active\:border-teal-100:active {
  border-color: var(--teal-100) !important;
}
.active\:border-teal-200:active {
  border-color: var(--teal-200) !important;
}
.active\:border-teal-300:active {
  border-color: var(--teal-300) !important;
}
.active\:border-teal-400:active {
  border-color: var(--teal-400) !important;
}
.active\:border-teal-500:active {
  border-color: var(--teal-500) !important;
}
.active\:border-teal-600:active {
  border-color: var(--teal-600) !important;
}
.active\:border-teal-700:active {
  border-color: var(--teal-700) !important;
}
.active\:border-teal-800:active {
  border-color: var(--teal-800) !important;
}
.active\:border-teal-900:active {
  border-color: var(--teal-900) !important;
}

.border-orange-50 {
  border-color: var(--orange-50) !important;
}
.border-orange-100 {
  border-color: var(--orange-100) !important;
}
.border-orange-200 {
  border-color: var(--orange-200) !important;
}
.border-orange-300 {
  border-color: var(--orange-300) !important;
}
.border-orange-400 {
  border-color: var(--orange-400) !important;
}
.border-orange-500 {
  border-color: var(--orange-500) !important;
}
.border-orange-600 {
  border-color: var(--orange-600) !important;
}
.border-orange-700 {
  border-color: var(--orange-700) !important;
}
.border-orange-800 {
  border-color: var(--orange-800) !important;
}
.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-50:focus {
  border-color: var(--orange-50) !important;
}
.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}
.focus\:border-orange-200:focus {
  border-color: var(--orange-200) !important;
}
.focus\:border-orange-300:focus {
  border-color: var(--orange-300) !important;
}
.focus\:border-orange-400:focus {
  border-color: var(--orange-400) !important;
}
.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}
.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}
.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}
.focus\:border-orange-800:focus {
  border-color: var(--orange-800) !important;
}
.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-50:hover {
  border-color: var(--orange-50) !important;
}
.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}
.hover\:border-orange-200:hover {
  border-color: var(--orange-200) !important;
}
.hover\:border-orange-300:hover {
  border-color: var(--orange-300) !important;
}
.hover\:border-orange-400:hover {
  border-color: var(--orange-400) !important;
}
.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}
.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}
.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}
.hover\:border-orange-800:hover {
  border-color: var(--orange-800) !important;
}
.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-50:active {
  border-color: var(--orange-50) !important;
}
.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}
.active\:border-orange-200:active {
  border-color: var(--orange-200) !important;
}
.active\:border-orange-300:active {
  border-color: var(--orange-300) !important;
}
.active\:border-orange-400:active {
  border-color: var(--orange-400) !important;
}
.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}
.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}
.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}
.active\:border-orange-800:active {
  border-color: var(--orange-800) !important;
}
.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.border-bluegray-50 {
  border-color: var(--bluegray-50) !important;
}
.border-bluegray-100 {
  border-color: var(--bluegray-100) !important;
}
.border-bluegray-200 {
  border-color: var(--bluegray-200) !important;
}
.border-bluegray-300 {
  border-color: var(--bluegray-300) !important;
}
.border-bluegray-400 {
  border-color: var(--bluegray-400) !important;
}
.border-bluegray-500 {
  border-color: var(--bluegray-500) !important;
}
.border-bluegray-600 {
  border-color: var(--bluegray-600) !important;
}
.border-bluegray-700 {
  border-color: var(--bluegray-700) !important;
}
.border-bluegray-800 {
  border-color: var(--bluegray-800) !important;
}
.border-bluegray-900 {
  border-color: var(--bluegray-900) !important;
}

.focus\:border-bluegray-50:focus {
  border-color: var(--bluegray-50) !important;
}
.focus\:border-bluegray-100:focus {
  border-color: var(--bluegray-100) !important;
}
.focus\:border-bluegray-200:focus {
  border-color: var(--bluegray-200) !important;
}
.focus\:border-bluegray-300:focus {
  border-color: var(--bluegray-300) !important;
}
.focus\:border-bluegray-400:focus {
  border-color: var(--bluegray-400) !important;
}
.focus\:border-bluegray-500:focus {
  border-color: var(--bluegray-500) !important;
}
.focus\:border-bluegray-600:focus {
  border-color: var(--bluegray-600) !important;
}
.focus\:border-bluegray-700:focus {
  border-color: var(--bluegray-700) !important;
}
.focus\:border-bluegray-800:focus {
  border-color: var(--bluegray-800) !important;
}
.focus\:border-bluegray-900:focus {
  border-color: var(--bluegray-900) !important;
}

.hover\:border-bluegray-50:hover {
  border-color: var(--bluegray-50) !important;
}
.hover\:border-bluegray-100:hover {
  border-color: var(--bluegray-100) !important;
}
.hover\:border-bluegray-200:hover {
  border-color: var(--bluegray-200) !important;
}
.hover\:border-bluegray-300:hover {
  border-color: var(--bluegray-300) !important;
}
.hover\:border-bluegray-400:hover {
  border-color: var(--bluegray-400) !important;
}
.hover\:border-bluegray-500:hover {
  border-color: var(--bluegray-500) !important;
}
.hover\:border-bluegray-600:hover {
  border-color: var(--bluegray-600) !important;
}
.hover\:border-bluegray-700:hover {
  border-color: var(--bluegray-700) !important;
}
.hover\:border-bluegray-800:hover {
  border-color: var(--bluegray-800) !important;
}
.hover\:border-bluegray-900:hover {
  border-color: var(--bluegray-900) !important;
}

.active\:border-bluegray-50:active {
  border-color: var(--bluegray-50) !important;
}
.active\:border-bluegray-100:active {
  border-color: var(--bluegray-100) !important;
}
.active\:border-bluegray-200:active {
  border-color: var(--bluegray-200) !important;
}
.active\:border-bluegray-300:active {
  border-color: var(--bluegray-300) !important;
}
.active\:border-bluegray-400:active {
  border-color: var(--bluegray-400) !important;
}
.active\:border-bluegray-500:active {
  border-color: var(--bluegray-500) !important;
}
.active\:border-bluegray-600:active {
  border-color: var(--bluegray-600) !important;
}
.active\:border-bluegray-700:active {
  border-color: var(--bluegray-700) !important;
}
.active\:border-bluegray-800:active {
  border-color: var(--bluegray-800) !important;
}
.active\:border-bluegray-900:active {
  border-color: var(--bluegray-900) !important;
}

.border-purple-50 {
  border-color: var(--purple-50) !important;
}
.border-purple-100 {
  border-color: var(--purple-100) !important;
}
.border-purple-200 {
  border-color: var(--purple-200) !important;
}
.border-purple-300 {
  border-color: var(--purple-300) !important;
}
.border-purple-400 {
  border-color: var(--purple-400) !important;
}
.border-purple-500 {
  border-color: var(--purple-500) !important;
}
.border-purple-600 {
  border-color: var(--purple-600) !important;
}
.border-purple-700 {
  border-color: var(--purple-700) !important;
}
.border-purple-800 {
  border-color: var(--purple-800) !important;
}
.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-50:focus {
  border-color: var(--purple-50) !important;
}
.focus\:border-purple-100:focus {
  border-color: var(--purple-100) !important;
}
.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}
.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}
.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}
.focus\:border-purple-500:focus {
  border-color: var(--purple-500) !important;
}
.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}
.focus\:border-purple-700:focus {
  border-color: var(--purple-700) !important;
}
.focus\:border-purple-800:focus {
  border-color: var(--purple-800) !important;
}
.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-50:hover {
  border-color: var(--purple-50) !important;
}
.hover\:border-purple-100:hover {
  border-color: var(--purple-100) !important;
}
.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}
.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}
.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}
.hover\:border-purple-500:hover {
  border-color: var(--purple-500) !important;
}
.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}
.hover\:border-purple-700:hover {
  border-color: var(--purple-700) !important;
}
.hover\:border-purple-800:hover {
  border-color: var(--purple-800) !important;
}
.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-50:active {
  border-color: var(--purple-50) !important;
}
.active\:border-purple-100:active {
  border-color: var(--purple-100) !important;
}
.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}
.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}
.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}
.active\:border-purple-500:active {
  border-color: var(--purple-500) !important;
}
.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}
.active\:border-purple-700:active {
  border-color: var(--purple-700) !important;
}
.active\:border-purple-800:active {
  border-color: var(--purple-800) !important;
}
.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.border-gray-50 {
  border-color: var(--gray-50) !important;
}
.border-gray-100 {
  border-color: var(--gray-100) !important;
}
.border-gray-200 {
  border-color: var(--gray-200) !important;
}
.border-gray-300 {
  border-color: var(--gray-300) !important;
}
.border-gray-400 {
  border-color: var(--gray-400) !important;
}
.border-gray-500 {
  border-color: var(--gray-500) !important;
}
.border-gray-600 {
  border-color: var(--gray-600) !important;
}
.border-gray-700 {
  border-color: var(--gray-700) !important;
}
.border-gray-800 {
  border-color: var(--gray-800) !important;
}
.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-50:focus {
  border-color: var(--gray-50) !important;
}
.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}
.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}
.focus\:border-gray-300:focus {
  border-color: var(--gray-300) !important;
}
.focus\:border-gray-400:focus {
  border-color: var(--gray-400) !important;
}
.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}
.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}
.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}
.focus\:border-gray-800:focus {
  border-color: var(--gray-800) !important;
}
.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-50:hover {
  border-color: var(--gray-50) !important;
}
.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}
.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}
.hover\:border-gray-300:hover {
  border-color: var(--gray-300) !important;
}
.hover\:border-gray-400:hover {
  border-color: var(--gray-400) !important;
}
.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}
.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}
.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}
.hover\:border-gray-800:hover {
  border-color: var(--gray-800) !important;
}
.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-50:active {
  border-color: var(--gray-50) !important;
}
.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}
.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}
.active\:border-gray-300:active {
  border-color: var(--gray-300) !important;
}
.active\:border-gray-400:active {
  border-color: var(--gray-400) !important;
}
.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}
.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}
.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}
.active\:border-gray-800:active {
  border-color: var(--gray-800) !important;
}
.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.bg-white-alpha-10 {
  background-color: rgba(255,255,255,0.1) !important;
}
.bg-white-alpha-20 {
  background-color: rgba(255,255,255,0.2) !important;
}
.bg-white-alpha-30 {
  background-color: rgba(255,255,255,0.3) !important;
}
.bg-white-alpha-40 {
  background-color: rgba(255,255,255,0.4) !important;
}
.bg-white-alpha-50 {
  background-color: rgba(255,255,255,0.5) !important;
}
.bg-white-alpha-60 {
  background-color: rgba(255,255,255,0.6) !important;
}
.bg-white-alpha-70 {
  background-color: rgba(255,255,255,0.7) !important;
}
.bg-white-alpha-80 {
  background-color: rgba(255,255,255,0.8) !important;
}
.bg-white-alpha-90 {
  background-color: rgba(255,255,255,0.9) !important;
}

.hover\:bg-white-alpha-10:hover {
  background-color: rgba(255,255,255,0.1) !important;
}
.hover\:bg-white-alpha-20:hover {
  background-color: rgba(255,255,255,0.2) !important;
}
.hover\:bg-white-alpha-30:hover {
  background-color: rgba(255,255,255,0.3) !important;
}
.hover\:bg-white-alpha-40:hover {
  background-color: rgba(255,255,255,0.4) !important;
}
.hover\:bg-white-alpha-50:hover {
  background-color: rgba(255,255,255,0.5) !important;
}
.hover\:bg-white-alpha-60:hover {
  background-color: rgba(255,255,255,0.6) !important;
}
.hover\:bg-white-alpha-70:hover {
  background-color: rgba(255,255,255,0.7) !important;
}
.hover\:bg-white-alpha-80:hover {
  background-color: rgba(255,255,255,0.8) !important;
}
.hover\:bg-white-alpha-90:hover {
  background-color: rgba(255,255,255,0.9) !important;
}

.focus\:bg-white-alpha-10:focus {
  background-color: rgba(255,255,255,0.1) !important;
}
.focus\:bg-white-alpha-20:focus {
  background-color: rgba(255,255,255,0.2) !important;
}
.focus\:bg-white-alpha-30:focus {
  background-color: rgba(255,255,255,0.3) !important;
}
.focus\:bg-white-alpha-40:focus {
  background-color: rgba(255,255,255,0.4) !important;
}
.focus\:bg-white-alpha-50:focus {
  background-color: rgba(255,255,255,0.5) !important;
}
.focus\:bg-white-alpha-60:focus {
  background-color: rgba(255,255,255,0.6) !important;
}
.focus\:bg-white-alpha-70:focus {
  background-color: rgba(255,255,255,0.7) !important;
}
.focus\:bg-white-alpha-80:focus {
  background-color: rgba(255,255,255,0.8) !important;
}
.focus\:bg-white-alpha-90:focus {
  background-color: rgba(255,255,255,0.9) !important;
}

.active\:bg-white-alpha-10:active {
  background-color: rgba(255,255,255,0.1) !important;
}
.active\:bg-white-alpha-20:active {
  background-color: rgba(255,255,255,0.2) !important;
}
.active\:bg-white-alpha-30:active {
  background-color: rgba(255,255,255,0.3) !important;
}
.active\:bg-white-alpha-40:active {
  background-color: rgba(255,255,255,0.4) !important;
}
.active\:bg-white-alpha-50:active {
  background-color: rgba(255,255,255,0.5) !important;
}
.active\:bg-white-alpha-60:active {
  background-color: rgba(255,255,255,0.6) !important;
}
.active\:bg-white-alpha-70:active {
  background-color: rgba(255,255,255,0.7) !important;
}
.active\:bg-white-alpha-80:active {
  background-color: rgba(255,255,255,0.8) !important;
}
.active\:bg-white-alpha-90:active {
  background-color: rgba(255,255,255,0.9) !important;
}

.bg-black-alpha-10 {
  background-color: rgba(0,0,0,0.1) !important;
}
.bg-black-alpha-20 {
  background-color: rgba(0,0,0,0.2) !important;
}
.bg-black-alpha-30 {
  background-color: rgba(0,0,0,0.3) !important;
}
.bg-black-alpha-40 {
  background-color: rgba(0,0,0,0.4) !important;
}
.bg-black-alpha-50 {
  background-color: rgba(0,0,0,0.5) !important;
}
.bg-black-alpha-60 {
  background-color: rgba(0,0,0,0.6) !important;
}
.bg-black-alpha-70 {
  background-color: rgba(0,0,0,0.7) !important;
}
.bg-black-alpha-80 {
  background-color: rgba(0,0,0,0.8) !important;
}
.bg-black-alpha-90 {
  background-color: rgba(0,0,0,0.9) !important;
}

.hover\:bg-black-alpha-10:hover {
  background-color: rgba(0,0,0,0.1) !important;
}
.hover\:bg-black-alpha-20:hover {
  background-color: rgba(0,0,0,0.2) !important;
}
.hover\:bg-black-alpha-30:hover {
  background-color: rgba(0,0,0,0.3) !important;
}
.hover\:bg-black-alpha-40:hover {
  background-color: rgba(0,0,0,0.4) !important;
}
.hover\:bg-black-alpha-50:hover {
  background-color: rgba(0,0,0,0.5) !important;
}
.hover\:bg-black-alpha-60:hover {
  background-color: rgba(0,0,0,0.6) !important;
}
.hover\:bg-black-alpha-70:hover {
  background-color: rgba(0,0,0,0.7) !important;
}
.hover\:bg-black-alpha-80:hover {
  background-color: rgba(0,0,0,0.8) !important;
}
.hover\:bg-black-alpha-90:hover {
  background-color: rgba(0,0,0,0.9) !important;
}

.focus\:bg-black-alpha-10:focus {
  background-color: rgba(0,0,0,0.1) !important;
}
.focus\:bg-black-alpha-20:focus {
  background-color: rgba(0,0,0,0.2) !important;
}
.focus\:bg-black-alpha-30:focus {
  background-color: rgba(0,0,0,0.3) !important;
}
.focus\:bg-black-alpha-40:focus {
  background-color: rgba(0,0,0,0.4) !important;
}
.focus\:bg-black-alpha-50:focus {
  background-color: rgba(0,0,0,0.5) !important;
}
.focus\:bg-black-alpha-60:focus {
  background-color: rgba(0,0,0,0.6) !important;
}
.focus\:bg-black-alpha-70:focus {
  background-color: rgba(0,0,0,0.7) !important;
}
.focus\:bg-black-alpha-80:focus {
  background-color: rgba(0,0,0,0.8) !important;
}
.focus\:bg-black-alpha-90:focus {
  background-color: rgba(0,0,0,0.9) !important;
}

.active\:bg-black-alpha-10:active {
  background-color: rgba(0,0,0,0.1) !important;
}
.active\:bg-black-alpha-20:active {
  background-color: rgba(0,0,0,0.2) !important;
}
.active\:bg-black-alpha-30:active {
  background-color: rgba(0,0,0,0.3) !important;
}
.active\:bg-black-alpha-40:active {
  background-color: rgba(0,0,0,0.4) !important;
}
.active\:bg-black-alpha-50:active {
  background-color: rgba(0,0,0,0.5) !important;
}
.active\:bg-black-alpha-60:active {
  background-color: rgba(0,0,0,0.6) !important;
}
.active\:bg-black-alpha-70:active {
  background-color: rgba(0,0,0,0.7) !important;
}
.active\:bg-black-alpha-80:active {
  background-color: rgba(0,0,0,0.8) !important;
}
.active\:bg-black-alpha-90:active {
  background-color: rgba(0,0,0,0.9) !important;
}

.border-white-alpha-10 {
  border-color: rgba(255,255,255,0.1) !important;
}
.border-white-alpha-20 {
  border-color: rgba(255,255,255,0.2) !important;
}
.border-white-alpha-30 {
  border-color: rgba(255,255,255,0.3) !important;
}
.border-white-alpha-40 {
  border-color: rgba(255,255,255,0.4) !important;
}
.border-white-alpha-50 {
  border-color: rgba(255,255,255,0.5) !important;
}
.border-white-alpha-60 {
  border-color: rgba(255,255,255,0.6) !important;
}
.border-white-alpha-70 {
  border-color: rgba(255,255,255,0.7) !important;
}
.border-white-alpha-80 {
  border-color: rgba(255,255,255,0.8) !important;
}
.border-white-alpha-90 {
  border-color: rgba(255,255,255,0.9) !important;
}

.hover\:border-white-alpha-10:hover {
  border-color: rgba(255,255,255,0.1) !important;
}
.hover\:border-white-alpha-20:hover {
  border-color: rgba(255,255,255,0.2) !important;
}
.hover\:border-white-alpha-30:hover {
  border-color: rgba(255,255,255,0.3) !important;
}
.hover\:border-white-alpha-40:hover {
  border-color: rgba(255,255,255,0.4) !important;
}
.hover\:border-white-alpha-50:hover {
  border-color: rgba(255,255,255,0.5) !important;
}
.hover\:border-white-alpha-60:hover {
  border-color: rgba(255,255,255,0.6) !important;
}
.hover\:border-white-alpha-70:hover {
  border-color: rgba(255,255,255,0.7) !important;
}
.hover\:border-white-alpha-80:hover {
  border-color: rgba(255,255,255,0.8) !important;
}
.hover\:border-white-alpha-90:hover {
  border-color: rgba(255,255,255,0.9) !important;
}

.focus\:border-white-alpha-10:focus {
  border-color: rgba(255,255,255,0.1) !important;
}
.focus\:border-white-alpha-20:focus {
  border-color: rgba(255,255,255,0.2) !important;
}
.focus\:border-white-alpha-30:focus {
  border-color: rgba(255,255,255,0.3) !important;
}
.focus\:border-white-alpha-40:focus {
  border-color: rgba(255,255,255,0.4) !important;
}
.focus\:border-white-alpha-50:focus {
  border-color: rgba(255,255,255,0.5) !important;
}
.focus\:border-white-alpha-60:focus {
  border-color: rgba(255,255,255,0.6) !important;
}
.focus\:border-white-alpha-70:focus {
  border-color: rgba(255,255,255,0.7) !important;
}
.focus\:border-white-alpha-80:focus {
  border-color: rgba(255,255,255,0.8) !important;
}
.focus\:border-white-alpha-90:focus {
  border-color: rgba(255,255,255,0.9) !important;
}

.active\:border-white-alpha-10:active {
  border-color: rgba(255,255,255,0.1) !important;
}
.active\:border-white-alpha-20:active {
  border-color: rgba(255,255,255,0.2) !important;
}
.active\:border-white-alpha-30:active {
  border-color: rgba(255,255,255,0.3) !important;
}
.active\:border-white-alpha-40:active {
  border-color: rgba(255,255,255,0.4) !important;
}
.active\:border-white-alpha-50:active {
  border-color: rgba(255,255,255,0.5) !important;
}
.active\:border-white-alpha-60:active {
  border-color: rgba(255,255,255,0.6) !important;
}
.active\:border-white-alpha-70:active {
  border-color: rgba(255,255,255,0.7) !important;
}
.active\:border-white-alpha-80:active {
  border-color: rgba(255,255,255,0.8) !important;
}
.active\:border-white-alpha-90:active {
  border-color: rgba(255,255,255,0.9) !important;
}

.border-black-alpha-10 {
  border-color: rgba(0,0,0,0.1) !important;
}
.border-black-alpha-20 {
  border-color: rgba(0,0,0,0.2) !important;
}
.border-black-alpha-30 {
  border-color: rgba(0,0,0,0.3) !important;
}
.border-black-alpha-40 {
  border-color: rgba(0,0,0,0.4) !important;
}
.border-black-alpha-50 {
  border-color: rgba(0,0,0,0.5) !important;
}
.border-black-alpha-60 {
  border-color: rgba(0,0,0,0.6) !important;
}
.border-black-alpha-70 {
  border-color: rgba(0,0,0,0.7) !important;
}
.border-black-alpha-80 {
  border-color: rgba(0,0,0,0.8) !important;
}
.border-black-alpha-90 {
  border-color: rgba(0,0,0,0.9) !important;
}

.hover\:border-black-alpha-10:hover {
  border-color: rgba(0,0,0,0.1) !important;
}
.hover\:border-black-alpha-20:hover {
  border-color: rgba(0,0,0,0.2) !important;
}
.hover\:border-black-alpha-30:hover {
  border-color: rgba(0,0,0,0.3) !important;
}
.hover\:border-black-alpha-40:hover {
  border-color: rgba(0,0,0,0.4) !important;
}
.hover\:border-black-alpha-50:hover {
  border-color: rgba(0,0,0,0.5) !important;
}
.hover\:border-black-alpha-60:hover {
  border-color: rgba(0,0,0,0.6) !important;
}
.hover\:border-black-alpha-70:hover {
  border-color: rgba(0,0,0,0.7) !important;
}
.hover\:border-black-alpha-80:hover {
  border-color: rgba(0,0,0,0.8) !important;
}
.hover\:border-black-alpha-90:hover {
  border-color: rgba(0,0,0,0.9) !important;
}

.focus\:border-black-alpha-10:focus {
  border-color: rgba(0,0,0,0.1) !important;
}
.focus\:border-black-alpha-20:focus {
  border-color: rgba(0,0,0,0.2) !important;
}
.focus\:border-black-alpha-30:focus {
  border-color: rgba(0,0,0,0.3) !important;
}
.focus\:border-black-alpha-40:focus {
  border-color: rgba(0,0,0,0.4) !important;
}
.focus\:border-black-alpha-50:focus {
  border-color: rgba(0,0,0,0.5) !important;
}
.focus\:border-black-alpha-60:focus {
  border-color: rgba(0,0,0,0.6) !important;
}
.focus\:border-black-alpha-70:focus {
  border-color: rgba(0,0,0,0.7) !important;
}
.focus\:border-black-alpha-80:focus {
  border-color: rgba(0,0,0,0.8) !important;
}
.focus\:border-black-alpha-90:focus {
  border-color: rgba(0,0,0,0.9) !important;
}

.active\:border-black-alpha-10:active {
  border-color: rgba(0,0,0,0.1) !important;
}
.active\:border-black-alpha-20:active {
  border-color: rgba(0,0,0,0.2) !important;
}
.active\:border-black-alpha-30:active {
  border-color: rgba(0,0,0,0.3) !important;
}
.active\:border-black-alpha-40:active {
  border-color: rgba(0,0,0,0.4) !important;
}
.active\:border-black-alpha-50:active {
  border-color: rgba(0,0,0,0.5) !important;
}
.active\:border-black-alpha-60:active {
  border-color: rgba(0,0,0,0.6) !important;
}
.active\:border-black-alpha-70:active {
  border-color: rgba(0,0,0,0.7) !important;
}
.active\:border-black-alpha-80:active {
  border-color: rgba(0,0,0,0.8) !important;
}
.active\:border-black-alpha-90:active {
  border-color: rgba(0,0,0,0.9) !important;
}

.text-white-alpha-10 {
  color: rgba(255,255,255,0.1) !important;
}
.text-white-alpha-20 {
  color: rgba(255,255,255,0.2) !important;
}
.text-white-alpha-30 {
  color: rgba(255,255,255,0.3) !important;
}
.text-white-alpha-40 {
  color: rgba(255,255,255,0.4) !important;
}
.text-white-alpha-50 {
  color: rgba(255,255,255,0.5) !important;
}
.text-white-alpha-60 {
  color: rgba(255,255,255,0.6) !important;
}
.text-white-alpha-70 {
  color: rgba(255,255,255,0.7) !important;
}
.text-white-alpha-80 {
  color: rgba(255,255,255,0.8) !important;
}
.text-white-alpha-90 {
  color: rgba(255,255,255,0.9) !important;
}

.hover\:text-white-alpha-10:hover {
  color: rgba(255,255,255,0.1) !important;
}
.hover\:text-white-alpha-20:hover {
  color: rgba(255,255,255,0.2) !important;
}
.hover\:text-white-alpha-30:hover {
  color: rgba(255,255,255,0.3) !important;
}
.hover\:text-white-alpha-40:hover {
  color: rgba(255,255,255,0.4) !important;
}
.hover\:text-white-alpha-50:hover {
  color: rgba(255,255,255,0.5) !important;
}
.hover\:text-white-alpha-60:hover {
  color: rgba(255,255,255,0.6) !important;
}
.hover\:text-white-alpha-70:hover {
  color: rgba(255,255,255,0.7) !important;
}
.hover\:text-white-alpha-80:hover {
  color: rgba(255,255,255,0.8) !important;
}
.hover\:text-white-alpha-90:hover {
  color: rgba(255,255,255,0.9) !important;
}

.focus\:text-white-alpha-10:focus {
  color: rgba(255,255,255,0.1) !important;
}
.focus\:text-white-alpha-20:focus {
  color: rgba(255,255,255,0.2) !important;
}
.focus\:text-white-alpha-30:focus {
  color: rgba(255,255,255,0.3) !important;
}
.focus\:text-white-alpha-40:focus {
  color: rgba(255,255,255,0.4) !important;
}
.focus\:text-white-alpha-50:focus {
  color: rgba(255,255,255,0.5) !important;
}
.focus\:text-white-alpha-60:focus {
  color: rgba(255,255,255,0.6) !important;
}
.focus\:text-white-alpha-70:focus {
  color: rgba(255,255,255,0.7) !important;
}
.focus\:text-white-alpha-80:focus {
  color: rgba(255,255,255,0.8) !important;
}
.focus\:text-white-alpha-90:focus {
  color: rgba(255,255,255,0.9) !important;
}

.active\:text-white-alpha-10:active {
  color: rgba(255,255,255,0.1) !important;
}
.active\:text-white-alpha-20:active {
  color: rgba(255,255,255,0.2) !important;
}
.active\:text-white-alpha-30:active {
  color: rgba(255,255,255,0.3) !important;
}
.active\:text-white-alpha-40:active {
  color: rgba(255,255,255,0.4) !important;
}
.active\:text-white-alpha-50:active {
  color: rgba(255,255,255,0.5) !important;
}
.active\:text-white-alpha-60:active {
  color: rgba(255,255,255,0.6) !important;
}
.active\:text-white-alpha-70:active {
  color: rgba(255,255,255,0.7) !important;
}
.active\:text-white-alpha-80:active {
  color: rgba(255,255,255,0.8) !important;
}
.active\:text-white-alpha-90:active {
  color: rgba(255,255,255,0.9) !important;
}

.text-black-alpha-10 {
  color: rgba(0,0,0,0.1) !important;
}
.text-black-alpha-20 {
  color: rgba(0,0,0,0.2) !important;
}
.text-black-alpha-30 {
  color: rgba(0,0,0,0.3) !important;
}
.text-black-alpha-40 {
  color: rgba(0,0,0,0.4) !important;
}
.text-black-alpha-50 {
  color: rgba(0,0,0,0.5) !important;
}
.text-black-alpha-60 {
  color: rgba(0,0,0,0.6) !important;
}
.text-black-alpha-70 {
  color: rgba(0,0,0,0.7) !important;
}
.text-black-alpha-80 {
  color: rgba(0,0,0,0.8) !important;
}
.text-black-alpha-90 {
  color: rgba(0,0,0,0.9) !important;
}

.hover\:text-black-alpha-10:hover {
  color: rgba(0,0,0,0.1) !important;
}
.hover\:text-black-alpha-20:hover {
  color: rgba(0,0,0,0.2) !important;
}
.hover\:text-black-alpha-30:hover {
  color: rgba(0,0,0,0.3) !important;
}
.hover\:text-black-alpha-40:hover {
  color: rgba(0,0,0,0.4) !important;
}
.hover\:text-black-alpha-50:hover {
  color: rgba(0,0,0,0.5) !important;
}
.hover\:text-black-alpha-60:hover {
  color: rgba(0,0,0,0.6) !important;
}
.hover\:text-black-alpha-70:hover {
  color: rgba(0,0,0,0.7) !important;
}
.hover\:text-black-alpha-80:hover {
  color: rgba(0,0,0,0.8) !important;
}
.hover\:text-black-alpha-90:hover {
  color: rgba(0,0,0,0.9) !important;
}

.focus\:text-black-alpha-10:focus {
  color: rgba(0,0,0,0.1) !important;
}
.focus\:text-black-alpha-20:focus {
  color: rgba(0,0,0,0.2) !important;
}
.focus\:text-black-alpha-30:focus {
  color: rgba(0,0,0,0.3) !important;
}
.focus\:text-black-alpha-40:focus {
  color: rgba(0,0,0,0.4) !important;
}
.focus\:text-black-alpha-50:focus {
  color: rgba(0,0,0,0.5) !important;
}
.focus\:text-black-alpha-60:focus {
  color: rgba(0,0,0,0.6) !important;
}
.focus\:text-black-alpha-70:focus {
  color: rgba(0,0,0,0.7) !important;
}
.focus\:text-black-alpha-80:focus {
  color: rgba(0,0,0,0.8) !important;
}
.focus\:text-black-alpha-90:focus {
  color: rgba(0,0,0,0.9) !important;
}

.active\:text-black-alpha-10:active {
  color: rgba(0,0,0,0.1) !important;
}
.active\:text-black-alpha-20:active {
  color: rgba(0,0,0,0.2) !important;
}
.active\:text-black-alpha-30:active {
  color: rgba(0,0,0,0.3) !important;
}
.active\:text-black-alpha-40:active {
  color: rgba(0,0,0,0.4) !important;
}
.active\:text-black-alpha-50:active {
  color: rgba(0,0,0,0.5) !important;
}
.active\:text-black-alpha-60:active {
  color: rgba(0,0,0,0.6) !important;
}
.active\:text-black-alpha-70:active {
  color: rgba(0,0,0,0.7) !important;
}
.active\:text-black-alpha-80:active {
  color: rgba(0,0,0,0.8) !important;
}
.active\:text-black-alpha-90:active {
  color: rgba(0,0,0,0.9) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}

.hover\:bg-primary:hover {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text) !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  background-color: var(--primary-color-text) !important;
  color: var(--primary-color) !important;
}

.hover\:bg-primary-reverse:hover {
  background-color: var(--primary-color-text) !important;
  color: var(--primary-color) !important;
}

.text-white {
  color: #ffffff !important;
}

.hover\:text-white:hover {
  color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.hover\:bg-white:hover {
  background-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.hover\:border-white:hover {
  border-color: #ffffff !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.field {
  margin-bottom: 1rem;
}

.field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.field.grid > label {
  display: flex;
  align-items: center;
}

.field > small {
  margin-top: 0.25rem;
}

.field.grid,
.formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed,
.formgrid.grid .col-fixed,
.field.grid .col,
.formgrid.grid .col,
.field.grid .col-1,
.formgrid.grid .col-1,
.field.grid .col-2,
.formgrid.grid .col-2,
.field.grid .col-3,
.formgrid.grid .col-3,
.field.grid .col-4,
.formgrid.grid .col-4,
.field.grid .col-5,
.formgrid.grid .col-5,
.field.grid .col-6,
.formgrid.grid .col-6,
.field.grid .col-7,
.formgrid.grid .col-7,
.field.grid .col-8,
.formgrid.grid .col-8,
.field.grid .col-9,
.formgrid.grid .col-9,
.field.grid .col-10,
.formgrid.grid .col-10,
.field.grid .col-11,
.formgrid.grid .col-11,
.field.grid .col-12,
.formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.field-checkbox > label,
.field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:inline {
    display: inline !important;
  }
  .sm\:inline-block {
    display: inline-block !important;
  }
  .sm\:flex {
    display: flex !important;
  }
  .sm\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline {
    display: inline !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: flex !important;
  }
  .md\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline {
    display: inline !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: flex !important;
  }
  .lg\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:hidden {
    display: none !important;
  }
  .xl\:block {
    display: block !important;
  }
  .xl\:inline {
    display: inline !important;
  }
  .xl\:inline-block {
    display: inline-block !important;
  }
  .xl\:flex {
    display: flex !important;
  }
  .xl\:inline-flex {
    display: inline-flex !important;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-justify {
    text-align: justify !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-justify {
    text-align: justify !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-justify {
    text-align: justify !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-justify {
    text-align: justify !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
}
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 576px) {
  .sm\:font-light {
    font-weight: 300 !important;
  }
  .sm\:font-normal {
    font-weight: 400 !important;
  }
  .sm\:font-medium {
    font-weight: 500 !important;
  }
  .sm\:font-semibold {
    font-weight: 600 !important;
  }
  .sm\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:font-light {
    font-weight: 300 !important;
  }
  .md\:font-normal {
    font-weight: 400 !important;
  }
  .md\:font-medium {
    font-weight: 500 !important;
  }
  .md\:font-semibold {
    font-weight: 600 !important;
  }
  .md\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:font-light {
    font-weight: 300 !important;
  }
  .lg\:font-normal {
    font-weight: 400 !important;
  }
  .lg\:font-medium {
    font-weight: 500 !important;
  }
  .lg\:font-semibold {
    font-weight: 600 !important;
  }
  .lg\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:font-light {
    font-weight: 300 !important;
  }
  .xl\:font-normal {
    font-weight: 400 !important;
  }
  .xl\:font-medium {
    font-weight: 500 !important;
  }
  .xl\:font-semibold {
    font-weight: 600 !important;
  }
  .xl\:font-bold {
    font-weight: 700 !important;
  }
}
.font-italic {
  font-style: italic !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-base {
  font-size: 1rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem !important;
}

.text-3xl {
  font-size: 1.75rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.5rem !important;
}

.text-6xl {
  font-size: 3rem !important;
}

.text-7xl {
  font-size: 4rem !important;
}

.text-8xl {
  font-size: 6rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:text-xs {
    font-size: 0.75rem !important;
  }
  .sm\:text-sm {
    font-size: 0.875rem !important;
  }
  .sm\:text-base {
    font-size: 1rem !important;
  }
  .sm\:text-lg {
    font-size: 1.125rem !important;
  }
  .sm\:text-xl {
    font-size: 1.25rem !important;
  }
  .sm\:text-2xl {
    font-size: 1.5rem !important;
  }
  .sm\:text-3xl {
    font-size: 1.75rem !important;
  }
  .sm\:text-4xl {
    font-size: 2rem !important;
  }
  .sm\:text-5xl {
    font-size: 2.5rem !important;
  }
  .sm\:text-6xl {
    font-size: 3rem !important;
  }
  .sm\:text-7xl {
    font-size: 4rem !important;
  }
  .sm\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-xs {
    font-size: 0.75rem !important;
  }
  .md\:text-sm {
    font-size: 0.875rem !important;
  }
  .md\:text-base {
    font-size: 1rem !important;
  }
  .md\:text-lg {
    font-size: 1.125rem !important;
  }
  .md\:text-xl {
    font-size: 1.25rem !important;
  }
  .md\:text-2xl {
    font-size: 1.5rem !important;
  }
  .md\:text-3xl {
    font-size: 1.75rem !important;
  }
  .md\:text-4xl {
    font-size: 2rem !important;
  }
  .md\:text-5xl {
    font-size: 2.5rem !important;
  }
  .md\:text-6xl {
    font-size: 3rem !important;
  }
  .md\:text-7xl {
    font-size: 4rem !important;
  }
  .md\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-xs {
    font-size: 0.75rem !important;
  }
  .lg\:text-sm {
    font-size: 0.875rem !important;
  }
  .lg\:text-base {
    font-size: 1rem !important;
  }
  .lg\:text-lg {
    font-size: 1.125rem !important;
  }
  .lg\:text-xl {
    font-size: 1.25rem !important;
  }
  .lg\:text-2xl {
    font-size: 1.5rem !important;
  }
  .lg\:text-3xl {
    font-size: 1.75rem !important;
  }
  .lg\:text-4xl {
    font-size: 2rem !important;
  }
  .lg\:text-5xl {
    font-size: 2.5rem !important;
  }
  .lg\:text-6xl {
    font-size: 3rem !important;
  }
  .lg\:text-7xl {
    font-size: 4rem !important;
  }
  .lg\:text-8xl {
    font-size: 6rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-xs {
    font-size: 0.75rem !important;
  }
  .xl\:text-sm {
    font-size: 0.875rem !important;
  }
  .xl\:text-base {
    font-size: 1rem !important;
  }
  .xl\:text-lg {
    font-size: 1.125rem !important;
  }
  .xl\:text-xl {
    font-size: 1.25rem !important;
  }
  .xl\:text-2xl {
    font-size: 1.5rem !important;
  }
  .xl\:text-3xl {
    font-size: 1.75rem !important;
  }
  .xl\:text-4xl {
    font-size: 2rem !important;
  }
  .xl\:text-5xl {
    font-size: 2.5rem !important;
  }
  .xl\:text-6xl {
    font-size: 3rem !important;
  }
  .xl\:text-7xl {
    font-size: 4rem !important;
  }
  .xl\:text-8xl {
    font-size: 6rem !important;
  }
}
.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:flex-column {
    flex-direction: column !important;
  }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg\:flex-column {
    flex-direction: column !important;
  }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl\:flex-column {
    flex-direction: column !important;
  }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    justify-content: center !important;
  }
  .sm\:justify-content-between {
    justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    justify-content: space-around !important;
  }
  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    justify-content: center !important;
  }
  .md\:justify-content-between {
    justify-content: space-between !important;
  }
  .md\:justify-content-around {
    justify-content: space-around !important;
  }
  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    justify-content: center !important;
  }
  .lg\:justify-content-between {
    justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    justify-content: space-around !important;
  }
  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    justify-content: center !important;
  }
  .xl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }
  .sm\:align-content-end {
    align-content: flex-end !important;
  }
  .sm\:align-content-center {
    align-content: center !important;
  }
  .sm\:align-content-between {
    align-content: space-between !important;
  }
  .sm\:align-content-around {
    align-content: space-around !important;
  }
  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }
  .md\:align-content-end {
    align-content: flex-end !important;
  }
  .md\:align-content-center {
    align-content: center !important;
  }
  .md\:align-content-between {
    align-content: space-between !important;
  }
  .md\:align-content-around {
    align-content: space-around !important;
  }
  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }
  .lg\:align-content-end {
    align-content: flex-end !important;
  }
  .lg\:align-content-center {
    align-content: center !important;
  }
  .lg\:align-content-between {
    align-content: space-between !important;
  }
  .lg\:align-content-around {
    align-content: space-around !important;
  }
  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }
  .xl\:align-content-end {
    align-content: flex-end !important;
  }
  .xl\:align-content-center {
    align-content: center !important;
  }
  .xl\:align-content-between {
    align-content: space-between !important;
  }
  .xl\:align-content-around {
    align-content: space-around !important;
  }
  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }
  .sm\:align-items-start {
    align-items: flex-start !important;
  }
  .sm\:align-items-center {
    align-items: center !important;
  }
  .sm\:align-items-end {
    align-items: flex-end !important;
  }
  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }
  .md\:align-items-start {
    align-items: flex-start !important;
  }
  .md\:align-items-center {
    align-items: center !important;
  }
  .md\:align-items-end {
    align-items: flex-end !important;
  }
  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }
  .lg\:align-items-start {
    align-items: flex-start !important;
  }
  .lg\:align-items-center {
    align-items: center !important;
  }
  .lg\:align-items-end {
    align-items: flex-end !important;
  }
  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xl\:align-items-start {
    align-items: flex-start !important;
  }
  .xl\:align-items-center {
    align-items: center !important;
  }
  .xl\:align-items-end {
    align-items: flex-end !important;
  }
  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}
.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }
  .sm\:align-self-start {
    align-self: flex-start !important;
  }
  .sm\:align-self-end {
    align-self: flex-end !important;
  }
  .sm\:align-self-center {
    align-self: center !important;
  }
  .sm\:align-self-stretch {
    align-self: stretch !important;
  }
  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }
  .md\:align-self-start {
    align-self: flex-start !important;
  }
  .md\:align-self-end {
    align-self: flex-end !important;
  }
  .md\:align-self-center {
    align-self: center !important;
  }
  .md\:align-self-stretch {
    align-self: stretch !important;
  }
  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }
  .lg\:align-self-start {
    align-self: flex-start !important;
  }
  .lg\:align-self-end {
    align-self: flex-end !important;
  }
  .lg\:align-self-center {
    align-self: center !important;
  }
  .lg\:align-self-stretch {
    align-self: stretch !important;
  }
  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }
  .xl\:align-self-start {
    align-self: flex-start !important;
  }
  .xl\:align-self-end {
    align-self: flex-end !important;
  }
  .xl\:align-self-center {
    align-self: center !important;
  }
  .xl\:align-self-stretch {
    align-self: stretch !important;
  }
  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}
.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }
  .sm\:flex-order-1 {
    order: 1 !important;
  }
  .sm\:flex-order-2 {
    order: 2 !important;
  }
  .sm\:flex-order-3 {
    order: 3 !important;
  }
  .sm\:flex-order-4 {
    order: 4 !important;
  }
  .sm\:flex-order-5 {
    order: 5 !important;
  }
  .sm\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }
  .md\:flex-order-1 {
    order: 1 !important;
  }
  .md\:flex-order-2 {
    order: 2 !important;
  }
  .md\:flex-order-3 {
    order: 3 !important;
  }
  .md\:flex-order-4 {
    order: 4 !important;
  }
  .md\:flex-order-5 {
    order: 5 !important;
  }
  .md\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }
  .lg\:flex-order-1 {
    order: 1 !important;
  }
  .lg\:flex-order-2 {
    order: 2 !important;
  }
  .lg\:flex-order-3 {
    order: 3 !important;
  }
  .lg\:flex-order-4 {
    order: 4 !important;
  }
  .lg\:flex-order-5 {
    order: 5 !important;
  }
  .lg\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }
  .xl\:flex-order-1 {
    order: 1 !important;
  }
  .xl\:flex-order-2 {
    order: 2 !important;
  }
  .xl\:flex-order-3 {
    order: 3 !important;
  }
  .xl\:flex-order-4 {
    order: 4 !important;
  }
  .xl\:flex-order-5 {
    order: 5 !important;
  }
  .xl\:flex-order-6 {
    order: 6 !important;
  }
}
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }
  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }
  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }
  .sm\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-1 {
    flex: 1 1 0% !important;
  }
  .md\:flex-auto {
    flex: 1 1 auto !important;
  }
  .md\:flex-initial {
    flex: 0 1 auto !important;
  }
  .md\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }
  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }
  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }
  .lg\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xl\:flex-none {
    flex: none !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.p-0 {
  padding: 0rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.p-7 {
  padding: 4rem !important;
}

.p-8 {
  padding: 5rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.pt-7 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 5rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 2rem !important;
}

.pr-6 {
  padding-right: 3rem !important;
}

.pr-7 {
  padding-right: 4rem !important;
}

.pr-8 {
  padding-right: 5rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 2rem !important;
}

.pl-6 {
  padding-left: 3rem !important;
}

.pl-7 {
  padding-left: 4rem !important;
}

.pl-8 {
  padding-left: 5rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 2rem !important;
}

.pb-6 {
  padding-bottom: 3rem !important;
}

.pb-7 {
  padding-bottom: 4rem !important;
}

.pb-8 {
  padding-bottom: 5rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-7 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-8 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-7 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-8 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0rem !important;
  }

  .sm\:p-1 {
    padding: 0.25rem !important;
  }

  .sm\:p-2 {
    padding: 0.5rem !important;
  }

  .sm\:p-3 {
    padding: 1rem !important;
  }

  .sm\:p-4 {
    padding: 1.5rem !important;
  }

  .sm\:p-5 {
    padding: 2rem !important;
  }

  .sm\:p-6 {
    padding: 3rem !important;
  }

  .sm\:p-7 {
    padding: 4rem !important;
  }

  .sm\:p-8 {
    padding: 5rem !important;
  }

  .sm\:pt-0 {
    padding-top: 0rem !important;
  }

  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .sm\:pt-3 {
    padding-top: 1rem !important;
  }

  .sm\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .sm\:pt-5 {
    padding-top: 2rem !important;
  }

  .sm\:pt-6 {
    padding-top: 3rem !important;
  }

  .sm\:pt-7 {
    padding-top: 4rem !important;
  }

  .sm\:pt-8 {
    padding-top: 5rem !important;
  }

  .sm\:pr-0 {
    padding-right: 0rem !important;
  }

  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .sm\:pr-3 {
    padding-right: 1rem !important;
  }

  .sm\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .sm\:pr-5 {
    padding-right: 2rem !important;
  }

  .sm\:pr-6 {
    padding-right: 3rem !important;
  }

  .sm\:pr-7 {
    padding-right: 4rem !important;
  }

  .sm\:pr-8 {
    padding-right: 5rem !important;
  }

  .sm\:pl-0 {
    padding-left: 0rem !important;
  }

  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .sm\:pl-3 {
    padding-left: 1rem !important;
  }

  .sm\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .sm\:pl-5 {
    padding-left: 2rem !important;
  }

  .sm\:pl-6 {
    padding-left: 3rem !important;
  }

  .sm\:pl-7 {
    padding-left: 4rem !important;
  }

  .sm\:pl-8 {
    padding-left: 5rem !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0rem !important;
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .sm\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .sm\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:p-0 {
    padding: 0rem !important;
  }

  .md\:p-1 {
    padding: 0.25rem !important;
  }

  .md\:p-2 {
    padding: 0.5rem !important;
  }

  .md\:p-3 {
    padding: 1rem !important;
  }

  .md\:p-4 {
    padding: 1.5rem !important;
  }

  .md\:p-5 {
    padding: 2rem !important;
  }

  .md\:p-6 {
    padding: 3rem !important;
  }

  .md\:p-7 {
    padding: 4rem !important;
  }

  .md\:p-8 {
    padding: 5rem !important;
  }

  .md\:pt-0 {
    padding-top: 0rem !important;
  }

  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .md\:pt-3 {
    padding-top: 1rem !important;
  }

  .md\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .md\:pt-5 {
    padding-top: 2rem !important;
  }

  .md\:pt-6 {
    padding-top: 3rem !important;
  }

  .md\:pt-7 {
    padding-top: 4rem !important;
  }

  .md\:pt-8 {
    padding-top: 5rem !important;
  }

  .md\:pr-0 {
    padding-right: 0rem !important;
  }

  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .md\:pr-3 {
    padding-right: 1rem !important;
  }

  .md\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .md\:pr-5 {
    padding-right: 2rem !important;
  }

  .md\:pr-6 {
    padding-right: 3rem !important;
  }

  .md\:pr-7 {
    padding-right: 4rem !important;
  }

  .md\:pr-8 {
    padding-right: 5rem !important;
  }

  .md\:pl-0 {
    padding-left: 0rem !important;
  }

  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .md\:pl-3 {
    padding-left: 1rem !important;
  }

  .md\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .md\:pl-5 {
    padding-left: 2rem !important;
  }

  .md\:pl-6 {
    padding-left: 3rem !important;
  }

  .md\:pl-7 {
    padding-left: 4rem !important;
  }

  .md\:pl-8 {
    padding-left: 5rem !important;
  }

  .md\:pb-0 {
    padding-bottom: 0rem !important;
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .md\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .md\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:p-0 {
    padding: 0rem !important;
  }

  .lg\:p-1 {
    padding: 0.25rem !important;
  }

  .lg\:p-2 {
    padding: 0.5rem !important;
  }

  .lg\:p-3 {
    padding: 1rem !important;
  }

  .lg\:p-4 {
    padding: 1.5rem !important;
  }

  .lg\:p-5 {
    padding: 2rem !important;
  }

  .lg\:p-6 {
    padding: 3rem !important;
  }

  .lg\:p-7 {
    padding: 4rem !important;
  }

  .lg\:p-8 {
    padding: 5rem !important;
  }

  .lg\:pt-0 {
    padding-top: 0rem !important;
  }

  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lg\:pt-3 {
    padding-top: 1rem !important;
  }

  .lg\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .lg\:pt-5 {
    padding-top: 2rem !important;
  }

  .lg\:pt-6 {
    padding-top: 3rem !important;
  }

  .lg\:pt-7 {
    padding-top: 4rem !important;
  }

  .lg\:pt-8 {
    padding-top: 5rem !important;
  }

  .lg\:pr-0 {
    padding-right: 0rem !important;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lg\:pr-3 {
    padding-right: 1rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .lg\:pr-5 {
    padding-right: 2rem !important;
  }

  .lg\:pr-6 {
    padding-right: 3rem !important;
  }

  .lg\:pr-7 {
    padding-right: 4rem !important;
  }

  .lg\:pr-8 {
    padding-right: 5rem !important;
  }

  .lg\:pl-0 {
    padding-left: 0rem !important;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lg\:pl-3 {
    padding-left: 1rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .lg\:pl-5 {
    padding-left: 2rem !important;
  }

  .lg\:pl-6 {
    padding-left: 3rem !important;
  }

  .lg\:pl-7 {
    padding-left: 4rem !important;
  }

  .lg\:pl-8 {
    padding-left: 5rem !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0rem !important;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .lg\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .lg\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:p-0 {
    padding: 0rem !important;
  }

  .xl\:p-1 {
    padding: 0.25rem !important;
  }

  .xl\:p-2 {
    padding: 0.5rem !important;
  }

  .xl\:p-3 {
    padding: 1rem !important;
  }

  .xl\:p-4 {
    padding: 1.5rem !important;
  }

  .xl\:p-5 {
    padding: 2rem !important;
  }

  .xl\:p-6 {
    padding: 3rem !important;
  }

  .xl\:p-7 {
    padding: 4rem !important;
  }

  .xl\:p-8 {
    padding: 5rem !important;
  }

  .xl\:pt-0 {
    padding-top: 0rem !important;
  }

  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xl\:pt-3 {
    padding-top: 1rem !important;
  }

  .xl\:pt-4 {
    padding-top: 1.5rem !important;
  }

  .xl\:pt-5 {
    padding-top: 2rem !important;
  }

  .xl\:pt-6 {
    padding-top: 3rem !important;
  }

  .xl\:pt-7 {
    padding-top: 4rem !important;
  }

  .xl\:pt-8 {
    padding-top: 5rem !important;
  }

  .xl\:pr-0 {
    padding-right: 0rem !important;
  }

  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xl\:pr-3 {
    padding-right: 1rem !important;
  }

  .xl\:pr-4 {
    padding-right: 1.5rem !important;
  }

  .xl\:pr-5 {
    padding-right: 2rem !important;
  }

  .xl\:pr-6 {
    padding-right: 3rem !important;
  }

  .xl\:pr-7 {
    padding-right: 4rem !important;
  }

  .xl\:pr-8 {
    padding-right: 5rem !important;
  }

  .xl\:pl-0 {
    padding-left: 0rem !important;
  }

  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xl\:pl-3 {
    padding-left: 1rem !important;
  }

  .xl\:pl-4 {
    padding-left: 1.5rem !important;
  }

  .xl\:pl-5 {
    padding-left: 2rem !important;
  }

  .xl\:pl-6 {
    padding-left: 3rem !important;
  }

  .xl\:pl-7 {
    padding-left: 4rem !important;
  }

  .xl\:pl-8 {
    padding-left: 5rem !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0rem !important;
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 1rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 2rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 3rem !important;
  }

  .xl\:pb-7 {
    padding-bottom: 4rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 5rem !important;
  }

  .xl\:px-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:px-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:px-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:px-5 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:px-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:px-7 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:px-8 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:py-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:py-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:py-7 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:py-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}
.m-0 {
  margin: 0rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.m-6 {
  margin: 3rem !important;
}

.m-7 {
  margin: 4rem !important;
}

.m-8 {
  margin: 5rem !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -1rem !important;
}

.-m-4 {
  margin: -1.5rem !important;
}

.-m-5 {
  margin: -2rem !important;
}

.-m-6 {
  margin: -3rem !important;
}

.-m-7 {
  margin: -4rem !important;
}

.-m-8 {
  margin: -5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 5rem !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mt-3 {
  margin-top: -1rem !important;
}

.-mt-4 {
  margin-top: -1.5rem !important;
}

.-mt-5 {
  margin-top: -2rem !important;
}

.-mt-6 {
  margin-top: -3rem !important;
}

.-mt-7 {
  margin-top: -4rem !important;
}

.-mt-8 {
  margin-top: -5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 2rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-7 {
  margin-right: 4rem !important;
}

.mr-8 {
  margin-right: 5rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mr-3 {
  margin-right: -1rem !important;
}

.-mr-4 {
  margin-right: -1.5rem !important;
}

.-mr-5 {
  margin-right: -2rem !important;
}

.-mr-6 {
  margin-right: -3rem !important;
}

.-mr-7 {
  margin-right: -4rem !important;
}

.-mr-8 {
  margin-right: -5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 4rem !important;
}

.ml-8 {
  margin-left: 5rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-ml-3 {
  margin-left: -1rem !important;
}

.-ml-4 {
  margin-left: -1.5rem !important;
}

.-ml-5 {
  margin-left: -2rem !important;
}

.-ml-6 {
  margin-left: -3rem !important;
}

.-ml-7 {
  margin-left: -4rem !important;
}

.-ml-8 {
  margin-left: -5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 2rem !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-7 {
  margin-bottom: 4rem !important;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-mb-3 {
  margin-bottom: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1.5rem !important;
}

.-mb-5 {
  margin-bottom: -2rem !important;
}

.-mb-6 {
  margin-bottom: -3rem !important;
}

.-mb-7 {
  margin-bottom: -4rem !important;
}

.-mb-8 {
  margin-bottom: -5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-7 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-8 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-mx-3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-mx-4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-mx-5 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-mx-6 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-mx-7 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-mx-8 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-my-3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-my-4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-my-5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-my-6 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-my-7 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-my-8 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0rem !important;
  }

  .sm\:m-1 {
    margin: 0.25rem !important;
  }

  .sm\:m-2 {
    margin: 0.5rem !important;
  }

  .sm\:m-3 {
    margin: 1rem !important;
  }

  .sm\:m-4 {
    margin: 1.5rem !important;
  }

  .sm\:m-5 {
    margin: 2rem !important;
  }

  .sm\:m-6 {
    margin: 3rem !important;
  }

  .sm\:m-7 {
    margin: 4rem !important;
  }

  .sm\:m-8 {
    margin: 5rem !important;
  }

  .sm\:-m-1 {
    margin: -0.25rem !important;
  }

  .sm\:-m-2 {
    margin: -0.5rem !important;
  }

  .sm\:-m-3 {
    margin: -1rem !important;
  }

  .sm\:-m-4 {
    margin: -1.5rem !important;
  }

  .sm\:-m-5 {
    margin: -2rem !important;
  }

  .sm\:-m-6 {
    margin: -3rem !important;
  }

  .sm\:-m-7 {
    margin: -4rem !important;
  }

  .sm\:-m-8 {
    margin: -5rem !important;
  }

  .sm\:m-auto {
    margin: auto !important;
  }

  .sm\:mt-0 {
    margin-top: 0rem !important;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .sm\:mt-3 {
    margin-top: 1rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .sm\:mt-5 {
    margin-top: 2rem !important;
  }

  .sm\:mt-6 {
    margin-top: 3rem !important;
  }

  .sm\:mt-7 {
    margin-top: 4rem !important;
  }

  .sm\:mt-8 {
    margin-top: 5rem !important;
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -1rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -2rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -3rem !important;
  }

  .sm\:-mt-7 {
    margin-top: -4rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -5rem !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mr-0 {
    margin-right: 0rem !important;
  }

  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .sm\:mr-3 {
    margin-right: 1rem !important;
  }

  .sm\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .sm\:mr-5 {
    margin-right: 2rem !important;
  }

  .sm\:mr-6 {
    margin-right: 3rem !important;
  }

  .sm\:mr-7 {
    margin-right: 4rem !important;
  }

  .sm\:mr-8 {
    margin-right: 5rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -1rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -2rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -3rem !important;
  }

  .sm\:-mr-7 {
    margin-right: -4rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -5rem !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:ml-0 {
    margin-left: 0rem !important;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm\:ml-3 {
    margin-left: 1rem !important;
  }

  .sm\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .sm\:ml-5 {
    margin-left: 2rem !important;
  }

  .sm\:ml-6 {
    margin-left: 3rem !important;
  }

  .sm\:ml-7 {
    margin-left: 4rem !important;
  }

  .sm\:ml-8 {
    margin-left: 5rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -1rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -2rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -3rem !important;
  }

  .sm\:-ml-7 {
    margin-left: -4rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -5rem !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0rem !important;
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .sm\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .sm\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .sm\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:m-0 {
    margin: 0rem !important;
  }

  .md\:m-1 {
    margin: 0.25rem !important;
  }

  .md\:m-2 {
    margin: 0.5rem !important;
  }

  .md\:m-3 {
    margin: 1rem !important;
  }

  .md\:m-4 {
    margin: 1.5rem !important;
  }

  .md\:m-5 {
    margin: 2rem !important;
  }

  .md\:m-6 {
    margin: 3rem !important;
  }

  .md\:m-7 {
    margin: 4rem !important;
  }

  .md\:m-8 {
    margin: 5rem !important;
  }

  .md\:-m-1 {
    margin: -0.25rem !important;
  }

  .md\:-m-2 {
    margin: -0.5rem !important;
  }

  .md\:-m-3 {
    margin: -1rem !important;
  }

  .md\:-m-4 {
    margin: -1.5rem !important;
  }

  .md\:-m-5 {
    margin: -2rem !important;
  }

  .md\:-m-6 {
    margin: -3rem !important;
  }

  .md\:-m-7 {
    margin: -4rem !important;
  }

  .md\:-m-8 {
    margin: -5rem !important;
  }

  .md\:m-auto {
    margin: auto !important;
  }

  .md\:mt-0 {
    margin-top: 0rem !important;
  }

  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .md\:mt-3 {
    margin-top: 1rem !important;
  }

  .md\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .md\:mt-5 {
    margin-top: 2rem !important;
  }

  .md\:mt-6 {
    margin-top: 3rem !important;
  }

  .md\:mt-7 {
    margin-top: 4rem !important;
  }

  .md\:mt-8 {
    margin-top: 5rem !important;
  }

  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .md\:-mt-3 {
    margin-top: -1rem !important;
  }

  .md\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .md\:-mt-5 {
    margin-top: -2rem !important;
  }

  .md\:-mt-6 {
    margin-top: -3rem !important;
  }

  .md\:-mt-7 {
    margin-top: -4rem !important;
  }

  .md\:-mt-8 {
    margin-top: -5rem !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mr-0 {
    margin-right: 0rem !important;
  }

  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .md\:mr-3 {
    margin-right: 1rem !important;
  }

  .md\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .md\:mr-5 {
    margin-right: 2rem !important;
  }

  .md\:mr-6 {
    margin-right: 3rem !important;
  }

  .md\:mr-7 {
    margin-right: 4rem !important;
  }

  .md\:mr-8 {
    margin-right: 5rem !important;
  }

  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .md\:-mr-3 {
    margin-right: -1rem !important;
  }

  .md\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .md\:-mr-5 {
    margin-right: -2rem !important;
  }

  .md\:-mr-6 {
    margin-right: -3rem !important;
  }

  .md\:-mr-7 {
    margin-right: -4rem !important;
  }

  .md\:-mr-8 {
    margin-right: -5rem !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:ml-0 {
    margin-left: 0rem !important;
  }

  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .md\:ml-3 {
    margin-left: 1rem !important;
  }

  .md\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .md\:ml-5 {
    margin-left: 2rem !important;
  }

  .md\:ml-6 {
    margin-left: 3rem !important;
  }

  .md\:ml-7 {
    margin-left: 4rem !important;
  }

  .md\:ml-8 {
    margin-left: 5rem !important;
  }

  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .md\:-ml-3 {
    margin-left: -1rem !important;
  }

  .md\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .md\:-ml-5 {
    margin-left: -2rem !important;
  }

  .md\:-ml-6 {
    margin-left: -3rem !important;
  }

  .md\:-ml-7 {
    margin-left: -4rem !important;
  }

  .md\:-ml-8 {
    margin-left: -5rem !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mb-0 {
    margin-bottom: 0rem !important;
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .md\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .md\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .md\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:m-0 {
    margin: 0rem !important;
  }

  .lg\:m-1 {
    margin: 0.25rem !important;
  }

  .lg\:m-2 {
    margin: 0.5rem !important;
  }

  .lg\:m-3 {
    margin: 1rem !important;
  }

  .lg\:m-4 {
    margin: 1.5rem !important;
  }

  .lg\:m-5 {
    margin: 2rem !important;
  }

  .lg\:m-6 {
    margin: 3rem !important;
  }

  .lg\:m-7 {
    margin: 4rem !important;
  }

  .lg\:m-8 {
    margin: 5rem !important;
  }

  .lg\:-m-1 {
    margin: -0.25rem !important;
  }

  .lg\:-m-2 {
    margin: -0.5rem !important;
  }

  .lg\:-m-3 {
    margin: -1rem !important;
  }

  .lg\:-m-4 {
    margin: -1.5rem !important;
  }

  .lg\:-m-5 {
    margin: -2rem !important;
  }

  .lg\:-m-6 {
    margin: -3rem !important;
  }

  .lg\:-m-7 {
    margin: -4rem !important;
  }

  .lg\:-m-8 {
    margin: -5rem !important;
  }

  .lg\:m-auto {
    margin: auto !important;
  }

  .lg\:mt-0 {
    margin-top: 0rem !important;
  }

  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lg\:mt-3 {
    margin-top: 1rem !important;
  }

  .lg\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .lg\:mt-5 {
    margin-top: 2rem !important;
  }

  .lg\:mt-6 {
    margin-top: 3rem !important;
  }

  .lg\:mt-7 {
    margin-top: 4rem !important;
  }

  .lg\:mt-8 {
    margin-top: 5rem !important;
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -1rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -2rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -3rem !important;
  }

  .lg\:-mt-7 {
    margin-top: -4rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -5rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mr-0 {
    margin-right: 0rem !important;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lg\:mr-3 {
    margin-right: 1rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .lg\:mr-5 {
    margin-right: 2rem !important;
  }

  .lg\:mr-6 {
    margin-right: 3rem !important;
  }

  .lg\:mr-7 {
    margin-right: 4rem !important;
  }

  .lg\:mr-8 {
    margin-right: 5rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -1rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -2rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -3rem !important;
  }

  .lg\:-mr-7 {
    margin-right: -4rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -5rem !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:ml-0 {
    margin-left: 0rem !important;
  }

  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lg\:ml-3 {
    margin-left: 1rem !important;
  }

  .lg\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .lg\:ml-5 {
    margin-left: 2rem !important;
  }

  .lg\:ml-6 {
    margin-left: 3rem !important;
  }

  .lg\:ml-7 {
    margin-left: 4rem !important;
  }

  .lg\:ml-8 {
    margin-left: 5rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -1rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -2rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -3rem !important;
  }

  .lg\:-ml-7 {
    margin-left: -4rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -5rem !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0rem !important;
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .lg\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .lg\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lg\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0rem !important;
  }

  .xl\:m-1 {
    margin: 0.25rem !important;
  }

  .xl\:m-2 {
    margin: 0.5rem !important;
  }

  .xl\:m-3 {
    margin: 1rem !important;
  }

  .xl\:m-4 {
    margin: 1.5rem !important;
  }

  .xl\:m-5 {
    margin: 2rem !important;
  }

  .xl\:m-6 {
    margin: 3rem !important;
  }

  .xl\:m-7 {
    margin: 4rem !important;
  }

  .xl\:m-8 {
    margin: 5rem !important;
  }

  .xl\:-m-1 {
    margin: -0.25rem !important;
  }

  .xl\:-m-2 {
    margin: -0.5rem !important;
  }

  .xl\:-m-3 {
    margin: -1rem !important;
  }

  .xl\:-m-4 {
    margin: -1.5rem !important;
  }

  .xl\:-m-5 {
    margin: -2rem !important;
  }

  .xl\:-m-6 {
    margin: -3rem !important;
  }

  .xl\:-m-7 {
    margin: -4rem !important;
  }

  .xl\:-m-8 {
    margin: -5rem !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }

  .xl\:mt-0 {
    margin-top: 0rem !important;
  }

  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xl\:mt-3 {
    margin-top: 1rem !important;
  }

  .xl\:mt-4 {
    margin-top: 1.5rem !important;
  }

  .xl\:mt-5 {
    margin-top: 2rem !important;
  }

  .xl\:mt-6 {
    margin-top: 3rem !important;
  }

  .xl\:mt-7 {
    margin-top: 4rem !important;
  }

  .xl\:mt-8 {
    margin-top: 5rem !important;
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -1rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -1.5rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -2rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -3rem !important;
  }

  .xl\:-mt-7 {
    margin-top: -4rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -5rem !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mr-0 {
    margin-right: 0rem !important;
  }

  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xl\:mr-3 {
    margin-right: 1rem !important;
  }

  .xl\:mr-4 {
    margin-right: 1.5rem !important;
  }

  .xl\:mr-5 {
    margin-right: 2rem !important;
  }

  .xl\:mr-6 {
    margin-right: 3rem !important;
  }

  .xl\:mr-7 {
    margin-right: 4rem !important;
  }

  .xl\:mr-8 {
    margin-right: 5rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -1rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -1.5rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -2rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -3rem !important;
  }

  .xl\:-mr-7 {
    margin-right: -4rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -5rem !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:ml-0 {
    margin-left: 0rem !important;
  }

  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xl\:ml-3 {
    margin-left: 1rem !important;
  }

  .xl\:ml-4 {
    margin-left: 1.5rem !important;
  }

  .xl\:ml-5 {
    margin-left: 2rem !important;
  }

  .xl\:ml-6 {
    margin-left: 3rem !important;
  }

  .xl\:ml-7 {
    margin-left: 4rem !important;
  }

  .xl\:ml-8 {
    margin-left: 5rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -1rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -1.5rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -2rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -3rem !important;
  }

  .xl\:-ml-7 {
    margin-left: -4rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -5rem !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0rem !important;
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 1rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 2rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 3rem !important;
  }

  .xl\:mb-7 {
    margin-bottom: 4rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 5rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -1rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -2rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -3rem !important;
  }

  .xl\:-mb-7 {
    margin-bottom: -4rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -5rem !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:mx-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }

  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:mx-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:mx-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:mx-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:mx-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:mx-7 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:mx-8 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-mx-7 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:my-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:my-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:my-7 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:my-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xl\:-my-3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-my-4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-my-5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-my-6 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-my-7 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-my-8 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.shadow-2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-3 {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-5 {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.shadow-7 {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.shadow-8 {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }
  .sm\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }
  .md\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }
  .lg\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }
  .xl\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
.border-none {
  border-width: 0px !important;
  border-style: none;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-top-none {
  border-top-width: 0px !important;
  border-top-style: none;
}

.border-top-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-top-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-top-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-right-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-right-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
}

.border-left-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-left-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-x-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-y-none {
  border-top-width: 0px !important;
  border-top-style: none;
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .sm\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .sm\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .sm\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .sm\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .sm\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .sm\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .sm\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .sm\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .sm\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .sm\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .sm\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .sm\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .sm\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .md\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .md\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .md\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .md\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .md\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .md\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .md\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .md\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .md\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .md\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .md\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .lg\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .lg\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .lg\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .lg\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .lg\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .lg\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .lg\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .lg\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .lg\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .lg\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .lg\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .lg\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .lg\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }
  .sm\:border-dashed {
    border-style: dashed !important;
  }
  .sm\:border-dotted {
    border-style: dotted !important;
  }
  .sm\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }
  .md\:border-dashed {
    border-style: dashed !important;
  }
  .md\:border-dotted {
    border-style: dotted !important;
  }
  .md\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }
  .lg\:border-dashed {
    border-style: dashed !important;
  }
  .lg\:border-dotted {
    border-style: dotted !important;
  }
  .lg\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }
  .xl\:border-dashed {
    border-style: dashed !important;
  }
  .xl\:border-dotted {
    border-style: dotted !important;
  }
  .xl\:border-double {
    border-style: double !important;
  }
}
.border-round-left {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.border-round-top {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.border-round-right {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

@media screen and (min-width: 576px) {
  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
@media screen and (min-width: 768px) {
  .md\:border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .md\:border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .md\:border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }
  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }
  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .sm\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }
  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .md\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }
  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .lg\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }
  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xl\:border-circle {
    border-radius: 50% !important;
  }
}
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }
  .sm\:w-screen {
    width: 100vw !important;
  }
  .sm\:w-auto {
    width: auto !important;
  }
  .sm\:w-min {
    width: min-content !important;
  }
  .sm\:w-max {
    width: max-content !important;
  }
  .sm\:w-1 {
    width: 8.3333% !important;
  }
  .sm\:w-2 {
    width: 16.6667% !important;
  }
  .sm\:w-3 {
    width: 25% !important;
  }
  .sm\:w-4 {
    width: 33.3333% !important;
  }
  .sm\:w-5 {
    width: 41.6667% !important;
  }
  .sm\:w-6 {
    width: 50% !important;
  }
  .sm\:w-7 {
    width: 58.3333% !important;
  }
  .sm\:w-8 {
    width: 66.6667% !important;
  }
  .sm\:w-9 {
    width: 75% !important;
  }
  .sm\:w-10 {
    width: 83.3333% !important;
  }
  .sm\:w-11 {
    width: 91.6667% !important;
  }
  .sm\:w-12 {
    width: 100% !important;
  }
  .sm\:w-1rem {
    width: 1rem !important;
  }
  .sm\:w-2rem {
    width: 2rem !important;
  }
  .sm\:w-3rem {
    width: 3rem !important;
  }
  .sm\:w-4rem {
    width: 4rem !important;
  }
  .sm\:w-5rem {
    width: 5rem !important;
  }
  .sm\:w-6rem {
    width: 6rem !important;
  }
  .sm\:w-7rem {
    width: 7rem !important;
  }
  .sm\:w-8rem {
    width: 8rem !important;
  }
  .sm\:w-9rem {
    width: 9rem !important;
  }
  .sm\:w-10rem {
    width: 10rem !important;
  }
  .sm\:w-11rem {
    width: 11rem !important;
  }
  .sm\:w-12rem {
    width: 12rem !important;
  }
  .sm\:w-13rem {
    width: 13rem !important;
  }
  .sm\:w-14rem {
    width: 14rem !important;
  }
  .sm\:w-15rem {
    width: 15rem !important;
  }
  .sm\:w-16rem {
    width: 16rem !important;
  }
  .sm\:w-17rem {
    width: 17rem !important;
  }
  .sm\:w-18rem {
    width: 18rem !important;
  }
  .sm\:w-19rem {
    width: 19rem !important;
  }
  .sm\:w-20rem {
    width: 20rem !important;
  }
  .sm\:w-21rem {
    width: 21rem !important;
  }
  .sm\:w-22rem {
    width: 22rem !important;
  }
  .sm\:w-23rem {
    width: 23rem !important;
  }
  .sm\:w-24rem {
    width: 24rem !important;
  }
  .sm\:w-25rem {
    width: 25rem !important;
  }
  .sm\:w-26rem {
    width: 26rem !important;
  }
  .sm\:w-27rem {
    width: 27rem !important;
  }
  .sm\:w-28rem {
    width: 28rem !important;
  }
  .sm\:w-29rem {
    width: 29rem !important;
  }
  .sm\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-screen {
    width: 100vw !important;
  }
  .md\:w-auto {
    width: auto !important;
  }
  .md\:w-min {
    width: min-content !important;
  }
  .md\:w-max {
    width: max-content !important;
  }
  .md\:w-1 {
    width: 8.3333% !important;
  }
  .md\:w-2 {
    width: 16.6667% !important;
  }
  .md\:w-3 {
    width: 25% !important;
  }
  .md\:w-4 {
    width: 33.3333% !important;
  }
  .md\:w-5 {
    width: 41.6667% !important;
  }
  .md\:w-6 {
    width: 50% !important;
  }
  .md\:w-7 {
    width: 58.3333% !important;
  }
  .md\:w-8 {
    width: 66.6667% !important;
  }
  .md\:w-9 {
    width: 75% !important;
  }
  .md\:w-10 {
    width: 83.3333% !important;
  }
  .md\:w-11 {
    width: 91.6667% !important;
  }
  .md\:w-12 {
    width: 100% !important;
  }
  .md\:w-1rem {
    width: 1rem !important;
  }
  .md\:w-2rem {
    width: 2rem !important;
  }
  .md\:w-3rem {
    width: 3rem !important;
  }
  .md\:w-4rem {
    width: 4rem !important;
  }
  .md\:w-5rem {
    width: 5rem !important;
  }
  .md\:w-6rem {
    width: 6rem !important;
  }
  .md\:w-7rem {
    width: 7rem !important;
  }
  .md\:w-8rem {
    width: 8rem !important;
  }
  .md\:w-9rem {
    width: 9rem !important;
  }
  .md\:w-10rem {
    width: 10rem !important;
  }
  .md\:w-11rem {
    width: 11rem !important;
  }
  .md\:w-12rem {
    width: 12rem !important;
  }
  .md\:w-13rem {
    width: 13rem !important;
  }
  .md\:w-14rem {
    width: 14rem !important;
  }
  .md\:w-15rem {
    width: 15rem !important;
  }
  .md\:w-16rem {
    width: 16rem !important;
  }
  .md\:w-17rem {
    width: 17rem !important;
  }
  .md\:w-18rem {
    width: 18rem !important;
  }
  .md\:w-19rem {
    width: 19rem !important;
  }
  .md\:w-20rem {
    width: 20rem !important;
  }
  .md\:w-21rem {
    width: 21rem !important;
  }
  .md\:w-22rem {
    width: 22rem !important;
  }
  .md\:w-23rem {
    width: 23rem !important;
  }
  .md\:w-24rem {
    width: 24rem !important;
  }
  .md\:w-25rem {
    width: 25rem !important;
  }
  .md\:w-26rem {
    width: 26rem !important;
  }
  .md\:w-27rem {
    width: 27rem !important;
  }
  .md\:w-28rem {
    width: 28rem !important;
  }
  .md\:w-29rem {
    width: 29rem !important;
  }
  .md\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:w-screen {
    width: 100vw !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-min {
    width: min-content !important;
  }
  .lg\:w-max {
    width: max-content !important;
  }
  .lg\:w-1 {
    width: 8.3333% !important;
  }
  .lg\:w-2 {
    width: 16.6667% !important;
  }
  .lg\:w-3 {
    width: 25% !important;
  }
  .lg\:w-4 {
    width: 33.3333% !important;
  }
  .lg\:w-5 {
    width: 41.6667% !important;
  }
  .lg\:w-6 {
    width: 50% !important;
  }
  .lg\:w-7 {
    width: 58.3333% !important;
  }
  .lg\:w-8 {
    width: 66.6667% !important;
  }
  .lg\:w-9 {
    width: 75% !important;
  }
  .lg\:w-10 {
    width: 83.3333% !important;
  }
  .lg\:w-11 {
    width: 91.6667% !important;
  }
  .lg\:w-12 {
    width: 100% !important;
  }
  .lg\:w-1rem {
    width: 1rem !important;
  }
  .lg\:w-2rem {
    width: 2rem !important;
  }
  .lg\:w-3rem {
    width: 3rem !important;
  }
  .lg\:w-4rem {
    width: 4rem !important;
  }
  .lg\:w-5rem {
    width: 5rem !important;
  }
  .lg\:w-6rem {
    width: 6rem !important;
  }
  .lg\:w-7rem {
    width: 7rem !important;
  }
  .lg\:w-8rem {
    width: 8rem !important;
  }
  .lg\:w-9rem {
    width: 9rem !important;
  }
  .lg\:w-10rem {
    width: 10rem !important;
  }
  .lg\:w-11rem {
    width: 11rem !important;
  }
  .lg\:w-12rem {
    width: 12rem !important;
  }
  .lg\:w-13rem {
    width: 13rem !important;
  }
  .lg\:w-14rem {
    width: 14rem !important;
  }
  .lg\:w-15rem {
    width: 15rem !important;
  }
  .lg\:w-16rem {
    width: 16rem !important;
  }
  .lg\:w-17rem {
    width: 17rem !important;
  }
  .lg\:w-18rem {
    width: 18rem !important;
  }
  .lg\:w-19rem {
    width: 19rem !important;
  }
  .lg\:w-20rem {
    width: 20rem !important;
  }
  .lg\:w-21rem {
    width: 21rem !important;
  }
  .lg\:w-22rem {
    width: 22rem !important;
  }
  .lg\:w-23rem {
    width: 23rem !important;
  }
  .lg\:w-24rem {
    width: 24rem !important;
  }
  .lg\:w-25rem {
    width: 25rem !important;
  }
  .lg\:w-26rem {
    width: 26rem !important;
  }
  .lg\:w-27rem {
    width: 27rem !important;
  }
  .lg\:w-28rem {
    width: 28rem !important;
  }
  .lg\:w-29rem {
    width: 29rem !important;
  }
  .lg\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }
  .xl\:w-screen {
    width: 100vw !important;
  }
  .xl\:w-auto {
    width: auto !important;
  }
  .xl\:w-min {
    width: min-content !important;
  }
  .xl\:w-max {
    width: max-content !important;
  }
  .xl\:w-1 {
    width: 8.3333% !important;
  }
  .xl\:w-2 {
    width: 16.6667% !important;
  }
  .xl\:w-3 {
    width: 25% !important;
  }
  .xl\:w-4 {
    width: 33.3333% !important;
  }
  .xl\:w-5 {
    width: 41.6667% !important;
  }
  .xl\:w-6 {
    width: 50% !important;
  }
  .xl\:w-7 {
    width: 58.3333% !important;
  }
  .xl\:w-8 {
    width: 66.6667% !important;
  }
  .xl\:w-9 {
    width: 75% !important;
  }
  .xl\:w-10 {
    width: 83.3333% !important;
  }
  .xl\:w-11 {
    width: 91.6667% !important;
  }
  .xl\:w-12 {
    width: 100% !important;
  }
  .xl\:w-1rem {
    width: 1rem !important;
  }
  .xl\:w-2rem {
    width: 2rem !important;
  }
  .xl\:w-3rem {
    width: 3rem !important;
  }
  .xl\:w-4rem {
    width: 4rem !important;
  }
  .xl\:w-5rem {
    width: 5rem !important;
  }
  .xl\:w-6rem {
    width: 6rem !important;
  }
  .xl\:w-7rem {
    width: 7rem !important;
  }
  .xl\:w-8rem {
    width: 8rem !important;
  }
  .xl\:w-9rem {
    width: 9rem !important;
  }
  .xl\:w-10rem {
    width: 10rem !important;
  }
  .xl\:w-11rem {
    width: 11rem !important;
  }
  .xl\:w-12rem {
    width: 12rem !important;
  }
  .xl\:w-13rem {
    width: 13rem !important;
  }
  .xl\:w-14rem {
    width: 14rem !important;
  }
  .xl\:w-15rem {
    width: 15rem !important;
  }
  .xl\:w-16rem {
    width: 16rem !important;
  }
  .xl\:w-17rem {
    width: 17rem !important;
  }
  .xl\:w-18rem {
    width: 18rem !important;
  }
  .xl\:w-19rem {
    width: 19rem !important;
  }
  .xl\:w-20rem {
    width: 20rem !important;
  }
  .xl\:w-21rem {
    width: 21rem !important;
  }
  .xl\:w-22rem {
    width: 22rem !important;
  }
  .xl\:w-23rem {
    width: 23rem !important;
  }
  .xl\:w-24rem {
    width: 24rem !important;
  }
  .xl\:w-25rem {
    width: 25rem !important;
  }
  .xl\:w-26rem {
    width: 26rem !important;
  }
  .xl\:w-27rem {
    width: 27rem !important;
  }
  .xl\:w-28rem {
    width: 28rem !important;
  }
  .xl\:w-29rem {
    width: 29rem !important;
  }
  .xl\:w-30rem {
    width: 30rem !important;
  }
}
.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }
  .sm\:h-screen {
    height: 100vh !important;
  }
  .sm\:h-auto {
    height: auto !important;
  }
  .sm\:h-1rem {
    height: 1rem !important;
  }
  .sm\:h-2rem {
    height: 2rem !important;
  }
  .sm\:h-3rem {
    height: 3rem !important;
  }
  .sm\:h-4rem {
    height: 4rem !important;
  }
  .sm\:h-5rem {
    height: 5rem !important;
  }
  .sm\:h-6rem {
    height: 6rem !important;
  }
  .sm\:h-7rem {
    height: 7rem !important;
  }
  .sm\:h-8rem {
    height: 8rem !important;
  }
  .sm\:h-9rem {
    height: 9rem !important;
  }
  .sm\:h-10rem {
    height: 10rem !important;
  }
  .sm\:h-11rem {
    height: 11rem !important;
  }
  .sm\:h-12rem {
    height: 12rem !important;
  }
  .sm\:h-13rem {
    height: 13rem !important;
  }
  .sm\:h-14rem {
    height: 14rem !important;
  }
  .sm\:h-15rem {
    height: 15rem !important;
  }
  .sm\:h-16rem {
    height: 16rem !important;
  }
  .sm\:h-17rem {
    height: 17rem !important;
  }
  .sm\:h-18rem {
    height: 18rem !important;
  }
  .sm\:h-19rem {
    height: 19rem !important;
  }
  .sm\:h-20rem {
    height: 20rem !important;
  }
  .sm\:h-21rem {
    height: 21rem !important;
  }
  .sm\:h-22rem {
    height: 22rem !important;
  }
  .sm\:h-23rem {
    height: 23rem !important;
  }
  .sm\:h-24rem {
    height: 24rem !important;
  }
  .sm\:h-25rem {
    height: 25rem !important;
  }
  .sm\:h-26rem {
    height: 26rem !important;
  }
  .sm\:h-27rem {
    height: 27rem !important;
  }
  .sm\:h-28rem {
    height: 28rem !important;
  }
  .sm\:h-29rem {
    height: 29rem !important;
  }
  .sm\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:h-full {
    height: 100% !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:h-1rem {
    height: 1rem !important;
  }
  .md\:h-2rem {
    height: 2rem !important;
  }
  .md\:h-3rem {
    height: 3rem !important;
  }
  .md\:h-4rem {
    height: 4rem !important;
  }
  .md\:h-5rem {
    height: 5rem !important;
  }
  .md\:h-6rem {
    height: 6rem !important;
  }
  .md\:h-7rem {
    height: 7rem !important;
  }
  .md\:h-8rem {
    height: 8rem !important;
  }
  .md\:h-9rem {
    height: 9rem !important;
  }
  .md\:h-10rem {
    height: 10rem !important;
  }
  .md\:h-11rem {
    height: 11rem !important;
  }
  .md\:h-12rem {
    height: 12rem !important;
  }
  .md\:h-13rem {
    height: 13rem !important;
  }
  .md\:h-14rem {
    height: 14rem !important;
  }
  .md\:h-15rem {
    height: 15rem !important;
  }
  .md\:h-16rem {
    height: 16rem !important;
  }
  .md\:h-17rem {
    height: 17rem !important;
  }
  .md\:h-18rem {
    height: 18rem !important;
  }
  .md\:h-19rem {
    height: 19rem !important;
  }
  .md\:h-20rem {
    height: 20rem !important;
  }
  .md\:h-21rem {
    height: 21rem !important;
  }
  .md\:h-22rem {
    height: 22rem !important;
  }
  .md\:h-23rem {
    height: 23rem !important;
  }
  .md\:h-24rem {
    height: 24rem !important;
  }
  .md\:h-25rem {
    height: 25rem !important;
  }
  .md\:h-26rem {
    height: 26rem !important;
  }
  .md\:h-27rem {
    height: 27rem !important;
  }
  .md\:h-28rem {
    height: 28rem !important;
  }
  .md\:h-29rem {
    height: 29rem !important;
  }
  .md\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:h-screen {
    height: 100vh !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-1rem {
    height: 1rem !important;
  }
  .lg\:h-2rem {
    height: 2rem !important;
  }
  .lg\:h-3rem {
    height: 3rem !important;
  }
  .lg\:h-4rem {
    height: 4rem !important;
  }
  .lg\:h-5rem {
    height: 5rem !important;
  }
  .lg\:h-6rem {
    height: 6rem !important;
  }
  .lg\:h-7rem {
    height: 7rem !important;
  }
  .lg\:h-8rem {
    height: 8rem !important;
  }
  .lg\:h-9rem {
    height: 9rem !important;
  }
  .lg\:h-10rem {
    height: 10rem !important;
  }
  .lg\:h-11rem {
    height: 11rem !important;
  }
  .lg\:h-12rem {
    height: 12rem !important;
  }
  .lg\:h-13rem {
    height: 13rem !important;
  }
  .lg\:h-14rem {
    height: 14rem !important;
  }
  .lg\:h-15rem {
    height: 15rem !important;
  }
  .lg\:h-16rem {
    height: 16rem !important;
  }
  .lg\:h-17rem {
    height: 17rem !important;
  }
  .lg\:h-18rem {
    height: 18rem !important;
  }
  .lg\:h-19rem {
    height: 19rem !important;
  }
  .lg\:h-20rem {
    height: 20rem !important;
  }
  .lg\:h-21rem {
    height: 21rem !important;
  }
  .lg\:h-22rem {
    height: 22rem !important;
  }
  .lg\:h-23rem {
    height: 23rem !important;
  }
  .lg\:h-24rem {
    height: 24rem !important;
  }
  .lg\:h-25rem {
    height: 25rem !important;
  }
  .lg\:h-26rem {
    height: 26rem !important;
  }
  .lg\:h-27rem {
    height: 27rem !important;
  }
  .lg\:h-28rem {
    height: 28rem !important;
  }
  .lg\:h-29rem {
    height: 29rem !important;
  }
  .lg\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }
  .xl\:h-screen {
    height: 100vh !important;
  }
  .xl\:h-auto {
    height: auto !important;
  }
  .xl\:h-1rem {
    height: 1rem !important;
  }
  .xl\:h-2rem {
    height: 2rem !important;
  }
  .xl\:h-3rem {
    height: 3rem !important;
  }
  .xl\:h-4rem {
    height: 4rem !important;
  }
  .xl\:h-5rem {
    height: 5rem !important;
  }
  .xl\:h-6rem {
    height: 6rem !important;
  }
  .xl\:h-7rem {
    height: 7rem !important;
  }
  .xl\:h-8rem {
    height: 8rem !important;
  }
  .xl\:h-9rem {
    height: 9rem !important;
  }
  .xl\:h-10rem {
    height: 10rem !important;
  }
  .xl\:h-11rem {
    height: 11rem !important;
  }
  .xl\:h-12rem {
    height: 12rem !important;
  }
  .xl\:h-13rem {
    height: 13rem !important;
  }
  .xl\:h-14rem {
    height: 14rem !important;
  }
  .xl\:h-15rem {
    height: 15rem !important;
  }
  .xl\:h-16rem {
    height: 16rem !important;
  }
  .xl\:h-17rem {
    height: 17rem !important;
  }
  .xl\:h-18rem {
    height: 18rem !important;
  }
  .xl\:h-19rem {
    height: 19rem !important;
  }
  .xl\:h-20rem {
    height: 20rem !important;
  }
  .xl\:h-21rem {
    height: 21rem !important;
  }
  .xl\:h-22rem {
    height: 22rem !important;
  }
  .xl\:h-23rem {
    height: 23rem !important;
  }
  .xl\:h-24rem {
    height: 24rem !important;
  }
  .xl\:h-25rem {
    height: 25rem !important;
  }
  .xl\:h-26rem {
    height: 26rem !important;
  }
  .xl\:h-27rem {
    height: 27rem !important;
  }
  .xl\:h-28rem {
    height: 28rem !important;
  }
  .xl\:h-29rem {
    height: 29rem !important;
  }
  .xl\:h-30rem {
    height: 30rem !important;
  }
}
.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0px !important;
  }
  .sm\:min-w-full {
    min-width: 100% !important;
  }
  .sm\:min-w-screen {
    min-width: 100vw !important;
  }
  .sm\:min-w-min {
    min-width: min-content !important;
  }
  .sm\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0px !important;
  }
  .md\:min-w-full {
    min-width: 100% !important;
  }
  .md\:min-w-screen {
    min-width: 100vw !important;
  }
  .md\:min-w-min {
    min-width: min-content !important;
  }
  .md\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-w-0 {
    min-width: 0px !important;
  }
  .lg\:min-w-full {
    min-width: 100% !important;
  }
  .lg\:min-w-screen {
    min-width: 100vw !important;
  }
  .lg\:min-w-min {
    min-width: min-content !important;
  }
  .lg\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-w-0 {
    min-width: 0px !important;
  }
  .xl\:min-w-full {
    min-width: 100% !important;
  }
  .xl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xl\:min-w-min {
    min-width: min-content !important;
  }
  .xl\:min-w-max {
    min-width: max-content !important;
  }
}
.max-w-0 {
  max-width: 0px !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0px !important;
  }
  .sm\:max-w-full {
    max-width: 100% !important;
  }
  .sm\:max-w-screen {
    max-width: 100vw !important;
  }
  .sm\:max-w-min {
    max-width: min-content !important;
  }
  .sm\:max-w-max {
    max-width: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-w-0 {
    max-width: 0px !important;
  }
  .md\:max-w-full {
    max-width: 100% !important;
  }
  .md\:max-w-screen {
    max-width: 100vw !important;
  }
  .md\:max-w-min {
    max-width: min-content !important;
  }
  .md\:max-w-max {
    max-width: max-content !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-w-0 {
    max-width: 0px !important;
  }
  .lg\:max-w-full {
    max-width: 100% !important;
  }
  .lg\:max-w-screen {
    max-width: 100vw !important;
  }
  .lg\:max-w-min {
    max-width: min-content !important;
  }
  .lg\:max-w-max {
    max-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-w-0 {
    max-width: 0px !important;
  }
  .xl\:max-w-full {
    max-width: 100% !important;
  }
  .xl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xl\:max-w-min {
    max-width: min-content !important;
  }
  .xl\:max-w-max {
    max-width: max-content !important;
  }
}
.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0px !important;
  }
  .sm\:min-h-full {
    min-height: 100% !important;
  }
  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-h-0 {
    min-height: 0px !important;
  }
  .md\:min-h-full {
    min-height: 100% !important;
  }
  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-h-0 {
    min-height: 0px !important;
  }
  .lg\:min-h-full {
    min-height: 100% !important;
  }
  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-h-0 {
    min-height: 0px !important;
  }
  .xl\:min-h-full {
    min-height: 100% !important;
  }
  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}
.max-h-0 {
  max-height: 0px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0px !important;
  }
  .sm\:max-h-full {
    max-height: 100% !important;
  }
  .sm\:max-h-screen {
    max-height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-h-0 {
    max-height: 0px !important;
  }
  .md\:max-h-full {
    max-height: 100% !important;
  }
  .md\:max-h-screen {
    max-height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-h-0 {
    max-height: 0px !important;
  }
  .lg\:max-h-full {
    max-height: 100% !important;
  }
  .lg\:max-h-screen {
    max-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-h-0 {
    max-height: 0px !important;
  }
  .xl\:max-h-full {
    max-height: 100% !important;
  }
  .xl\:max-h-screen {
    max-height: 100vh !important;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }
  .sm\:fixed {
    position: fixed !important;
  }
  .sm\:absolute {
    position: absolute !important;
  }
  .sm\:relative {
    position: relative !important;
  }
  .sm\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:static {
    position: static !important;
  }
  .md\:fixed {
    position: fixed !important;
  }
  .md\:absolute {
    position: absolute !important;
  }
  .md\:relative {
    position: relative !important;
  }
  .md\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:static {
    position: static !important;
  }
  .lg\:fixed {
    position: fixed !important;
  }
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:relative {
    position: relative !important;
  }
  .lg\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:static {
    position: static !important;
  }
  .xl\:fixed {
    position: fixed !important;
  }
  .xl\:absolute {
    position: absolute !important;
  }
  .xl\:relative {
    position: relative !important;
  }
  .xl\:sticky {
    position: sticky !important;
  }
}
.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }
  .sm\:top-0 {
    top: 0px !important;
  }
  .sm\:top-50 {
    top: 50% !important;
  }
  .sm\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:top-auto {
    top: auto !important;
  }
  .md\:top-0 {
    top: 0px !important;
  }
  .md\:top-50 {
    top: 50% !important;
  }
  .md\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:top-auto {
    top: auto !important;
  }
  .lg\:top-0 {
    top: 0px !important;
  }
  .lg\:top-50 {
    top: 50% !important;
  }
  .lg\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }
  .xl\:top-0 {
    top: 0px !important;
  }
  .xl\:top-50 {
    top: 50% !important;
  }
  .xl\:top-100 {
    top: 100% !important;
  }
}
.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }
  .sm\:left-0 {
    left: 0px !important;
  }
  .sm\:left-50 {
    left: 50% !important;
  }
  .sm\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:left-auto {
    left: auto !important;
  }
  .md\:left-0 {
    left: 0px !important;
  }
  .md\:left-50 {
    left: 50% !important;
  }
  .md\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:left-auto {
    left: auto !important;
  }
  .lg\:left-0 {
    left: 0px !important;
  }
  .lg\:left-50 {
    left: 50% !important;
  }
  .lg\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }
  .xl\:left-0 {
    left: 0px !important;
  }
  .xl\:left-50 {
    left: 50% !important;
  }
  .xl\:left-100 {
    left: 100% !important;
  }
}
.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0px !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }
  .sm\:right-0 {
    right: 0px !important;
  }
  .sm\:right-50 {
    right: 50% !important;
  }
  .sm\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:right-auto {
    right: auto !important;
  }
  .md\:right-0 {
    right: 0px !important;
  }
  .md\:right-50 {
    right: 50% !important;
  }
  .md\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:right-auto {
    right: auto !important;
  }
  .lg\:right-0 {
    right: 0px !important;
  }
  .lg\:right-50 {
    right: 50% !important;
  }
  .lg\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }
  .xl\:right-0 {
    right: 0px !important;
  }
  .xl\:right-50 {
    right: 50% !important;
  }
  .xl\:right-100 {
    right: 100% !important;
  }
}
.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }
  .sm\:bottom-0 {
    bottom: 0px !important;
  }
  .sm\:bottom-50 {
    bottom: 50% !important;
  }
  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }
  .md\:bottom-0 {
    bottom: 0px !important;
  }
  .md\:bottom-50 {
    bottom: 50% !important;
  }
  .md\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }
  .lg\:bottom-0 {
    bottom: 0px !important;
  }
  .lg\:bottom-50 {
    bottom: 50% !important;
  }
  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }
  .xl\:bottom-0 {
    bottom: 0px !important;
  }
  .xl\:bottom-50 {
    bottom: 50% !important;
  }
  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }
  .sm\:overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:overflow-visible {
    overflow: visible !important;
  }
  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }
  .md\:overflow-hidden {
    overflow: hidden !important;
  }
  .md\:overflow-visible {
    overflow: visible !important;
  }
  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }
  .lg\:overflow-hidden {
    overflow: hidden !important;
  }
  .lg\:overflow-visible {
    overflow: visible !important;
  }
  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }
  .xl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xl\:overflow-visible {
    overflow: visible !important;
  }
  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }
  .sm\:z-0 {
    z-index: 0 !important;
  }
  .sm\:z-1 {
    z-index: 1 !important;
  }
  .sm\:z-2 {
    z-index: 2 !important;
  }
  .sm\:z-3 {
    z-index: 3 !important;
  }
  .sm\:z-4 {
    z-index: 4 !important;
  }
  .sm\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }
  .md\:z-0 {
    z-index: 0 !important;
  }
  .md\:z-1 {
    z-index: 1 !important;
  }
  .md\:z-2 {
    z-index: 2 !important;
  }
  .md\:z-3 {
    z-index: 3 !important;
  }
  .md\:z-4 {
    z-index: 4 !important;
  }
  .md\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }
  .lg\:z-0 {
    z-index: 0 !important;
  }
  .lg\:z-1 {
    z-index: 1 !important;
  }
  .lg\:z-2 {
    z-index: 2 !important;
  }
  .lg\:z-3 {
    z-index: 3 !important;
  }
  .lg\:z-4 {
    z-index: 4 !important;
  }
  .lg\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }
  .xl\:z-0 {
    z-index: 0 !important;
  }
  .xl\:z-1 {
    z-index: 1 !important;
  }
  .xl\:z-2 {
    z-index: 2 !important;
  }
  .xl\:z-3 {
    z-index: 3 !important;
  }
  .xl\:z-4 {
    z-index: 4 !important;
  }
  .xl\:z-5 {
    z-index: 5 !important;
  }
}
.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }
  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .md\:bg-repeat-round {
    background-repeat: round !important;
  }
  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }
  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }
  .sm\:bg-cover {
    background-size: cover !important;
  }
  .sm\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }
  .md\:bg-cover {
    background-size: cover !important;
  }
  .md\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }
  .lg\:bg-cover {
    background-size: cover !important;
  }
  .lg\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }
  .xl\:bg-cover {
    background-size: cover !important;
  }
  .xl\:bg-contain {
    background-size: contain !important;
  }
}
.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }
  .sm\:bg-center {
    background-position: center !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .sm\:bg-left-top {
    background-position: left top !important;
  }
  .sm\:bg-right {
    background-position: right !important;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .sm\:bg-right-top {
    background-position: right top !important;
  }
  .sm\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }
  .md\:bg-center {
    background-position: center !important;
  }
  .md\:bg-left {
    background-position: left !important;
  }
  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .md\:bg-left-top {
    background-position: left top !important;
  }
  .md\:bg-right {
    background-position: right !important;
  }
  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .md\:bg-right-top {
    background-position: right top !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }
  .lg\:bg-center {
    background-position: center !important;
  }
  .lg\:bg-left {
    background-position: left !important;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .lg\:bg-left-top {
    background-position: left top !important;
  }
  .lg\:bg-right {
    background-position: right !important;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .lg\:bg-right-top {
    background-position: right top !important;
  }
  .lg\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }
  .xl\:bg-center {
    background-position: center !important;
  }
  .xl\:bg-left {
    background-position: left !important;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xl\:bg-left-top {
    background-position: left top !important;
  }
  .xl\:bg-right {
    background-position: right !important;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xl\:bg-right-top {
    background-position: right top !important;
  }
  .xl\:bg-top {
    background-position: top !important;
  }
}
.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color,border-color,color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: 100ms !important;
}

.transition-duration-150 {
  transition-duration: 150ms !important;
}

.transition-duration-200 {
  transition-duration: 200ms !important;
}

.transition-duration-300 {
  transition-duration: 300ms !important;
}

.transition-duration-400 {
  transition-duration: 400ms !important;
}

.transition-duration-500 {
  transition-duration: 500ms !important;
}

.transition-duration-1000 {
  transition-duration: 1000ms !important;
}

.transition-duration-2000 {
  transition-duration: 2000ms !important;
}

.transition-duration-3000 {
  transition-duration: 3000ms !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  transition-delay: 100ms !important;
}

.transition-delay-150 {
  transition-delay: 150ms !important;
}

.transition-delay-200 {
  transition-delay: 200ms !important;
}

.transition-delay-300 {
  transition-delay: 300ms !important;
}

.transition-delay-400 {
  transition-delay: 400ms !important;
}

.transition-delay-500 {
  transition-delay: 500ms !important;
}

.transition-delay-1000 {
  transition-delay: 1000ms !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }
  .sm\:origin-top {
    transform-origin: top !important;
  }
  .sm\:origin-top-right {
    transform-origin: top right !important;
  }
  .sm\:origin-right {
    transform-origin: right !important;
  }
  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }
  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .sm\:origin-left {
    transform-origin: left !important;
  }
  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }
  .md\:origin-top {
    transform-origin: top !important;
  }
  .md\:origin-top-right {
    transform-origin: top right !important;
  }
  .md\:origin-right {
    transform-origin: right !important;
  }
  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .md\:origin-bottom {
    transform-origin: bottom !important;
  }
  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .md\:origin-left {
    transform-origin: left !important;
  }
  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }
  .lg\:origin-top {
    transform-origin: top !important;
  }
  .lg\:origin-top-right {
    transform-origin: top right !important;
  }
  .lg\:origin-right {
    transform-origin: right !important;
  }
  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }
  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .lg\:origin-left {
    transform-origin: left !important;
  }
  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }
  .xl\:origin-top {
    transform-origin: top !important;
  }
  .xl\:origin-top-right {
    transform-origin: top right !important;
  }
  .xl\:origin-right {
    transform-origin: right !important;
  }
  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xl\:origin-left {
    transform-origin: left !important;
  }
  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.fadein {
  animation: fadein 0.15s linear;
}

.fadeout {
  animation: fadeout 0.15s linear;
}

.slidedown {
  animation: slidedown 0.45s ease-in-out;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  animation: scalein 0.15s linear;
}

.fadeinleft {
  animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  animation: fadeinright 0.15s linear;
}

.fadeoutright {
  animation: fadeoutright 0.15s linear;
}

.fadeinup {
  animation: fadeinup 0.15s linear;
}

.fadeoutup {
  animation: fadeoutup 0.15s linear;
}

.fadeindown {
  animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  animation: fadeoutdown 0.15s linear;
}

.animate-width {
  animation: animate-width 1000ms linear;
}

.animation-duration-100 {
  animation-duration: 100ms !important;
}

.animation-duration-150 {
  animation-duration: 150ms !important;
}

.animation-duration-200 {
  animation-duration: 200ms !important;
}

.animation-duration-300 {
  animation-duration: 300ms !important;
}

.animation-duration-400 {
  animation-duration: 400ms !important;
}

.animation-duration-500 {
  animation-duration: 500ms !important;
}

.animation-duration-1000 {
  animation-duration: 1000ms !important;
}

.animation-duration-2000 {
  animation-duration: 2000ms !important;
}

.animation-duration-3000 {
  animation-duration: 3000ms !important;
}

.animation-delay-100 {
  animation-delay: 100ms !important;
}

.animation-delay-150 {
  animation-delay: 150ms !important;
}

.animation-delay-200 {
  animation-delay: 200ms !important;
}

.animation-delay-300 {
  animation-delay: 300ms !important;
}

.animation-delay-400 {
  animation-delay: 400ms !important;
}

.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-input-icon-left > span {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  left: 1rem;
  color: rgba(0, 0, 0, 0.6);
}

.p-inputtext {
  border: none;
}
.p-inputtext:disabled {
  opacity: unset;
  border: 2px solid #fff;
  background-color: rgb(var(--background));
}
.p-inputtext:enabled:focus, .p-inputtext.ng-dirty.ng-invalid, .p-inputtext:enabled:focus.ng-invalid.ng-dirty {
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}

.p-error {
  font-size: 1rem;
  color: #ef626c;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.field {
  position: relative;
  margin-bottom: 1.5rem;
}
.field label {
  color: rgb(var(--primary));
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .field > small {
    position: absolute;
  }
}
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-button {
  display: unset;
  padding: 0.5rem;
}
.p-button.p-button-icon-only {
  width: 2rem;
  padding: 0.5rem;
}
.p-button.p-button-icon-only.p-button-text:enabled, .p-button.p-button-icon-only.p-button-outlined:enabled {
  color: rgb(var(--primary));
  background: transparent;
}
.p-button.p-button-icon-only.p-button-text:enabled:focus, .p-button.p-button-icon-only.p-button-text:enabled:hover, .p-button.p-button-icon-only.p-button-text:enabled:active, .p-button.p-button-icon-only.p-button-outlined:enabled:focus, .p-button.p-button-icon-only.p-button-outlined:enabled:hover, .p-button.p-button-icon-only.p-button-outlined:enabled:active {
  color: rgb(var(--primary));
  background: transparent;
}
.p-button .p-button-label {
  flex: unset;
}
.p-button.required .p-button-label::after {
  content: "*";
  color: #ef626c;
}

button .p-button-icon {
  font-size: 1.4rem;
}

.p-menu {
  padding: unset;
  width: fit-content;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-component {
  font-family: "Source Sans Pro", sans-serif;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-toast {
  opacity: 1;
}
.p-toast.p-toast-top-right {
  top: 8.25rem;
  right: 2rem;
}
@media only screen and (max-width: 1023px) {
  .p-toast.p-toast-top-right {
    top: 13.25rem;
    right: 1rem;
  }
}
@media only screen and (max-width: 575px) {
  .p-toast.p-toast-top-right {
    top: 10rem;
    width: 20rem;
  }
}
.p-toast .p-toast-message.p-toast-message-error {
  border: solid #ff5757;
  border-width: 0 0 0 0.5rem;
  border-radius: 0.5rem;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  align-items: center;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 1.5rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: unset;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-paginator {
  background: unset;
  border: unset;
  font-family: "Source Sans Pro", sans-serif;
}
.p-paginator .p-paginator-first {
  margin-left: auto;
}
.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  height: 2rem;
  min-width: 2rem;
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  color: rgb(var(--primary));
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background: #fff;
}
.p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  color: rgb(var(--primary));
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  color: #fff;
  background: rgb(var(--primary));
}
@media only screen and (max-width: 575px) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    padding: 0.5rem 0;
  }
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-sidebar.p-sidebar-active {
  width: 15rem;
}
.p-sidebar .p-sidebar-header {
  display: none;
}
.p-sidebar .p-sidebar-content {
  padding: 0 1rem 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-togglebutton.p-button.p-highlight,
.p-togglebutton.p-button.p-highlight:hover,
.p-togglebutton.p-button:focus.p-highlight {
  background: rgb(var(--primary));
  border-color: rgb(var(--primary));
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: rgb(var(--secondary-text-color));
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-tooltip {
  max-width: 26rem;
}
@media only screen and (max-width: 1023px) {
  .p-tooltip {
    max-width: 20rem;
  }
}
@media only screen and (max-width: 575px) {
  .p-tooltip {
    max-width: 10rem;
  }
}
.p-tooltip .p-tooltip-text {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgb(var(--primary-text-color));
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-tieredmenu {
  width: auto;
  max-width: 12rem;
  padding: 0;
  border-radius: 0.25rem;
}
.p-tieredmenu .p-submenu-list {
  padding: inherit;
  border-radius: 0.25rem;
}
.p-tieredmenu .highlight span {
  font-weight: 700;
}
.p-tieredmenu .p-menuitem .p-menuitem-link .p-menuitem-icon {
  font-weight: 700;
  font-size: 20px;
}
.p-tieredmenu .p-menuitem .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgb(var(--primary-text-color)) !important;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-dropdown {
  border: none;
  width: 100%;
  height: 3.5rem;
  border-radius: 2rem;
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2), inset 0 0 0 1px rgb(var(--primary));
}
.p-dropdown:not(.p-disabled):hover {
  border-color: unset;
}
.p-dropdown .p-dropdown-label {
  width: 0;
}
.p-dropdown.primary {
  background-color: rgb(var(--primary));
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2), inset 0 0 0 1px #fff;
}
.p-dropdown.primary .p-dropdown-label {
  color: #fff;
}
.p-dropdown.ng-dirty.ng-invalid .p-dropdown.ng-dirty.ng-invalid {
  background-color: #ef626c !important;
}
.p-dropdown .p-inputtext {
  padding: 1rem 1.5rem;
  align-self: center;
}
.p-dropdown .p-dropdown-trigger {
  width: 2.875rem;
}
.p-dropdown .p-dropdown-trigger .fa-solid.fa-angle-down {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--primary), 0.5);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 0.7rem;
  color: rgb(var(--secondary-text-color));
}

.p-dropdown-panel {
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
  width: inherit;
  max-width: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: inherit;
  background: rgba(var(--primary), 0.5);
}

.p-dropdown-panel.search-box__dropdown {
  border-radius: 0.25rem;
  width: auto;
  max-width: 12rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-multiselect {
  border: none;
  width: 100%;
  height: 3.5rem;
  border-radius: 2rem;
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}
.p-multiselect.p-multiselect-open, .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2), inset 0 0 0 1px rgb(var(--primary));
}
.p-multiselect.primary {
  background-color: rgb(var(--primary));
}
.p-multiselect.primary .p-dropdown-label {
  color: #fff;
}
.p-multiselect.ng-dirty.ng-invalid .p-multiselect.ng-dirty.ng-invalid {
  background-color: #ef626c !important;
}
.p-multiselect .p-multiselect-label-container {
  width: 0;
  display: flex;
  align-items: center;
}
.p-multiselect .p-multiselect-label {
  padding: 1rem 1.5rem;
}
@media only screen and (max-width: 575px) {
  .p-multiselect .p-multiselect-label {
    width: calc(100vw - 6rem);
  }
}
.p-multiselect .p-multiselect-trigger {
  width: 2.875rem;
}
.p-multiselect .p-multiselect-trigger .fa-solid.fa-angle-down {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--primary), 0.5);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 0.7rem;
  color: rgb(var(--secondary-text-color));
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  border-radius: 1rem;
}

.p-multiselect-panel {
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0 1rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter:enabled:focus {
  box-shadow: unset;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
  display: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight, .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus.p-highlight {
  color: inherit;
  background: transparent;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox .p-checkbox-box {
  border-radius: 50%;
  border: 1px solid rgb(var(--primary));
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox .p-checkbox-box.p-highlight {
  background-color: rgb(var(--primary));
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.html-content .ql-editor img {
  max-width: 100%;
}
.html-content .ql-editor a {
  color: rgb(var(--primary));
}

p-editor .p-editor-container {
  border-radius: 2rem;
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}
p-editor .p-editor-container .p-editor-toolbar.ql-snow {
  padding: 16px;
  border: none;
  background-color: rgb(var(--background));
  border-radius: 2rem 2rem 0 0;
}
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-picker-label.ql-active,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-bold.ql-active,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-italic.ql-active,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-underline.ql-active,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-list.ql-active,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-code-block.ql-active {
  color: rgb(var(--primary));
}
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-picker-label.ql-active svg .ql-stroke,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-bold.ql-active svg .ql-stroke,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-italic.ql-active svg .ql-stroke,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-underline.ql-active svg .ql-stroke,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-list.ql-active svg .ql-stroke,
p-editor .p-editor-container .p-editor-toolbar.ql-snow .ql-code-block.ql-active svg .ql-stroke {
  stroke: rgb(var(--primary));
}
p-editor .p-editor-container .p-editor-content {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 0 0 2rem 2rem;
}
p-editor .p-editor-container .p-editor-content .editor-wrapper {
  width: 100%;
  height: 16.5rem;
  overflow: hidden;
  border-radius: 0 0 2rem 2rem;
}
p-editor .p-editor-container .p-editor-content .editor-wrapper .ql-editor {
  border: none;
  padding: 16px 24px;
  font-size: 1rem;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 0 0 2rem 2rem;
}
p-editor .p-editor-container .p-editor-content .editor-wrapper .ql-editor:focus {
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2), inset 0 0 0 1px rgb(var(--primary));
}
p-editor .p-editor-container .p-editor-content .editor-wrapper .ql-editor::before {
  left: 1.5rem;
  right: 1.5rem;
  font: normal 1rem "Source Sans Pro", sans-serif;
}
p-editor .p-editor-container .p-editor-content.ql-snow {
  border: none;
}
p-editor .p-editor-container .p-editor-content.ql-snow .ql-tooltip.ql-editing {
  left: unset !important;
  z-index: 1;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-inputtextarea {
  overflow: auto;
  padding: 16px 24px;
  display: block;
}
.p-inputtextarea:enabled:focus, .p-inputtextarea.ng-dirty.ng-invalid, .p-inputtextarea:enabled:focus.ng-invalid.ng-dirty {
  box-shadow: unset;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.p-tabview .p-tabview-nav {
  background: transparent;
  justify-content: flex-end;
  border-bottom: 2px solid rgb(var(--gray));
}
@media only screen and (max-width: 575px) {
  .p-tabview .p-tabview-nav {
    justify-content: flex-start;
  }
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-bottom-color: transparent;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link, .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: rgb(var(--primary-text-color));
  background: transparent;
  margin-bottom: -2px;
  padding: 0.7rem 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: rgb(var(--primary));
  border-bottom: 2px solid rgb(var(--primary));
}
.p-tabview .p-tabview-nav li.p-tabview-ink-bar {
  display: none;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: unset;
  color: rgb(var(--primary));
}
.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 1rem 0;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
p-fileupload .p-button {
  display: flex;
  align-items: center;
  color: rgb(var(--primary));
  background-color: unset;
}
p-fileupload span.p-button-icon {
  font-size: 2rem;
}
p-fileupload .p-fileupload-choose:not(.p-disabled):hover {
  background: unset;
  color: unset;
}
p-fileupload .p-messages {
  display: none;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
p-divider {
  display: flex;
}

.p-divider.p-divider-horizontal.p-divider-dashed::before {
  border: none;
  height: 1px;
  background-image: linear-gradient(to right, rgba(var(--gray), 0.6) 0.6rem, transparent 1px);
  background-size: 1rem 1rem;
}
.p-divider.p-divider-horizontal::before {
  border-top: 1px rgba(var(--gray), 0.6);
}

p-card .p-card {
  box-shadow: 0 3px 14px 0 rgba(29, 34, 40, 0.2);
}
p-card .p-card .p-card-body .p-card-content {
  word-break: break-word;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
p-progressspinner .p-progress-spinner .p-progress-spinner-circle {
  stroke: #fff;
  animation: unset;
}
@media only screen and (max-width: 767px) {
  p-progressspinner .p-progress-spinner {
    width: 4rem;
    height: 4rem;
  }
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
p-inputswitch .p-inputswitch {
  height: 2.35rem;
  width: 4.5rem;
}
p-inputswitch .p-inputswitch .p-inputswitch-slider {
  border-radius: 2rem;
  background: #eceef1 !important;
}
p-inputswitch .p-inputswitch .p-inputswitch-slider::before {
  box-shadow: unset !important;
  background: rgb(var(--primary));
  width: 2rem;
  height: 2rem;
  left: 0.25rem;
  margin-top: -1rem;
}
p-inputswitch .p-inputswitch .p-inputswitch-slider::after {
  content: "aus";
  text-transform: uppercase;
  font-size: 0.75rem;
  color: rgb(var(--primary));
  display: flex;
  justify-content: flex-end;
  line-height: 2.35rem;
  margin-right: 0.75rem;
}
p-inputswitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: rgb(var(--primary)) !important;
}
p-inputswitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  box-shadow: unset !important;
  background: #fff;
  left: 0.75rem;
}
p-inputswitch .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::after {
  content: "ein";
  color: #fff;
  justify-content: flex-start;
  margin-left: 0.75rem;
}

.header-profile .p-avatar {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

app-sidebar .p-sidebar app-sidebar-item {
  width: 100%;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.drag-drop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drag-drop em {
  color: rgb(var(--primary));
  cursor: move;
}
.drag-drop-order {
  width: 1.5rem;
  line-height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(var(--primary));
  color: #fff;
  margin-right: 1rem;
  font-size: 0.875rem;
  border: 1px solid #fff;
  box-sizing: content-box;
}

.cdk-drag-preview {
  display: table;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: rgb(var(--primary));
  color: rgb(var(--secondary-text-color));
  opacity: 0.8;
}
.cdk-drag-preview > td {
  /* stylelint-disable no-descending-specificity */
}
.cdk-drag-preview > td .p-column-title {
  display: none;
  color: rgb(var(--secondary-text-color)) !important;
}
.cdk-drag-preview > td .p-column-title,
.cdk-drag-preview > td .url > a,
.cdk-drag-preview > td .drag-drop em {
  color: rgb(var(--secondary-text-color)) !important;
}
.cdk-drag-preview > td.stacked {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: unset !important;
}
.cdk-drag-preview > td.stacked .p-column-title {
  display: block;
}

.cdk-drag-preview:last-child,
app-table .p-datatable .p-datatable-tbody > tr:last-child {
  border-bottom: 0;
}
.cdk-drag-preview > td,
app-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem;
}
.cdk-drag-preview > td .p-column-title,
app-table .p-datatable .p-datatable-tbody > tr > td .p-column-title {
  width: 16rem;
  color: rgb(var(--primary));
  padding-right: 0.2rem;
}
@media only screen and (max-width: 575px) {
  .cdk-drag-preview > td .p-column-title,
app-table .p-datatable .p-datatable-tbody > tr > td .p-column-title {
    width: 10rem;
  }
}
.cdk-drag-preview > td .default,
app-table .p-datatable .p-datatable-tbody > tr > td .default {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  word-break: break-word;
}
.cdk-drag-preview > td .default span,
app-table .p-datatable .p-datatable-tbody > tr > td .default span {
  outline: 0;
}
.cdk-drag-preview > td.actions,
app-table .p-datatable .p-datatable-tbody > tr > td.actions {
  justify-content: flex-end !important;
}

app-table .p-datatable {
  height: 100%;
  display: flex;
  flex-direction: column;
}
app-table .p-datatable .p-datatable-thead {
  top: 0;
  z-index: 2;
  font-size: 20px;
}
app-table .p-datatable .p-datatable-thead > tr > th {
  position: relative;
  padding: 0.75rem;
  color: rgb(var(--primary));
  background-color: transparent;
}
app-table .p-datatable .p-datatable-thead > tr > th.p-sortable-column:hover, app-table .p-datatable .p-datatable-thead > tr > th.p-sortable-column:focus {
  background: var(--surface-200);
}
app-table .p-datatable .p-datatable-thead > tr > th::after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;
  border-bottom: inherit;
}
app-table .p-datatable .p-datatable-tbody > tr {
  border-bottom: 1px solid #e4e4e4;
}
app-table .p-datatable .p-datatable-tbody > tr:last-child {
  border-bottom: 0;
}
app-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 0.75rem;
}
app-table .p-datatable .p-datatable-tbody > tr > td .p-column-title {
  width: 16rem;
  color: rgb(var(--primary));
  padding-right: 0.2rem;
}
@media only screen and (max-width: 575px) {
  app-table .p-datatable .p-datatable-tbody > tr > td .p-column-title {
    width: 10rem;
  }
}
app-table .p-datatable .p-datatable-tbody > tr > td .default {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  word-break: break-word;
}
app-table .p-datatable .p-datatable-tbody > tr > td .default span {
  outline: 0;
}
app-table .p-datatable .p-datatable-tbody > tr > td.actions {
  justify-content: flex-end !important;
}
app-table .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: var(--surface-200);
}
app-table .sticky-header .p-datatable-thead {
  position: sticky;
  z-index: 1;
}
app-table .p-column-filter-row p-columnfilterformelement {
  flex: 1 1 auto;
  width: 100%;
}
app-table .p-datatable-wrapper {
  overflow-x: auto;
}
app-table .header-actions {
  color: rgb(var(--primary));
}
app-table .header-actions.stacked {
  position: relative;
}
app-table .header-actions.stacked .button-title {
  right: 2.2rem;
  bottom: 0.6rem;
}
app-table .header-actions .button-title {
  white-space: nowrap;
  position: absolute;
  right: 3.2rem;
  bottom: 1.2rem;
}
app-table .header-actions .p-button {
  margin-left: auto;
}
app-table .header-actions .p-button .p-button-icon {
  font-size: 1.3rem;
  text-align: right;
}
app-table .header-actions .p-button.p-button-icon-only.p-button-rounded {
  display: flex;
  width: unset;
  height: unset;
}

.drag-drop-heading {
  width: 6rem;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.table-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.content-layout-dialog.p-dynamic-dialog {
  width: 58rem;
  border-radius: 2.5rem;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 1023px) {
  .content-layout-dialog.p-dynamic-dialog {
    width: 43rem;
  }
}
@media only screen and (max-width: 575px) {
  .content-layout-dialog.p-dynamic-dialog {
    width: 21.5rem;
    border-radius: 1rem;
  }
}
.content-layout-dialog.p-dynamic-dialog .p-dialog-content {
  border-radius: 2.5rem;
  padding: 3.75rem 7.5rem;
}
@media only screen and (max-width: 1023px) {
  .content-layout-dialog.p-dynamic-dialog .p-dialog-content {
    padding: 3.75rem 2rem;
  }
}
@media only screen and (max-width: 575px) {
  .content-layout-dialog.p-dynamic-dialog .p-dialog-content {
    padding: 2rem 1.5rem;
    border-radius: 1rem;
  }
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.confirmation-dialog.p-dynamic-dialog {
  width: 35rem;
  border-radius: 2.5rem;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .confirmation-dialog.p-dynamic-dialog {
    width: 19.5rem;
    border-radius: 1rem;
  }
}
.confirmation-dialog.p-dynamic-dialog .p-dialog-content {
  padding: 3.5rem;
}
@media only screen and (max-width: 575px) {
  .confirmation-dialog.p-dynamic-dialog .p-dialog-content {
    padding: 2rem 1.5rem;
  }
}

.close-btn.p-button.p-button-icon-only.p-button-rounded {
  width: 2.6rem;
  height: 2.6rem;
}
.close-btn.p-button.p-button-icon-only.p-button-rounded .p-button-icon {
  font-size: 1.5rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.paginator.p-paginator {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .paginator.p-paginator {
    justify-content: center;
  }
}

app-content-category-form app-text-area .field .form-control {
  height: 10rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
.status p-tag {
  border-radius: 0;
}
.status p-tag span {
  font-weight: 500;
  color: rgb(var(--secondary-text-color));
  font-size: 1rem;
  border-radius: 0;
  padding: 0;
}
.status p-tag span .p-tag-value {
  display: none;
}
.status p-tag .published-tag {
  background-color: rgb(var(--published));
}
.status p-tag .published-tag span {
  padding: 0.25rem 0.4rem;
}
.status p-tag .hidden-tag {
  background-color: rgb(var(--hidden));
}
.status p-tag .hidden-tag span {
  padding: 0.25rem 0.4rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
app-toggle-button .p-togglebutton.p-button .p-button-icon-left,
app-toggle-button .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
  color: rgb(var(--primary));
}
app-toggle-button .p-button.p-button-icon-only,
app-toggle-button .p-togglebutton.p-button.p-highlight {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}
app-toggle-button .p-button.p-button-icon-only .pi,
app-toggle-button .p-togglebutton.p-button.p-highlight .pi {
  font-size: 0.75rem;
}

app-process-step-form app-editor p-editor .p-editor-container .p-editor-content .editor-wrapper {
  height: 12rem;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
.pt-0 {
  padding-top: 0px;
}

.pt-0-i {
  padding-top: 0px !important;
}

.pr-0 {
  padding-right: 0px;
}

.pr-0-i {
  padding-right: 0px !important;
}

.pl-0 {
  padding-left: 0px;
}

.pl-0-i {
  padding-left: 0px !important;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-0-i {
  padding-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px;
}

.mt-0-i {
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0px;
}

.mr-0-i {
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0-i {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px;
}

.ml-0-i {
  margin-left: 0px !important;
}

.pt-1 {
  padding-top: 1px;
}

.pt-1-i {
  padding-top: 1px !important;
}

.pr-1 {
  padding-right: 1px;
}

.pr-1-i {
  padding-right: 1px !important;
}

.pl-1 {
  padding-left: 1px;
}

.pl-1-i {
  padding-left: 1px !important;
}

.pb-1 {
  padding-bottom: 1px;
}

.pb-1-i {
  padding-bottom: 1px !important;
}

.mt-1 {
  margin-top: 1px;
}

.mt-1-i {
  margin-top: 1px !important;
}

.mr-1 {
  margin-right: 1px;
}

.mr-1-i {
  margin-right: 1px !important;
}

.mb-1 {
  margin-bottom: 1px;
}

.mb-1-i {
  margin-bottom: 1px !important;
}

.ml-1 {
  margin-left: 1px;
}

.ml-1-i {
  margin-left: 1px !important;
}

.pt-2 {
  padding-top: 2px;
}

.pt-2-i {
  padding-top: 2px !important;
}

.pr-2 {
  padding-right: 2px;
}

.pr-2-i {
  padding-right: 2px !important;
}

.pl-2 {
  padding-left: 2px;
}

.pl-2-i {
  padding-left: 2px !important;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-2-i {
  padding-bottom: 2px !important;
}

.mt-2 {
  margin-top: 2px;
}

.mt-2-i {
  margin-top: 2px !important;
}

.mr-2 {
  margin-right: 2px;
}

.mr-2-i {
  margin-right: 2px !important;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-2-i {
  margin-bottom: 2px !important;
}

.ml-2 {
  margin-left: 2px;
}

.ml-2-i {
  margin-left: 2px !important;
}

.pt-3 {
  padding-top: 3px;
}

.pt-3-i {
  padding-top: 3px !important;
}

.pr-3 {
  padding-right: 3px;
}

.pr-3-i {
  padding-right: 3px !important;
}

.pl-3 {
  padding-left: 3px;
}

.pl-3-i {
  padding-left: 3px !important;
}

.pb-3 {
  padding-bottom: 3px;
}

.pb-3-i {
  padding-bottom: 3px !important;
}

.mt-3 {
  margin-top: 3px;
}

.mt-3-i {
  margin-top: 3px !important;
}

.mr-3 {
  margin-right: 3px;
}

.mr-3-i {
  margin-right: 3px !important;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-3-i {
  margin-bottom: 3px !important;
}

.ml-3 {
  margin-left: 3px;
}

.ml-3-i {
  margin-left: 3px !important;
}

.pt-4 {
  padding-top: 4px;
}

.pt-4-i {
  padding-top: 4px !important;
}

.pr-4 {
  padding-right: 4px;
}

.pr-4-i {
  padding-right: 4px !important;
}

.pl-4 {
  padding-left: 4px;
}

.pl-4-i {
  padding-left: 4px !important;
}

.pb-4 {
  padding-bottom: 4px;
}

.pb-4-i {
  padding-bottom: 4px !important;
}

.mt-4 {
  margin-top: 4px;
}

.mt-4-i {
  margin-top: 4px !important;
}

.mr-4 {
  margin-right: 4px;
}

.mr-4-i {
  margin-right: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-4-i {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-4-i {
  margin-left: 4px !important;
}

.pt-5 {
  padding-top: 5px;
}

.pt-5-i {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px;
}

.pr-5-i {
  padding-right: 5px !important;
}

.pl-5 {
  padding-left: 5px;
}

.pl-5-i {
  padding-left: 5px !important;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-5-i {
  padding-bottom: 5px !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-5-i {
  margin-top: 5px !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-5-i {
  margin-right: 5px !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-5-i {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-5-i {
  margin-left: 5px !important;
}

.pt-6 {
  padding-top: 6px;
}

.pt-6-i {
  padding-top: 6px !important;
}

.pr-6 {
  padding-right: 6px;
}

.pr-6-i {
  padding-right: 6px !important;
}

.pl-6 {
  padding-left: 6px;
}

.pl-6-i {
  padding-left: 6px !important;
}

.pb-6 {
  padding-bottom: 6px;
}

.pb-6-i {
  padding-bottom: 6px !important;
}

.mt-6 {
  margin-top: 6px;
}

.mt-6-i {
  margin-top: 6px !important;
}

.mr-6 {
  margin-right: 6px;
}

.mr-6-i {
  margin-right: 6px !important;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-6-i {
  margin-bottom: 6px !important;
}

.ml-6 {
  margin-left: 6px;
}

.ml-6-i {
  margin-left: 6px !important;
}

.pt-7 {
  padding-top: 7px;
}

.pt-7-i {
  padding-top: 7px !important;
}

.pr-7 {
  padding-right: 7px;
}

.pr-7-i {
  padding-right: 7px !important;
}

.pl-7 {
  padding-left: 7px;
}

.pl-7-i {
  padding-left: 7px !important;
}

.pb-7 {
  padding-bottom: 7px;
}

.pb-7-i {
  padding-bottom: 7px !important;
}

.mt-7 {
  margin-top: 7px;
}

.mt-7-i {
  margin-top: 7px !important;
}

.mr-7 {
  margin-right: 7px;
}

.mr-7-i {
  margin-right: 7px !important;
}

.mb-7 {
  margin-bottom: 7px;
}

.mb-7-i {
  margin-bottom: 7px !important;
}

.ml-7 {
  margin-left: 7px;
}

.ml-7-i {
  margin-left: 7px !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-8-i {
  padding-top: 8px !important;
}

.pr-8 {
  padding-right: 8px;
}

.pr-8-i {
  padding-right: 8px !important;
}

.pl-8 {
  padding-left: 8px;
}

.pl-8-i {
  padding-left: 8px !important;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-8-i {
  padding-bottom: 8px !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-8-i {
  margin-top: 8px !important;
}

.mr-8 {
  margin-right: 8px;
}

.mr-8-i {
  margin-right: 8px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-8-i {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px;
}

.ml-8-i {
  margin-left: 8px !important;
}

.pt-9 {
  padding-top: 9px;
}

.pt-9-i {
  padding-top: 9px !important;
}

.pr-9 {
  padding-right: 9px;
}

.pr-9-i {
  padding-right: 9px !important;
}

.pl-9 {
  padding-left: 9px;
}

.pl-9-i {
  padding-left: 9px !important;
}

.pb-9 {
  padding-bottom: 9px;
}

.pb-9-i {
  padding-bottom: 9px !important;
}

.mt-9 {
  margin-top: 9px;
}

.mt-9-i {
  margin-top: 9px !important;
}

.mr-9 {
  margin-right: 9px;
}

.mr-9-i {
  margin-right: 9px !important;
}

.mb-9 {
  margin-bottom: 9px;
}

.mb-9-i {
  margin-bottom: 9px !important;
}

.ml-9 {
  margin-left: 9px;
}

.ml-9-i {
  margin-left: 9px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-10-i {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px;
}

.pr-10-i {
  padding-right: 10px !important;
}

.pl-10 {
  padding-left: 10px;
}

.pl-10-i {
  padding-left: 10px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-10-i {
  padding-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-10-i {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-10-i {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-10-i {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-10-i {
  margin-left: 10px !important;
}

.pt-11 {
  padding-top: 11px;
}

.pt-11-i {
  padding-top: 11px !important;
}

.pr-11 {
  padding-right: 11px;
}

.pr-11-i {
  padding-right: 11px !important;
}

.pl-11 {
  padding-left: 11px;
}

.pl-11-i {
  padding-left: 11px !important;
}

.pb-11 {
  padding-bottom: 11px;
}

.pb-11-i {
  padding-bottom: 11px !important;
}

.mt-11 {
  margin-top: 11px;
}

.mt-11-i {
  margin-top: 11px !important;
}

.mr-11 {
  margin-right: 11px;
}

.mr-11-i {
  margin-right: 11px !important;
}

.mb-11 {
  margin-bottom: 11px;
}

.mb-11-i {
  margin-bottom: 11px !important;
}

.ml-11 {
  margin-left: 11px;
}

.ml-11-i {
  margin-left: 11px !important;
}

.pt-12 {
  padding-top: 12px;
}

.pt-12-i {
  padding-top: 12px !important;
}

.pr-12 {
  padding-right: 12px;
}

.pr-12-i {
  padding-right: 12px !important;
}

.pl-12 {
  padding-left: 12px;
}

.pl-12-i {
  padding-left: 12px !important;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-12-i {
  padding-bottom: 12px !important;
}

.mt-12 {
  margin-top: 12px;
}

.mt-12-i {
  margin-top: 12px !important;
}

.mr-12 {
  margin-right: 12px;
}

.mr-12-i {
  margin-right: 12px !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-12-i {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px;
}

.ml-12-i {
  margin-left: 12px !important;
}

.pt-13 {
  padding-top: 13px;
}

.pt-13-i {
  padding-top: 13px !important;
}

.pr-13 {
  padding-right: 13px;
}

.pr-13-i {
  padding-right: 13px !important;
}

.pl-13 {
  padding-left: 13px;
}

.pl-13-i {
  padding-left: 13px !important;
}

.pb-13 {
  padding-bottom: 13px;
}

.pb-13-i {
  padding-bottom: 13px !important;
}

.mt-13 {
  margin-top: 13px;
}

.mt-13-i {
  margin-top: 13px !important;
}

.mr-13 {
  margin-right: 13px;
}

.mr-13-i {
  margin-right: 13px !important;
}

.mb-13 {
  margin-bottom: 13px;
}

.mb-13-i {
  margin-bottom: 13px !important;
}

.ml-13 {
  margin-left: 13px;
}

.ml-13-i {
  margin-left: 13px !important;
}

.pt-14 {
  padding-top: 14px;
}

.pt-14-i {
  padding-top: 14px !important;
}

.pr-14 {
  padding-right: 14px;
}

.pr-14-i {
  padding-right: 14px !important;
}

.pl-14 {
  padding-left: 14px;
}

.pl-14-i {
  padding-left: 14px !important;
}

.pb-14 {
  padding-bottom: 14px;
}

.pb-14-i {
  padding-bottom: 14px !important;
}

.mt-14 {
  margin-top: 14px;
}

.mt-14-i {
  margin-top: 14px !important;
}

.mr-14 {
  margin-right: 14px;
}

.mr-14-i {
  margin-right: 14px !important;
}

.mb-14 {
  margin-bottom: 14px;
}

.mb-14-i {
  margin-bottom: 14px !important;
}

.ml-14 {
  margin-left: 14px;
}

.ml-14-i {
  margin-left: 14px !important;
}

.pt-15 {
  padding-top: 15px;
}

.pt-15-i {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px;
}

.pr-15-i {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px;
}

.pl-15-i {
  padding-left: 15px !important;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-15-i {
  padding-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-15-i {
  margin-top: 15px !important;
}

.mr-15 {
  margin-right: 15px;
}

.mr-15-i {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-15-i {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px;
}

.ml-15-i {
  margin-left: 15px !important;
}

.pt-16 {
  padding-top: 16px;
}

.pt-16-i {
  padding-top: 16px !important;
}

.pr-16 {
  padding-right: 16px;
}

.pr-16-i {
  padding-right: 16px !important;
}

.pl-16 {
  padding-left: 16px;
}

.pl-16-i {
  padding-left: 16px !important;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-16-i {
  padding-bottom: 16px !important;
}

.mt-16 {
  margin-top: 16px;
}

.mt-16-i {
  margin-top: 16px !important;
}

.mr-16 {
  margin-right: 16px;
}

.mr-16-i {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-16-i {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px;
}

.ml-16-i {
  margin-left: 16px !important;
}

.pt-17 {
  padding-top: 17px;
}

.pt-17-i {
  padding-top: 17px !important;
}

.pr-17 {
  padding-right: 17px;
}

.pr-17-i {
  padding-right: 17px !important;
}

.pl-17 {
  padding-left: 17px;
}

.pl-17-i {
  padding-left: 17px !important;
}

.pb-17 {
  padding-bottom: 17px;
}

.pb-17-i {
  padding-bottom: 17px !important;
}

.mt-17 {
  margin-top: 17px;
}

.mt-17-i {
  margin-top: 17px !important;
}

.mr-17 {
  margin-right: 17px;
}

.mr-17-i {
  margin-right: 17px !important;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-17-i {
  margin-bottom: 17px !important;
}

.ml-17 {
  margin-left: 17px;
}

.ml-17-i {
  margin-left: 17px !important;
}

.pt-18 {
  padding-top: 18px;
}

.pt-18-i {
  padding-top: 18px !important;
}

.pr-18 {
  padding-right: 18px;
}

.pr-18-i {
  padding-right: 18px !important;
}

.pl-18 {
  padding-left: 18px;
}

.pl-18-i {
  padding-left: 18px !important;
}

.pb-18 {
  padding-bottom: 18px;
}

.pb-18-i {
  padding-bottom: 18px !important;
}

.mt-18 {
  margin-top: 18px;
}

.mt-18-i {
  margin-top: 18px !important;
}

.mr-18 {
  margin-right: 18px;
}

.mr-18-i {
  margin-right: 18px !important;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-18-i {
  margin-bottom: 18px !important;
}

.ml-18 {
  margin-left: 18px;
}

.ml-18-i {
  margin-left: 18px !important;
}

.pt-19 {
  padding-top: 19px;
}

.pt-19-i {
  padding-top: 19px !important;
}

.pr-19 {
  padding-right: 19px;
}

.pr-19-i {
  padding-right: 19px !important;
}

.pl-19 {
  padding-left: 19px;
}

.pl-19-i {
  padding-left: 19px !important;
}

.pb-19 {
  padding-bottom: 19px;
}

.pb-19-i {
  padding-bottom: 19px !important;
}

.mt-19 {
  margin-top: 19px;
}

.mt-19-i {
  margin-top: 19px !important;
}

.mr-19 {
  margin-right: 19px;
}

.mr-19-i {
  margin-right: 19px !important;
}

.mb-19 {
  margin-bottom: 19px;
}

.mb-19-i {
  margin-bottom: 19px !important;
}

.ml-19 {
  margin-left: 19px;
}

.ml-19-i {
  margin-left: 19px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pt-20-i {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px;
}

.pr-20-i {
  padding-right: 20px !important;
}

.pl-20 {
  padding-left: 20px;
}

.pl-20-i {
  padding-left: 20px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-20-i {
  padding-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-20-i {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px;
}

.mr-20-i {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-20-i {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px;
}

.ml-20-i {
  margin-left: 20px !important;
}

.pt-21 {
  padding-top: 21px;
}

.pt-21-i {
  padding-top: 21px !important;
}

.pr-21 {
  padding-right: 21px;
}

.pr-21-i {
  padding-right: 21px !important;
}

.pl-21 {
  padding-left: 21px;
}

.pl-21-i {
  padding-left: 21px !important;
}

.pb-21 {
  padding-bottom: 21px;
}

.pb-21-i {
  padding-bottom: 21px !important;
}

.mt-21 {
  margin-top: 21px;
}

.mt-21-i {
  margin-top: 21px !important;
}

.mr-21 {
  margin-right: 21px;
}

.mr-21-i {
  margin-right: 21px !important;
}

.mb-21 {
  margin-bottom: 21px;
}

.mb-21-i {
  margin-bottom: 21px !important;
}

.ml-21 {
  margin-left: 21px;
}

.ml-21-i {
  margin-left: 21px !important;
}

.pt-22 {
  padding-top: 22px;
}

.pt-22-i {
  padding-top: 22px !important;
}

.pr-22 {
  padding-right: 22px;
}

.pr-22-i {
  padding-right: 22px !important;
}

.pl-22 {
  padding-left: 22px;
}

.pl-22-i {
  padding-left: 22px !important;
}

.pb-22 {
  padding-bottom: 22px;
}

.pb-22-i {
  padding-bottom: 22px !important;
}

.mt-22 {
  margin-top: 22px;
}

.mt-22-i {
  margin-top: 22px !important;
}

.mr-22 {
  margin-right: 22px;
}

.mr-22-i {
  margin-right: 22px !important;
}

.mb-22 {
  margin-bottom: 22px;
}

.mb-22-i {
  margin-bottom: 22px !important;
}

.ml-22 {
  margin-left: 22px;
}

.ml-22-i {
  margin-left: 22px !important;
}

.pt-23 {
  padding-top: 23px;
}

.pt-23-i {
  padding-top: 23px !important;
}

.pr-23 {
  padding-right: 23px;
}

.pr-23-i {
  padding-right: 23px !important;
}

.pl-23 {
  padding-left: 23px;
}

.pl-23-i {
  padding-left: 23px !important;
}

.pb-23 {
  padding-bottom: 23px;
}

.pb-23-i {
  padding-bottom: 23px !important;
}

.mt-23 {
  margin-top: 23px;
}

.mt-23-i {
  margin-top: 23px !important;
}

.mr-23 {
  margin-right: 23px;
}

.mr-23-i {
  margin-right: 23px !important;
}

.mb-23 {
  margin-bottom: 23px;
}

.mb-23-i {
  margin-bottom: 23px !important;
}

.ml-23 {
  margin-left: 23px;
}

.ml-23-i {
  margin-left: 23px !important;
}

.pt-24 {
  padding-top: 24px;
}

.pt-24-i {
  padding-top: 24px !important;
}

.pr-24 {
  padding-right: 24px;
}

.pr-24-i {
  padding-right: 24px !important;
}

.pl-24 {
  padding-left: 24px;
}

.pl-24-i {
  padding-left: 24px !important;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-24-i {
  padding-bottom: 24px !important;
}

.mt-24 {
  margin-top: 24px;
}

.mt-24-i {
  margin-top: 24px !important;
}

.mr-24 {
  margin-right: 24px;
}

.mr-24-i {
  margin-right: 24px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-24-i {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px;
}

.ml-24-i {
  margin-left: 24px !important;
}

.pt-25 {
  padding-top: 25px;
}

.pt-25-i {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px;
}

.pr-25-i {
  padding-right: 25px !important;
}

.pl-25 {
  padding-left: 25px;
}

.pl-25-i {
  padding-left: 25px !important;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-25-i {
  padding-bottom: 25px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-25-i {
  margin-top: 25px !important;
}

.mr-25 {
  margin-right: 25px;
}

.mr-25-i {
  margin-right: 25px !important;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-25-i {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px;
}

.ml-25-i {
  margin-left: 25px !important;
}

.pt-26 {
  padding-top: 26px;
}

.pt-26-i {
  padding-top: 26px !important;
}

.pr-26 {
  padding-right: 26px;
}

.pr-26-i {
  padding-right: 26px !important;
}

.pl-26 {
  padding-left: 26px;
}

.pl-26-i {
  padding-left: 26px !important;
}

.pb-26 {
  padding-bottom: 26px;
}

.pb-26-i {
  padding-bottom: 26px !important;
}

.mt-26 {
  margin-top: 26px;
}

.mt-26-i {
  margin-top: 26px !important;
}

.mr-26 {
  margin-right: 26px;
}

.mr-26-i {
  margin-right: 26px !important;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-26-i {
  margin-bottom: 26px !important;
}

.ml-26 {
  margin-left: 26px;
}

.ml-26-i {
  margin-left: 26px !important;
}

.pt-27 {
  padding-top: 27px;
}

.pt-27-i {
  padding-top: 27px !important;
}

.pr-27 {
  padding-right: 27px;
}

.pr-27-i {
  padding-right: 27px !important;
}

.pl-27 {
  padding-left: 27px;
}

.pl-27-i {
  padding-left: 27px !important;
}

.pb-27 {
  padding-bottom: 27px;
}

.pb-27-i {
  padding-bottom: 27px !important;
}

.mt-27 {
  margin-top: 27px;
}

.mt-27-i {
  margin-top: 27px !important;
}

.mr-27 {
  margin-right: 27px;
}

.mr-27-i {
  margin-right: 27px !important;
}

.mb-27 {
  margin-bottom: 27px;
}

.mb-27-i {
  margin-bottom: 27px !important;
}

.ml-27 {
  margin-left: 27px;
}

.ml-27-i {
  margin-left: 27px !important;
}

.pt-28 {
  padding-top: 28px;
}

.pt-28-i {
  padding-top: 28px !important;
}

.pr-28 {
  padding-right: 28px;
}

.pr-28-i {
  padding-right: 28px !important;
}

.pl-28 {
  padding-left: 28px;
}

.pl-28-i {
  padding-left: 28px !important;
}

.pb-28 {
  padding-bottom: 28px;
}

.pb-28-i {
  padding-bottom: 28px !important;
}

.mt-28 {
  margin-top: 28px;
}

.mt-28-i {
  margin-top: 28px !important;
}

.mr-28 {
  margin-right: 28px;
}

.mr-28-i {
  margin-right: 28px !important;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-28-i {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px;
}

.ml-28-i {
  margin-left: 28px !important;
}

.pt-29 {
  padding-top: 29px;
}

.pt-29-i {
  padding-top: 29px !important;
}

.pr-29 {
  padding-right: 29px;
}

.pr-29-i {
  padding-right: 29px !important;
}

.pl-29 {
  padding-left: 29px;
}

.pl-29-i {
  padding-left: 29px !important;
}

.pb-29 {
  padding-bottom: 29px;
}

.pb-29-i {
  padding-bottom: 29px !important;
}

.mt-29 {
  margin-top: 29px;
}

.mt-29-i {
  margin-top: 29px !important;
}

.mr-29 {
  margin-right: 29px;
}

.mr-29-i {
  margin-right: 29px !important;
}

.mb-29 {
  margin-bottom: 29px;
}

.mb-29-i {
  margin-bottom: 29px !important;
}

.ml-29 {
  margin-left: 29px;
}

.ml-29-i {
  margin-left: 29px !important;
}

.pt-30 {
  padding-top: 30px;
}

.pt-30-i {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px;
}

.pr-30-i {
  padding-right: 30px !important;
}

.pl-30 {
  padding-left: 30px;
}

.pl-30-i {
  padding-left: 30px !important;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-30-i {
  padding-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-30-i {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px;
}

.mr-30-i {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-30-i {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px;
}

.ml-30-i {
  margin-left: 30px !important;
}

.pt-31 {
  padding-top: 31px;
}

.pt-31-i {
  padding-top: 31px !important;
}

.pr-31 {
  padding-right: 31px;
}

.pr-31-i {
  padding-right: 31px !important;
}

.pl-31 {
  padding-left: 31px;
}

.pl-31-i {
  padding-left: 31px !important;
}

.pb-31 {
  padding-bottom: 31px;
}

.pb-31-i {
  padding-bottom: 31px !important;
}

.mt-31 {
  margin-top: 31px;
}

.mt-31-i {
  margin-top: 31px !important;
}

.mr-31 {
  margin-right: 31px;
}

.mr-31-i {
  margin-right: 31px !important;
}

.mb-31 {
  margin-bottom: 31px;
}

.mb-31-i {
  margin-bottom: 31px !important;
}

.ml-31 {
  margin-left: 31px;
}

.ml-31-i {
  margin-left: 31px !important;
}

.pt-32 {
  padding-top: 32px;
}

.pt-32-i {
  padding-top: 32px !important;
}

.pr-32 {
  padding-right: 32px;
}

.pr-32-i {
  padding-right: 32px !important;
}

.pl-32 {
  padding-left: 32px;
}

.pl-32-i {
  padding-left: 32px !important;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-32-i {
  padding-bottom: 32px !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-32-i {
  margin-top: 32px !important;
}

.mr-32 {
  margin-right: 32px;
}

.mr-32-i {
  margin-right: 32px !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-32-i {
  margin-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px;
}

.ml-32-i {
  margin-left: 32px !important;
}

.pt-33 {
  padding-top: 33px;
}

.pt-33-i {
  padding-top: 33px !important;
}

.pr-33 {
  padding-right: 33px;
}

.pr-33-i {
  padding-right: 33px !important;
}

.pl-33 {
  padding-left: 33px;
}

.pl-33-i {
  padding-left: 33px !important;
}

.pb-33 {
  padding-bottom: 33px;
}

.pb-33-i {
  padding-bottom: 33px !important;
}

.mt-33 {
  margin-top: 33px;
}

.mt-33-i {
  margin-top: 33px !important;
}

.mr-33 {
  margin-right: 33px;
}

.mr-33-i {
  margin-right: 33px !important;
}

.mb-33 {
  margin-bottom: 33px;
}

.mb-33-i {
  margin-bottom: 33px !important;
}

.ml-33 {
  margin-left: 33px;
}

.ml-33-i {
  margin-left: 33px !important;
}

.pt-34 {
  padding-top: 34px;
}

.pt-34-i {
  padding-top: 34px !important;
}

.pr-34 {
  padding-right: 34px;
}

.pr-34-i {
  padding-right: 34px !important;
}

.pl-34 {
  padding-left: 34px;
}

.pl-34-i {
  padding-left: 34px !important;
}

.pb-34 {
  padding-bottom: 34px;
}

.pb-34-i {
  padding-bottom: 34px !important;
}

.mt-34 {
  margin-top: 34px;
}

.mt-34-i {
  margin-top: 34px !important;
}

.mr-34 {
  margin-right: 34px;
}

.mr-34-i {
  margin-right: 34px !important;
}

.mb-34 {
  margin-bottom: 34px;
}

.mb-34-i {
  margin-bottom: 34px !important;
}

.ml-34 {
  margin-left: 34px;
}

.ml-34-i {
  margin-left: 34px !important;
}

.pt-35 {
  padding-top: 35px;
}

.pt-35-i {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px;
}

.pr-35-i {
  padding-right: 35px !important;
}

.pl-35 {
  padding-left: 35px;
}

.pl-35-i {
  padding-left: 35px !important;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-35-i {
  padding-bottom: 35px !important;
}

.mt-35 {
  margin-top: 35px;
}

.mt-35-i {
  margin-top: 35px !important;
}

.mr-35 {
  margin-right: 35px;
}

.mr-35-i {
  margin-right: 35px !important;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-35-i {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px;
}

.ml-35-i {
  margin-left: 35px !important;
}

.pt-36 {
  padding-top: 36px;
}

.pt-36-i {
  padding-top: 36px !important;
}

.pr-36 {
  padding-right: 36px;
}

.pr-36-i {
  padding-right: 36px !important;
}

.pl-36 {
  padding-left: 36px;
}

.pl-36-i {
  padding-left: 36px !important;
}

.pb-36 {
  padding-bottom: 36px;
}

.pb-36-i {
  padding-bottom: 36px !important;
}

.mt-36 {
  margin-top: 36px;
}

.mt-36-i {
  margin-top: 36px !important;
}

.mr-36 {
  margin-right: 36px;
}

.mr-36-i {
  margin-right: 36px !important;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-36-i {
  margin-bottom: 36px !important;
}

.ml-36 {
  margin-left: 36px;
}

.ml-36-i {
  margin-left: 36px !important;
}

.pt-37 {
  padding-top: 37px;
}

.pt-37-i {
  padding-top: 37px !important;
}

.pr-37 {
  padding-right: 37px;
}

.pr-37-i {
  padding-right: 37px !important;
}

.pl-37 {
  padding-left: 37px;
}

.pl-37-i {
  padding-left: 37px !important;
}

.pb-37 {
  padding-bottom: 37px;
}

.pb-37-i {
  padding-bottom: 37px !important;
}

.mt-37 {
  margin-top: 37px;
}

.mt-37-i {
  margin-top: 37px !important;
}

.mr-37 {
  margin-right: 37px;
}

.mr-37-i {
  margin-right: 37px !important;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-37-i {
  margin-bottom: 37px !important;
}

.ml-37 {
  margin-left: 37px;
}

.ml-37-i {
  margin-left: 37px !important;
}

.pt-38 {
  padding-top: 38px;
}

.pt-38-i {
  padding-top: 38px !important;
}

.pr-38 {
  padding-right: 38px;
}

.pr-38-i {
  padding-right: 38px !important;
}

.pl-38 {
  padding-left: 38px;
}

.pl-38-i {
  padding-left: 38px !important;
}

.pb-38 {
  padding-bottom: 38px;
}

.pb-38-i {
  padding-bottom: 38px !important;
}

.mt-38 {
  margin-top: 38px;
}

.mt-38-i {
  margin-top: 38px !important;
}

.mr-38 {
  margin-right: 38px;
}

.mr-38-i {
  margin-right: 38px !important;
}

.mb-38 {
  margin-bottom: 38px;
}

.mb-38-i {
  margin-bottom: 38px !important;
}

.ml-38 {
  margin-left: 38px;
}

.ml-38-i {
  margin-left: 38px !important;
}

.pt-39 {
  padding-top: 39px;
}

.pt-39-i {
  padding-top: 39px !important;
}

.pr-39 {
  padding-right: 39px;
}

.pr-39-i {
  padding-right: 39px !important;
}

.pl-39 {
  padding-left: 39px;
}

.pl-39-i {
  padding-left: 39px !important;
}

.pb-39 {
  padding-bottom: 39px;
}

.pb-39-i {
  padding-bottom: 39px !important;
}

.mt-39 {
  margin-top: 39px;
}

.mt-39-i {
  margin-top: 39px !important;
}

.mr-39 {
  margin-right: 39px;
}

.mr-39-i {
  margin-right: 39px !important;
}

.mb-39 {
  margin-bottom: 39px;
}

.mb-39-i {
  margin-bottom: 39px !important;
}

.ml-39 {
  margin-left: 39px;
}

.ml-39-i {
  margin-left: 39px !important;
}

.pt-40 {
  padding-top: 40px;
}

.pt-40-i {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px;
}

.pr-40-i {
  padding-right: 40px !important;
}

.pl-40 {
  padding-left: 40px;
}

.pl-40-i {
  padding-left: 40px !important;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-40-i {
  padding-bottom: 40px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-40-i {
  margin-top: 40px !important;
}

.mr-40 {
  margin-right: 40px;
}

.mr-40-i {
  margin-right: 40px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-40-i {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml-40-i {
  margin-left: 40px !important;
}

.pt-41 {
  padding-top: 41px;
}

.pt-41-i {
  padding-top: 41px !important;
}

.pr-41 {
  padding-right: 41px;
}

.pr-41-i {
  padding-right: 41px !important;
}

.pl-41 {
  padding-left: 41px;
}

.pl-41-i {
  padding-left: 41px !important;
}

.pb-41 {
  padding-bottom: 41px;
}

.pb-41-i {
  padding-bottom: 41px !important;
}

.mt-41 {
  margin-top: 41px;
}

.mt-41-i {
  margin-top: 41px !important;
}

.mr-41 {
  margin-right: 41px;
}

.mr-41-i {
  margin-right: 41px !important;
}

.mb-41 {
  margin-bottom: 41px;
}

.mb-41-i {
  margin-bottom: 41px !important;
}

.ml-41 {
  margin-left: 41px;
}

.ml-41-i {
  margin-left: 41px !important;
}

.pt-42 {
  padding-top: 42px;
}

.pt-42-i {
  padding-top: 42px !important;
}

.pr-42 {
  padding-right: 42px;
}

.pr-42-i {
  padding-right: 42px !important;
}

.pl-42 {
  padding-left: 42px;
}

.pl-42-i {
  padding-left: 42px !important;
}

.pb-42 {
  padding-bottom: 42px;
}

.pb-42-i {
  padding-bottom: 42px !important;
}

.mt-42 {
  margin-top: 42px;
}

.mt-42-i {
  margin-top: 42px !important;
}

.mr-42 {
  margin-right: 42px;
}

.mr-42-i {
  margin-right: 42px !important;
}

.mb-42 {
  margin-bottom: 42px;
}

.mb-42-i {
  margin-bottom: 42px !important;
}

.ml-42 {
  margin-left: 42px;
}

.ml-42-i {
  margin-left: 42px !important;
}

.pt-43 {
  padding-top: 43px;
}

.pt-43-i {
  padding-top: 43px !important;
}

.pr-43 {
  padding-right: 43px;
}

.pr-43-i {
  padding-right: 43px !important;
}

.pl-43 {
  padding-left: 43px;
}

.pl-43-i {
  padding-left: 43px !important;
}

.pb-43 {
  padding-bottom: 43px;
}

.pb-43-i {
  padding-bottom: 43px !important;
}

.mt-43 {
  margin-top: 43px;
}

.mt-43-i {
  margin-top: 43px !important;
}

.mr-43 {
  margin-right: 43px;
}

.mr-43-i {
  margin-right: 43px !important;
}

.mb-43 {
  margin-bottom: 43px;
}

.mb-43-i {
  margin-bottom: 43px !important;
}

.ml-43 {
  margin-left: 43px;
}

.ml-43-i {
  margin-left: 43px !important;
}

.pt-44 {
  padding-top: 44px;
}

.pt-44-i {
  padding-top: 44px !important;
}

.pr-44 {
  padding-right: 44px;
}

.pr-44-i {
  padding-right: 44px !important;
}

.pl-44 {
  padding-left: 44px;
}

.pl-44-i {
  padding-left: 44px !important;
}

.pb-44 {
  padding-bottom: 44px;
}

.pb-44-i {
  padding-bottom: 44px !important;
}

.mt-44 {
  margin-top: 44px;
}

.mt-44-i {
  margin-top: 44px !important;
}

.mr-44 {
  margin-right: 44px;
}

.mr-44-i {
  margin-right: 44px !important;
}

.mb-44 {
  margin-bottom: 44px;
}

.mb-44-i {
  margin-bottom: 44px !important;
}

.ml-44 {
  margin-left: 44px;
}

.ml-44-i {
  margin-left: 44px !important;
}

.pt-45 {
  padding-top: 45px;
}

.pt-45-i {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px;
}

.pr-45-i {
  padding-right: 45px !important;
}

.pl-45 {
  padding-left: 45px;
}

.pl-45-i {
  padding-left: 45px !important;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-45-i {
  padding-bottom: 45px !important;
}

.mt-45 {
  margin-top: 45px;
}

.mt-45-i {
  margin-top: 45px !important;
}

.mr-45 {
  margin-right: 45px;
}

.mr-45-i {
  margin-right: 45px !important;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-45-i {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px;
}

.ml-45-i {
  margin-left: 45px !important;
}

.pt-46 {
  padding-top: 46px;
}

.pt-46-i {
  padding-top: 46px !important;
}

.pr-46 {
  padding-right: 46px;
}

.pr-46-i {
  padding-right: 46px !important;
}

.pl-46 {
  padding-left: 46px;
}

.pl-46-i {
  padding-left: 46px !important;
}

.pb-46 {
  padding-bottom: 46px;
}

.pb-46-i {
  padding-bottom: 46px !important;
}

.mt-46 {
  margin-top: 46px;
}

.mt-46-i {
  margin-top: 46px !important;
}

.mr-46 {
  margin-right: 46px;
}

.mr-46-i {
  margin-right: 46px !important;
}

.mb-46 {
  margin-bottom: 46px;
}

.mb-46-i {
  margin-bottom: 46px !important;
}

.ml-46 {
  margin-left: 46px;
}

.ml-46-i {
  margin-left: 46px !important;
}

.pt-47 {
  padding-top: 47px;
}

.pt-47-i {
  padding-top: 47px !important;
}

.pr-47 {
  padding-right: 47px;
}

.pr-47-i {
  padding-right: 47px !important;
}

.pl-47 {
  padding-left: 47px;
}

.pl-47-i {
  padding-left: 47px !important;
}

.pb-47 {
  padding-bottom: 47px;
}

.pb-47-i {
  padding-bottom: 47px !important;
}

.mt-47 {
  margin-top: 47px;
}

.mt-47-i {
  margin-top: 47px !important;
}

.mr-47 {
  margin-right: 47px;
}

.mr-47-i {
  margin-right: 47px !important;
}

.mb-47 {
  margin-bottom: 47px;
}

.mb-47-i {
  margin-bottom: 47px !important;
}

.ml-47 {
  margin-left: 47px;
}

.ml-47-i {
  margin-left: 47px !important;
}

.pt-48 {
  padding-top: 48px;
}

.pt-48-i {
  padding-top: 48px !important;
}

.pr-48 {
  padding-right: 48px;
}

.pr-48-i {
  padding-right: 48px !important;
}

.pl-48 {
  padding-left: 48px;
}

.pl-48-i {
  padding-left: 48px !important;
}

.pb-48 {
  padding-bottom: 48px;
}

.pb-48-i {
  padding-bottom: 48px !important;
}

.mt-48 {
  margin-top: 48px;
}

.mt-48-i {
  margin-top: 48px !important;
}

.mr-48 {
  margin-right: 48px;
}

.mr-48-i {
  margin-right: 48px !important;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-48-i {
  margin-bottom: 48px !important;
}

.ml-48 {
  margin-left: 48px;
}

.ml-48-i {
  margin-left: 48px !important;
}

.pt-49 {
  padding-top: 49px;
}

.pt-49-i {
  padding-top: 49px !important;
}

.pr-49 {
  padding-right: 49px;
}

.pr-49-i {
  padding-right: 49px !important;
}

.pl-49 {
  padding-left: 49px;
}

.pl-49-i {
  padding-left: 49px !important;
}

.pb-49 {
  padding-bottom: 49px;
}

.pb-49-i {
  padding-bottom: 49px !important;
}

.mt-49 {
  margin-top: 49px;
}

.mt-49-i {
  margin-top: 49px !important;
}

.mr-49 {
  margin-right: 49px;
}

.mr-49-i {
  margin-right: 49px !important;
}

.mb-49 {
  margin-bottom: 49px;
}

.mb-49-i {
  margin-bottom: 49px !important;
}

.ml-49 {
  margin-left: 49px;
}

.ml-49-i {
  margin-left: 49px !important;
}

.pt-50 {
  padding-top: 50px;
}

.pt-50-i {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px;
}

.pr-50-i {
  padding-right: 50px !important;
}

.pl-50 {
  padding-left: 50px;
}

.pl-50-i {
  padding-left: 50px !important;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-50-i {
  padding-bottom: 50px !important;
}

.mt-50 {
  margin-top: 50px;
}

.mt-50-i {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px;
}

.mr-50-i {
  margin-right: 50px !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-50-i {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px;
}

.ml-50-i {
  margin-left: 50px !important;
}

.pt-51 {
  padding-top: 51px;
}

.pt-51-i {
  padding-top: 51px !important;
}

.pr-51 {
  padding-right: 51px;
}

.pr-51-i {
  padding-right: 51px !important;
}

.pl-51 {
  padding-left: 51px;
}

.pl-51-i {
  padding-left: 51px !important;
}

.pb-51 {
  padding-bottom: 51px;
}

.pb-51-i {
  padding-bottom: 51px !important;
}

.mt-51 {
  margin-top: 51px;
}

.mt-51-i {
  margin-top: 51px !important;
}

.mr-51 {
  margin-right: 51px;
}

.mr-51-i {
  margin-right: 51px !important;
}

.mb-51 {
  margin-bottom: 51px;
}

.mb-51-i {
  margin-bottom: 51px !important;
}

.ml-51 {
  margin-left: 51px;
}

.ml-51-i {
  margin-left: 51px !important;
}

.pt-52 {
  padding-top: 52px;
}

.pt-52-i {
  padding-top: 52px !important;
}

.pr-52 {
  padding-right: 52px;
}

.pr-52-i {
  padding-right: 52px !important;
}

.pl-52 {
  padding-left: 52px;
}

.pl-52-i {
  padding-left: 52px !important;
}

.pb-52 {
  padding-bottom: 52px;
}

.pb-52-i {
  padding-bottom: 52px !important;
}

.mt-52 {
  margin-top: 52px;
}

.mt-52-i {
  margin-top: 52px !important;
}

.mr-52 {
  margin-right: 52px;
}

.mr-52-i {
  margin-right: 52px !important;
}

.mb-52 {
  margin-bottom: 52px;
}

.mb-52-i {
  margin-bottom: 52px !important;
}

.ml-52 {
  margin-left: 52px;
}

.ml-52-i {
  margin-left: 52px !important;
}

.pt-53 {
  padding-top: 53px;
}

.pt-53-i {
  padding-top: 53px !important;
}

.pr-53 {
  padding-right: 53px;
}

.pr-53-i {
  padding-right: 53px !important;
}

.pl-53 {
  padding-left: 53px;
}

.pl-53-i {
  padding-left: 53px !important;
}

.pb-53 {
  padding-bottom: 53px;
}

.pb-53-i {
  padding-bottom: 53px !important;
}

.mt-53 {
  margin-top: 53px;
}

.mt-53-i {
  margin-top: 53px !important;
}

.mr-53 {
  margin-right: 53px;
}

.mr-53-i {
  margin-right: 53px !important;
}

.mb-53 {
  margin-bottom: 53px;
}

.mb-53-i {
  margin-bottom: 53px !important;
}

.ml-53 {
  margin-left: 53px;
}

.ml-53-i {
  margin-left: 53px !important;
}

.pt-54 {
  padding-top: 54px;
}

.pt-54-i {
  padding-top: 54px !important;
}

.pr-54 {
  padding-right: 54px;
}

.pr-54-i {
  padding-right: 54px !important;
}

.pl-54 {
  padding-left: 54px;
}

.pl-54-i {
  padding-left: 54px !important;
}

.pb-54 {
  padding-bottom: 54px;
}

.pb-54-i {
  padding-bottom: 54px !important;
}

.mt-54 {
  margin-top: 54px;
}

.mt-54-i {
  margin-top: 54px !important;
}

.mr-54 {
  margin-right: 54px;
}

.mr-54-i {
  margin-right: 54px !important;
}

.mb-54 {
  margin-bottom: 54px;
}

.mb-54-i {
  margin-bottom: 54px !important;
}

.ml-54 {
  margin-left: 54px;
}

.ml-54-i {
  margin-left: 54px !important;
}

.pt-55 {
  padding-top: 55px;
}

.pt-55-i {
  padding-top: 55px !important;
}

.pr-55 {
  padding-right: 55px;
}

.pr-55-i {
  padding-right: 55px !important;
}

.pl-55 {
  padding-left: 55px;
}

.pl-55-i {
  padding-left: 55px !important;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-55-i {
  padding-bottom: 55px !important;
}

.mt-55 {
  margin-top: 55px;
}

.mt-55-i {
  margin-top: 55px !important;
}

.mr-55 {
  margin-right: 55px;
}

.mr-55-i {
  margin-right: 55px !important;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-55-i {
  margin-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px;
}

.ml-55-i {
  margin-left: 55px !important;
}

.pt-56 {
  padding-top: 56px;
}

.pt-56-i {
  padding-top: 56px !important;
}

.pr-56 {
  padding-right: 56px;
}

.pr-56-i {
  padding-right: 56px !important;
}

.pl-56 {
  padding-left: 56px;
}

.pl-56-i {
  padding-left: 56px !important;
}

.pb-56 {
  padding-bottom: 56px;
}

.pb-56-i {
  padding-bottom: 56px !important;
}

.mt-56 {
  margin-top: 56px;
}

.mt-56-i {
  margin-top: 56px !important;
}

.mr-56 {
  margin-right: 56px;
}

.mr-56-i {
  margin-right: 56px !important;
}

.mb-56 {
  margin-bottom: 56px;
}

.mb-56-i {
  margin-bottom: 56px !important;
}

.ml-56 {
  margin-left: 56px;
}

.ml-56-i {
  margin-left: 56px !important;
}

.pt-57 {
  padding-top: 57px;
}

.pt-57-i {
  padding-top: 57px !important;
}

.pr-57 {
  padding-right: 57px;
}

.pr-57-i {
  padding-right: 57px !important;
}

.pl-57 {
  padding-left: 57px;
}

.pl-57-i {
  padding-left: 57px !important;
}

.pb-57 {
  padding-bottom: 57px;
}

.pb-57-i {
  padding-bottom: 57px !important;
}

.mt-57 {
  margin-top: 57px;
}

.mt-57-i {
  margin-top: 57px !important;
}

.mr-57 {
  margin-right: 57px;
}

.mr-57-i {
  margin-right: 57px !important;
}

.mb-57 {
  margin-bottom: 57px;
}

.mb-57-i {
  margin-bottom: 57px !important;
}

.ml-57 {
  margin-left: 57px;
}

.ml-57-i {
  margin-left: 57px !important;
}

.pt-58 {
  padding-top: 58px;
}

.pt-58-i {
  padding-top: 58px !important;
}

.pr-58 {
  padding-right: 58px;
}

.pr-58-i {
  padding-right: 58px !important;
}

.pl-58 {
  padding-left: 58px;
}

.pl-58-i {
  padding-left: 58px !important;
}

.pb-58 {
  padding-bottom: 58px;
}

.pb-58-i {
  padding-bottom: 58px !important;
}

.mt-58 {
  margin-top: 58px;
}

.mt-58-i {
  margin-top: 58px !important;
}

.mr-58 {
  margin-right: 58px;
}

.mr-58-i {
  margin-right: 58px !important;
}

.mb-58 {
  margin-bottom: 58px;
}

.mb-58-i {
  margin-bottom: 58px !important;
}

.ml-58 {
  margin-left: 58px;
}

.ml-58-i {
  margin-left: 58px !important;
}

.pt-59 {
  padding-top: 59px;
}

.pt-59-i {
  padding-top: 59px !important;
}

.pr-59 {
  padding-right: 59px;
}

.pr-59-i {
  padding-right: 59px !important;
}

.pl-59 {
  padding-left: 59px;
}

.pl-59-i {
  padding-left: 59px !important;
}

.pb-59 {
  padding-bottom: 59px;
}

.pb-59-i {
  padding-bottom: 59px !important;
}

.mt-59 {
  margin-top: 59px;
}

.mt-59-i {
  margin-top: 59px !important;
}

.mr-59 {
  margin-right: 59px;
}

.mr-59-i {
  margin-right: 59px !important;
}

.mb-59 {
  margin-bottom: 59px;
}

.mb-59-i {
  margin-bottom: 59px !important;
}

.ml-59 {
  margin-left: 59px;
}

.ml-59-i {
  margin-left: 59px !important;
}

.pt-60 {
  padding-top: 60px;
}

.pt-60-i {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px;
}

.pr-60-i {
  padding-right: 60px !important;
}

.pl-60 {
  padding-left: 60px;
}

.pl-60-i {
  padding-left: 60px !important;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-60-i {
  padding-bottom: 60px !important;
}

.mt-60 {
  margin-top: 60px;
}

.mt-60-i {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px;
}

.mr-60-i {
  margin-right: 60px !important;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-60-i {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px;
}

.ml-60-i {
  margin-left: 60px !important;
}

.pt-61 {
  padding-top: 61px;
}

.pt-61-i {
  padding-top: 61px !important;
}

.pr-61 {
  padding-right: 61px;
}

.pr-61-i {
  padding-right: 61px !important;
}

.pl-61 {
  padding-left: 61px;
}

.pl-61-i {
  padding-left: 61px !important;
}

.pb-61 {
  padding-bottom: 61px;
}

.pb-61-i {
  padding-bottom: 61px !important;
}

.mt-61 {
  margin-top: 61px;
}

.mt-61-i {
  margin-top: 61px !important;
}

.mr-61 {
  margin-right: 61px;
}

.mr-61-i {
  margin-right: 61px !important;
}

.mb-61 {
  margin-bottom: 61px;
}

.mb-61-i {
  margin-bottom: 61px !important;
}

.ml-61 {
  margin-left: 61px;
}

.ml-61-i {
  margin-left: 61px !important;
}

.pt-62 {
  padding-top: 62px;
}

.pt-62-i {
  padding-top: 62px !important;
}

.pr-62 {
  padding-right: 62px;
}

.pr-62-i {
  padding-right: 62px !important;
}

.pl-62 {
  padding-left: 62px;
}

.pl-62-i {
  padding-left: 62px !important;
}

.pb-62 {
  padding-bottom: 62px;
}

.pb-62-i {
  padding-bottom: 62px !important;
}

.mt-62 {
  margin-top: 62px;
}

.mt-62-i {
  margin-top: 62px !important;
}

.mr-62 {
  margin-right: 62px;
}

.mr-62-i {
  margin-right: 62px !important;
}

.mb-62 {
  margin-bottom: 62px;
}

.mb-62-i {
  margin-bottom: 62px !important;
}

.ml-62 {
  margin-left: 62px;
}

.ml-62-i {
  margin-left: 62px !important;
}

.pt-63 {
  padding-top: 63px;
}

.pt-63-i {
  padding-top: 63px !important;
}

.pr-63 {
  padding-right: 63px;
}

.pr-63-i {
  padding-right: 63px !important;
}

.pl-63 {
  padding-left: 63px;
}

.pl-63-i {
  padding-left: 63px !important;
}

.pb-63 {
  padding-bottom: 63px;
}

.pb-63-i {
  padding-bottom: 63px !important;
}

.mt-63 {
  margin-top: 63px;
}

.mt-63-i {
  margin-top: 63px !important;
}

.mr-63 {
  margin-right: 63px;
}

.mr-63-i {
  margin-right: 63px !important;
}

.mb-63 {
  margin-bottom: 63px;
}

.mb-63-i {
  margin-bottom: 63px !important;
}

.ml-63 {
  margin-left: 63px;
}

.ml-63-i {
  margin-left: 63px !important;
}

.pt-64 {
  padding-top: 64px;
}

.pt-64-i {
  padding-top: 64px !important;
}

.pr-64 {
  padding-right: 64px;
}

.pr-64-i {
  padding-right: 64px !important;
}

.pl-64 {
  padding-left: 64px;
}

.pl-64-i {
  padding-left: 64px !important;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-64-i {
  padding-bottom: 64px !important;
}

.mt-64 {
  margin-top: 64px;
}

.mt-64-i {
  margin-top: 64px !important;
}

.mr-64 {
  margin-right: 64px;
}

.mr-64-i {
  margin-right: 64px !important;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-64-i {
  margin-bottom: 64px !important;
}

.ml-64 {
  margin-left: 64px;
}

.ml-64-i {
  margin-left: 64px !important;
}

.pt-65 {
  padding-top: 65px;
}

.pt-65-i {
  padding-top: 65px !important;
}

.pr-65 {
  padding-right: 65px;
}

.pr-65-i {
  padding-right: 65px !important;
}

.pl-65 {
  padding-left: 65px;
}

.pl-65-i {
  padding-left: 65px !important;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-65-i {
  padding-bottom: 65px !important;
}

.mt-65 {
  margin-top: 65px;
}

.mt-65-i {
  margin-top: 65px !important;
}

.mr-65 {
  margin-right: 65px;
}

.mr-65-i {
  margin-right: 65px !important;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-65-i {
  margin-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px;
}

.ml-65-i {
  margin-left: 65px !important;
}

.pt-66 {
  padding-top: 66px;
}

.pt-66-i {
  padding-top: 66px !important;
}

.pr-66 {
  padding-right: 66px;
}

.pr-66-i {
  padding-right: 66px !important;
}

.pl-66 {
  padding-left: 66px;
}

.pl-66-i {
  padding-left: 66px !important;
}

.pb-66 {
  padding-bottom: 66px;
}

.pb-66-i {
  padding-bottom: 66px !important;
}

.mt-66 {
  margin-top: 66px;
}

.mt-66-i {
  margin-top: 66px !important;
}

.mr-66 {
  margin-right: 66px;
}

.mr-66-i {
  margin-right: 66px !important;
}

.mb-66 {
  margin-bottom: 66px;
}

.mb-66-i {
  margin-bottom: 66px !important;
}

.ml-66 {
  margin-left: 66px;
}

.ml-66-i {
  margin-left: 66px !important;
}

.pt-67 {
  padding-top: 67px;
}

.pt-67-i {
  padding-top: 67px !important;
}

.pr-67 {
  padding-right: 67px;
}

.pr-67-i {
  padding-right: 67px !important;
}

.pl-67 {
  padding-left: 67px;
}

.pl-67-i {
  padding-left: 67px !important;
}

.pb-67 {
  padding-bottom: 67px;
}

.pb-67-i {
  padding-bottom: 67px !important;
}

.mt-67 {
  margin-top: 67px;
}

.mt-67-i {
  margin-top: 67px !important;
}

.mr-67 {
  margin-right: 67px;
}

.mr-67-i {
  margin-right: 67px !important;
}

.mb-67 {
  margin-bottom: 67px;
}

.mb-67-i {
  margin-bottom: 67px !important;
}

.ml-67 {
  margin-left: 67px;
}

.ml-67-i {
  margin-left: 67px !important;
}

.pt-68 {
  padding-top: 68px;
}

.pt-68-i {
  padding-top: 68px !important;
}

.pr-68 {
  padding-right: 68px;
}

.pr-68-i {
  padding-right: 68px !important;
}

.pl-68 {
  padding-left: 68px;
}

.pl-68-i {
  padding-left: 68px !important;
}

.pb-68 {
  padding-bottom: 68px;
}

.pb-68-i {
  padding-bottom: 68px !important;
}

.mt-68 {
  margin-top: 68px;
}

.mt-68-i {
  margin-top: 68px !important;
}

.mr-68 {
  margin-right: 68px;
}

.mr-68-i {
  margin-right: 68px !important;
}

.mb-68 {
  margin-bottom: 68px;
}

.mb-68-i {
  margin-bottom: 68px !important;
}

.ml-68 {
  margin-left: 68px;
}

.ml-68-i {
  margin-left: 68px !important;
}

.pt-69 {
  padding-top: 69px;
}

.pt-69-i {
  padding-top: 69px !important;
}

.pr-69 {
  padding-right: 69px;
}

.pr-69-i {
  padding-right: 69px !important;
}

.pl-69 {
  padding-left: 69px;
}

.pl-69-i {
  padding-left: 69px !important;
}

.pb-69 {
  padding-bottom: 69px;
}

.pb-69-i {
  padding-bottom: 69px !important;
}

.mt-69 {
  margin-top: 69px;
}

.mt-69-i {
  margin-top: 69px !important;
}

.mr-69 {
  margin-right: 69px;
}

.mr-69-i {
  margin-right: 69px !important;
}

.mb-69 {
  margin-bottom: 69px;
}

.mb-69-i {
  margin-bottom: 69px !important;
}

.ml-69 {
  margin-left: 69px;
}

.ml-69-i {
  margin-left: 69px !important;
}

.pt-70 {
  padding-top: 70px;
}

.pt-70-i {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px;
}

.pr-70-i {
  padding-right: 70px !important;
}

.pl-70 {
  padding-left: 70px;
}

.pl-70-i {
  padding-left: 70px !important;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-70-i {
  padding-bottom: 70px !important;
}

.mt-70 {
  margin-top: 70px;
}

.mt-70-i {
  margin-top: 70px !important;
}

.mr-70 {
  margin-right: 70px;
}

.mr-70-i {
  margin-right: 70px !important;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-70-i {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px;
}

.ml-70-i {
  margin-left: 70px !important;
}

.pt-71 {
  padding-top: 71px;
}

.pt-71-i {
  padding-top: 71px !important;
}

.pr-71 {
  padding-right: 71px;
}

.pr-71-i {
  padding-right: 71px !important;
}

.pl-71 {
  padding-left: 71px;
}

.pl-71-i {
  padding-left: 71px !important;
}

.pb-71 {
  padding-bottom: 71px;
}

.pb-71-i {
  padding-bottom: 71px !important;
}

.mt-71 {
  margin-top: 71px;
}

.mt-71-i {
  margin-top: 71px !important;
}

.mr-71 {
  margin-right: 71px;
}

.mr-71-i {
  margin-right: 71px !important;
}

.mb-71 {
  margin-bottom: 71px;
}

.mb-71-i {
  margin-bottom: 71px !important;
}

.ml-71 {
  margin-left: 71px;
}

.ml-71-i {
  margin-left: 71px !important;
}

.pt-72 {
  padding-top: 72px;
}

.pt-72-i {
  padding-top: 72px !important;
}

.pr-72 {
  padding-right: 72px;
}

.pr-72-i {
  padding-right: 72px !important;
}

.pl-72 {
  padding-left: 72px;
}

.pl-72-i {
  padding-left: 72px !important;
}

.pb-72 {
  padding-bottom: 72px;
}

.pb-72-i {
  padding-bottom: 72px !important;
}

.mt-72 {
  margin-top: 72px;
}

.mt-72-i {
  margin-top: 72px !important;
}

.mr-72 {
  margin-right: 72px;
}

.mr-72-i {
  margin-right: 72px !important;
}

.mb-72 {
  margin-bottom: 72px;
}

.mb-72-i {
  margin-bottom: 72px !important;
}

.ml-72 {
  margin-left: 72px;
}

.ml-72-i {
  margin-left: 72px !important;
}

.pt-73 {
  padding-top: 73px;
}

.pt-73-i {
  padding-top: 73px !important;
}

.pr-73 {
  padding-right: 73px;
}

.pr-73-i {
  padding-right: 73px !important;
}

.pl-73 {
  padding-left: 73px;
}

.pl-73-i {
  padding-left: 73px !important;
}

.pb-73 {
  padding-bottom: 73px;
}

.pb-73-i {
  padding-bottom: 73px !important;
}

.mt-73 {
  margin-top: 73px;
}

.mt-73-i {
  margin-top: 73px !important;
}

.mr-73 {
  margin-right: 73px;
}

.mr-73-i {
  margin-right: 73px !important;
}

.mb-73 {
  margin-bottom: 73px;
}

.mb-73-i {
  margin-bottom: 73px !important;
}

.ml-73 {
  margin-left: 73px;
}

.ml-73-i {
  margin-left: 73px !important;
}

.pt-74 {
  padding-top: 74px;
}

.pt-74-i {
  padding-top: 74px !important;
}

.pr-74 {
  padding-right: 74px;
}

.pr-74-i {
  padding-right: 74px !important;
}

.pl-74 {
  padding-left: 74px;
}

.pl-74-i {
  padding-left: 74px !important;
}

.pb-74 {
  padding-bottom: 74px;
}

.pb-74-i {
  padding-bottom: 74px !important;
}

.mt-74 {
  margin-top: 74px;
}

.mt-74-i {
  margin-top: 74px !important;
}

.mr-74 {
  margin-right: 74px;
}

.mr-74-i {
  margin-right: 74px !important;
}

.mb-74 {
  margin-bottom: 74px;
}

.mb-74-i {
  margin-bottom: 74px !important;
}

.ml-74 {
  margin-left: 74px;
}

.ml-74-i {
  margin-left: 74px !important;
}

.pt-75 {
  padding-top: 75px;
}

.pt-75-i {
  padding-top: 75px !important;
}

.pr-75 {
  padding-right: 75px;
}

.pr-75-i {
  padding-right: 75px !important;
}

.pl-75 {
  padding-left: 75px;
}

.pl-75-i {
  padding-left: 75px !important;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-75-i {
  padding-bottom: 75px !important;
}

.mt-75 {
  margin-top: 75px;
}

.mt-75-i {
  margin-top: 75px !important;
}

.mr-75 {
  margin-right: 75px;
}

.mr-75-i {
  margin-right: 75px !important;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-75-i {
  margin-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px;
}

.ml-75-i {
  margin-left: 75px !important;
}

.pt-76 {
  padding-top: 76px;
}

.pt-76-i {
  padding-top: 76px !important;
}

.pr-76 {
  padding-right: 76px;
}

.pr-76-i {
  padding-right: 76px !important;
}

.pl-76 {
  padding-left: 76px;
}

.pl-76-i {
  padding-left: 76px !important;
}

.pb-76 {
  padding-bottom: 76px;
}

.pb-76-i {
  padding-bottom: 76px !important;
}

.mt-76 {
  margin-top: 76px;
}

.mt-76-i {
  margin-top: 76px !important;
}

.mr-76 {
  margin-right: 76px;
}

.mr-76-i {
  margin-right: 76px !important;
}

.mb-76 {
  margin-bottom: 76px;
}

.mb-76-i {
  margin-bottom: 76px !important;
}

.ml-76 {
  margin-left: 76px;
}

.ml-76-i {
  margin-left: 76px !important;
}

.pt-77 {
  padding-top: 77px;
}

.pt-77-i {
  padding-top: 77px !important;
}

.pr-77 {
  padding-right: 77px;
}

.pr-77-i {
  padding-right: 77px !important;
}

.pl-77 {
  padding-left: 77px;
}

.pl-77-i {
  padding-left: 77px !important;
}

.pb-77 {
  padding-bottom: 77px;
}

.pb-77-i {
  padding-bottom: 77px !important;
}

.mt-77 {
  margin-top: 77px;
}

.mt-77-i {
  margin-top: 77px !important;
}

.mr-77 {
  margin-right: 77px;
}

.mr-77-i {
  margin-right: 77px !important;
}

.mb-77 {
  margin-bottom: 77px;
}

.mb-77-i {
  margin-bottom: 77px !important;
}

.ml-77 {
  margin-left: 77px;
}

.ml-77-i {
  margin-left: 77px !important;
}

.pt-78 {
  padding-top: 78px;
}

.pt-78-i {
  padding-top: 78px !important;
}

.pr-78 {
  padding-right: 78px;
}

.pr-78-i {
  padding-right: 78px !important;
}

.pl-78 {
  padding-left: 78px;
}

.pl-78-i {
  padding-left: 78px !important;
}

.pb-78 {
  padding-bottom: 78px;
}

.pb-78-i {
  padding-bottom: 78px !important;
}

.mt-78 {
  margin-top: 78px;
}

.mt-78-i {
  margin-top: 78px !important;
}

.mr-78 {
  margin-right: 78px;
}

.mr-78-i {
  margin-right: 78px !important;
}

.mb-78 {
  margin-bottom: 78px;
}

.mb-78-i {
  margin-bottom: 78px !important;
}

.ml-78 {
  margin-left: 78px;
}

.ml-78-i {
  margin-left: 78px !important;
}

.pt-79 {
  padding-top: 79px;
}

.pt-79-i {
  padding-top: 79px !important;
}

.pr-79 {
  padding-right: 79px;
}

.pr-79-i {
  padding-right: 79px !important;
}

.pl-79 {
  padding-left: 79px;
}

.pl-79-i {
  padding-left: 79px !important;
}

.pb-79 {
  padding-bottom: 79px;
}

.pb-79-i {
  padding-bottom: 79px !important;
}

.mt-79 {
  margin-top: 79px;
}

.mt-79-i {
  margin-top: 79px !important;
}

.mr-79 {
  margin-right: 79px;
}

.mr-79-i {
  margin-right: 79px !important;
}

.mb-79 {
  margin-bottom: 79px;
}

.mb-79-i {
  margin-bottom: 79px !important;
}

.ml-79 {
  margin-left: 79px;
}

.ml-79-i {
  margin-left: 79px !important;
}

.pt-80 {
  padding-top: 80px;
}

.pt-80-i {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px;
}

.pr-80-i {
  padding-right: 80px !important;
}

.pl-80 {
  padding-left: 80px;
}

.pl-80-i {
  padding-left: 80px !important;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-80-i {
  padding-bottom: 80px !important;
}

.mt-80 {
  margin-top: 80px;
}

.mt-80-i {
  margin-top: 80px !important;
}

.mr-80 {
  margin-right: 80px;
}

.mr-80-i {
  margin-right: 80px !important;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-80-i {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px;
}

.ml-80-i {
  margin-left: 80px !important;
}

.pt-81 {
  padding-top: 81px;
}

.pt-81-i {
  padding-top: 81px !important;
}

.pr-81 {
  padding-right: 81px;
}

.pr-81-i {
  padding-right: 81px !important;
}

.pl-81 {
  padding-left: 81px;
}

.pl-81-i {
  padding-left: 81px !important;
}

.pb-81 {
  padding-bottom: 81px;
}

.pb-81-i {
  padding-bottom: 81px !important;
}

.mt-81 {
  margin-top: 81px;
}

.mt-81-i {
  margin-top: 81px !important;
}

.mr-81 {
  margin-right: 81px;
}

.mr-81-i {
  margin-right: 81px !important;
}

.mb-81 {
  margin-bottom: 81px;
}

.mb-81-i {
  margin-bottom: 81px !important;
}

.ml-81 {
  margin-left: 81px;
}

.ml-81-i {
  margin-left: 81px !important;
}

.pt-82 {
  padding-top: 82px;
}

.pt-82-i {
  padding-top: 82px !important;
}

.pr-82 {
  padding-right: 82px;
}

.pr-82-i {
  padding-right: 82px !important;
}

.pl-82 {
  padding-left: 82px;
}

.pl-82-i {
  padding-left: 82px !important;
}

.pb-82 {
  padding-bottom: 82px;
}

.pb-82-i {
  padding-bottom: 82px !important;
}

.mt-82 {
  margin-top: 82px;
}

.mt-82-i {
  margin-top: 82px !important;
}

.mr-82 {
  margin-right: 82px;
}

.mr-82-i {
  margin-right: 82px !important;
}

.mb-82 {
  margin-bottom: 82px;
}

.mb-82-i {
  margin-bottom: 82px !important;
}

.ml-82 {
  margin-left: 82px;
}

.ml-82-i {
  margin-left: 82px !important;
}

.pt-83 {
  padding-top: 83px;
}

.pt-83-i {
  padding-top: 83px !important;
}

.pr-83 {
  padding-right: 83px;
}

.pr-83-i {
  padding-right: 83px !important;
}

.pl-83 {
  padding-left: 83px;
}

.pl-83-i {
  padding-left: 83px !important;
}

.pb-83 {
  padding-bottom: 83px;
}

.pb-83-i {
  padding-bottom: 83px !important;
}

.mt-83 {
  margin-top: 83px;
}

.mt-83-i {
  margin-top: 83px !important;
}

.mr-83 {
  margin-right: 83px;
}

.mr-83-i {
  margin-right: 83px !important;
}

.mb-83 {
  margin-bottom: 83px;
}

.mb-83-i {
  margin-bottom: 83px !important;
}

.ml-83 {
  margin-left: 83px;
}

.ml-83-i {
  margin-left: 83px !important;
}

.pt-84 {
  padding-top: 84px;
}

.pt-84-i {
  padding-top: 84px !important;
}

.pr-84 {
  padding-right: 84px;
}

.pr-84-i {
  padding-right: 84px !important;
}

.pl-84 {
  padding-left: 84px;
}

.pl-84-i {
  padding-left: 84px !important;
}

.pb-84 {
  padding-bottom: 84px;
}

.pb-84-i {
  padding-bottom: 84px !important;
}

.mt-84 {
  margin-top: 84px;
}

.mt-84-i {
  margin-top: 84px !important;
}

.mr-84 {
  margin-right: 84px;
}

.mr-84-i {
  margin-right: 84px !important;
}

.mb-84 {
  margin-bottom: 84px;
}

.mb-84-i {
  margin-bottom: 84px !important;
}

.ml-84 {
  margin-left: 84px;
}

.ml-84-i {
  margin-left: 84px !important;
}

.pt-85 {
  padding-top: 85px;
}

.pt-85-i {
  padding-top: 85px !important;
}

.pr-85 {
  padding-right: 85px;
}

.pr-85-i {
  padding-right: 85px !important;
}

.pl-85 {
  padding-left: 85px;
}

.pl-85-i {
  padding-left: 85px !important;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-85-i {
  padding-bottom: 85px !important;
}

.mt-85 {
  margin-top: 85px;
}

.mt-85-i {
  margin-top: 85px !important;
}

.mr-85 {
  margin-right: 85px;
}

.mr-85-i {
  margin-right: 85px !important;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-85-i {
  margin-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px;
}

.ml-85-i {
  margin-left: 85px !important;
}

.pt-86 {
  padding-top: 86px;
}

.pt-86-i {
  padding-top: 86px !important;
}

.pr-86 {
  padding-right: 86px;
}

.pr-86-i {
  padding-right: 86px !important;
}

.pl-86 {
  padding-left: 86px;
}

.pl-86-i {
  padding-left: 86px !important;
}

.pb-86 {
  padding-bottom: 86px;
}

.pb-86-i {
  padding-bottom: 86px !important;
}

.mt-86 {
  margin-top: 86px;
}

.mt-86-i {
  margin-top: 86px !important;
}

.mr-86 {
  margin-right: 86px;
}

.mr-86-i {
  margin-right: 86px !important;
}

.mb-86 {
  margin-bottom: 86px;
}

.mb-86-i {
  margin-bottom: 86px !important;
}

.ml-86 {
  margin-left: 86px;
}

.ml-86-i {
  margin-left: 86px !important;
}

.pt-87 {
  padding-top: 87px;
}

.pt-87-i {
  padding-top: 87px !important;
}

.pr-87 {
  padding-right: 87px;
}

.pr-87-i {
  padding-right: 87px !important;
}

.pl-87 {
  padding-left: 87px;
}

.pl-87-i {
  padding-left: 87px !important;
}

.pb-87 {
  padding-bottom: 87px;
}

.pb-87-i {
  padding-bottom: 87px !important;
}

.mt-87 {
  margin-top: 87px;
}

.mt-87-i {
  margin-top: 87px !important;
}

.mr-87 {
  margin-right: 87px;
}

.mr-87-i {
  margin-right: 87px !important;
}

.mb-87 {
  margin-bottom: 87px;
}

.mb-87-i {
  margin-bottom: 87px !important;
}

.ml-87 {
  margin-left: 87px;
}

.ml-87-i {
  margin-left: 87px !important;
}

.pt-88 {
  padding-top: 88px;
}

.pt-88-i {
  padding-top: 88px !important;
}

.pr-88 {
  padding-right: 88px;
}

.pr-88-i {
  padding-right: 88px !important;
}

.pl-88 {
  padding-left: 88px;
}

.pl-88-i {
  padding-left: 88px !important;
}

.pb-88 {
  padding-bottom: 88px;
}

.pb-88-i {
  padding-bottom: 88px !important;
}

.mt-88 {
  margin-top: 88px;
}

.mt-88-i {
  margin-top: 88px !important;
}

.mr-88 {
  margin-right: 88px;
}

.mr-88-i {
  margin-right: 88px !important;
}

.mb-88 {
  margin-bottom: 88px;
}

.mb-88-i {
  margin-bottom: 88px !important;
}

.ml-88 {
  margin-left: 88px;
}

.ml-88-i {
  margin-left: 88px !important;
}

.pt-89 {
  padding-top: 89px;
}

.pt-89-i {
  padding-top: 89px !important;
}

.pr-89 {
  padding-right: 89px;
}

.pr-89-i {
  padding-right: 89px !important;
}

.pl-89 {
  padding-left: 89px;
}

.pl-89-i {
  padding-left: 89px !important;
}

.pb-89 {
  padding-bottom: 89px;
}

.pb-89-i {
  padding-bottom: 89px !important;
}

.mt-89 {
  margin-top: 89px;
}

.mt-89-i {
  margin-top: 89px !important;
}

.mr-89 {
  margin-right: 89px;
}

.mr-89-i {
  margin-right: 89px !important;
}

.mb-89 {
  margin-bottom: 89px;
}

.mb-89-i {
  margin-bottom: 89px !important;
}

.ml-89 {
  margin-left: 89px;
}

.ml-89-i {
  margin-left: 89px !important;
}

.pt-90 {
  padding-top: 90px;
}

.pt-90-i {
  padding-top: 90px !important;
}

.pr-90 {
  padding-right: 90px;
}

.pr-90-i {
  padding-right: 90px !important;
}

.pl-90 {
  padding-left: 90px;
}

.pl-90-i {
  padding-left: 90px !important;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-90-i {
  padding-bottom: 90px !important;
}

.mt-90 {
  margin-top: 90px;
}

.mt-90-i {
  margin-top: 90px !important;
}

.mr-90 {
  margin-right: 90px;
}

.mr-90-i {
  margin-right: 90px !important;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-90-i {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px;
}

.ml-90-i {
  margin-left: 90px !important;
}

.pt-91 {
  padding-top: 91px;
}

.pt-91-i {
  padding-top: 91px !important;
}

.pr-91 {
  padding-right: 91px;
}

.pr-91-i {
  padding-right: 91px !important;
}

.pl-91 {
  padding-left: 91px;
}

.pl-91-i {
  padding-left: 91px !important;
}

.pb-91 {
  padding-bottom: 91px;
}

.pb-91-i {
  padding-bottom: 91px !important;
}

.mt-91 {
  margin-top: 91px;
}

.mt-91-i {
  margin-top: 91px !important;
}

.mr-91 {
  margin-right: 91px;
}

.mr-91-i {
  margin-right: 91px !important;
}

.mb-91 {
  margin-bottom: 91px;
}

.mb-91-i {
  margin-bottom: 91px !important;
}

.ml-91 {
  margin-left: 91px;
}

.ml-91-i {
  margin-left: 91px !important;
}

.pt-92 {
  padding-top: 92px;
}

.pt-92-i {
  padding-top: 92px !important;
}

.pr-92 {
  padding-right: 92px;
}

.pr-92-i {
  padding-right: 92px !important;
}

.pl-92 {
  padding-left: 92px;
}

.pl-92-i {
  padding-left: 92px !important;
}

.pb-92 {
  padding-bottom: 92px;
}

.pb-92-i {
  padding-bottom: 92px !important;
}

.mt-92 {
  margin-top: 92px;
}

.mt-92-i {
  margin-top: 92px !important;
}

.mr-92 {
  margin-right: 92px;
}

.mr-92-i {
  margin-right: 92px !important;
}

.mb-92 {
  margin-bottom: 92px;
}

.mb-92-i {
  margin-bottom: 92px !important;
}

.ml-92 {
  margin-left: 92px;
}

.ml-92-i {
  margin-left: 92px !important;
}

.pt-93 {
  padding-top: 93px;
}

.pt-93-i {
  padding-top: 93px !important;
}

.pr-93 {
  padding-right: 93px;
}

.pr-93-i {
  padding-right: 93px !important;
}

.pl-93 {
  padding-left: 93px;
}

.pl-93-i {
  padding-left: 93px !important;
}

.pb-93 {
  padding-bottom: 93px;
}

.pb-93-i {
  padding-bottom: 93px !important;
}

.mt-93 {
  margin-top: 93px;
}

.mt-93-i {
  margin-top: 93px !important;
}

.mr-93 {
  margin-right: 93px;
}

.mr-93-i {
  margin-right: 93px !important;
}

.mb-93 {
  margin-bottom: 93px;
}

.mb-93-i {
  margin-bottom: 93px !important;
}

.ml-93 {
  margin-left: 93px;
}

.ml-93-i {
  margin-left: 93px !important;
}

.pt-94 {
  padding-top: 94px;
}

.pt-94-i {
  padding-top: 94px !important;
}

.pr-94 {
  padding-right: 94px;
}

.pr-94-i {
  padding-right: 94px !important;
}

.pl-94 {
  padding-left: 94px;
}

.pl-94-i {
  padding-left: 94px !important;
}

.pb-94 {
  padding-bottom: 94px;
}

.pb-94-i {
  padding-bottom: 94px !important;
}

.mt-94 {
  margin-top: 94px;
}

.mt-94-i {
  margin-top: 94px !important;
}

.mr-94 {
  margin-right: 94px;
}

.mr-94-i {
  margin-right: 94px !important;
}

.mb-94 {
  margin-bottom: 94px;
}

.mb-94-i {
  margin-bottom: 94px !important;
}

.ml-94 {
  margin-left: 94px;
}

.ml-94-i {
  margin-left: 94px !important;
}

.pt-95 {
  padding-top: 95px;
}

.pt-95-i {
  padding-top: 95px !important;
}

.pr-95 {
  padding-right: 95px;
}

.pr-95-i {
  padding-right: 95px !important;
}

.pl-95 {
  padding-left: 95px;
}

.pl-95-i {
  padding-left: 95px !important;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-95-i {
  padding-bottom: 95px !important;
}

.mt-95 {
  margin-top: 95px;
}

.mt-95-i {
  margin-top: 95px !important;
}

.mr-95 {
  margin-right: 95px;
}

.mr-95-i {
  margin-right: 95px !important;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-95-i {
  margin-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px;
}

.ml-95-i {
  margin-left: 95px !important;
}

.pt-96 {
  padding-top: 96px;
}

.pt-96-i {
  padding-top: 96px !important;
}

.pr-96 {
  padding-right: 96px;
}

.pr-96-i {
  padding-right: 96px !important;
}

.pl-96 {
  padding-left: 96px;
}

.pl-96-i {
  padding-left: 96px !important;
}

.pb-96 {
  padding-bottom: 96px;
}

.pb-96-i {
  padding-bottom: 96px !important;
}

.mt-96 {
  margin-top: 96px;
}

.mt-96-i {
  margin-top: 96px !important;
}

.mr-96 {
  margin-right: 96px;
}

.mr-96-i {
  margin-right: 96px !important;
}

.mb-96 {
  margin-bottom: 96px;
}

.mb-96-i {
  margin-bottom: 96px !important;
}

.ml-96 {
  margin-left: 96px;
}

.ml-96-i {
  margin-left: 96px !important;
}

.pt-97 {
  padding-top: 97px;
}

.pt-97-i {
  padding-top: 97px !important;
}

.pr-97 {
  padding-right: 97px;
}

.pr-97-i {
  padding-right: 97px !important;
}

.pl-97 {
  padding-left: 97px;
}

.pl-97-i {
  padding-left: 97px !important;
}

.pb-97 {
  padding-bottom: 97px;
}

.pb-97-i {
  padding-bottom: 97px !important;
}

.mt-97 {
  margin-top: 97px;
}

.mt-97-i {
  margin-top: 97px !important;
}

.mr-97 {
  margin-right: 97px;
}

.mr-97-i {
  margin-right: 97px !important;
}

.mb-97 {
  margin-bottom: 97px;
}

.mb-97-i {
  margin-bottom: 97px !important;
}

.ml-97 {
  margin-left: 97px;
}

.ml-97-i {
  margin-left: 97px !important;
}

.pt-98 {
  padding-top: 98px;
}

.pt-98-i {
  padding-top: 98px !important;
}

.pr-98 {
  padding-right: 98px;
}

.pr-98-i {
  padding-right: 98px !important;
}

.pl-98 {
  padding-left: 98px;
}

.pl-98-i {
  padding-left: 98px !important;
}

.pb-98 {
  padding-bottom: 98px;
}

.pb-98-i {
  padding-bottom: 98px !important;
}

.mt-98 {
  margin-top: 98px;
}

.mt-98-i {
  margin-top: 98px !important;
}

.mr-98 {
  margin-right: 98px;
}

.mr-98-i {
  margin-right: 98px !important;
}

.mb-98 {
  margin-bottom: 98px;
}

.mb-98-i {
  margin-bottom: 98px !important;
}

.ml-98 {
  margin-left: 98px;
}

.ml-98-i {
  margin-left: 98px !important;
}

.pt-99 {
  padding-top: 99px;
}

.pt-99-i {
  padding-top: 99px !important;
}

.pr-99 {
  padding-right: 99px;
}

.pr-99-i {
  padding-right: 99px !important;
}

.pl-99 {
  padding-left: 99px;
}

.pl-99-i {
  padding-left: 99px !important;
}

.pb-99 {
  padding-bottom: 99px;
}

.pb-99-i {
  padding-bottom: 99px !important;
}

.mt-99 {
  margin-top: 99px;
}

.mt-99-i {
  margin-top: 99px !important;
}

.mr-99 {
  margin-right: 99px;
}

.mr-99-i {
  margin-right: 99px !important;
}

.mb-99 {
  margin-bottom: 99px;
}

.mb-99-i {
  margin-bottom: 99px !important;
}

.ml-99 {
  margin-left: 99px;
}

.ml-99-i {
  margin-left: 99px !important;
}

.pt-100 {
  padding-top: 100px;
}

.pt-100-i {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px;
}

.pr-100-i {
  padding-right: 100px !important;
}

.pl-100 {
  padding-left: 100px;
}

.pl-100-i {
  padding-left: 100px !important;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-100-i {
  padding-bottom: 100px !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-100-i {
  margin-top: 100px !important;
}

.mr-100 {
  margin-right: 100px;
}

.mr-100-i {
  margin-right: 100px !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-100-i {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px;
}

.ml-100-i {
  margin-left: 100px !important;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/*
 * Application global variables.
 */
/* stylelint-disable */
/* stylelint-enable */
/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
html {
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: rgb(var(--primary-text-color));
}

* {
  text-size-adjust: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  height: 100%;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}