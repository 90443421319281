@import "theme-variables";
@import "breakpoints";
@import "mixins";

$tr-border-color: #e4e4e4;

.drag-drop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  em {
    color: $primary;
    cursor: move;
  }

  &-order {
    width: 1.5rem;
    line-height: 1.5rem;
    border-radius: 50%;
    text-align: center;
    background-color: $primary;
    color: $white;
    margin-right: 1rem;
    font-size: 0.875rem;
    border: 1px solid $white;
    box-sizing: content-box;
  }
}

.cdk-drag-preview {
  display: table;
  vertical-align: middle;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  background-color: $primary;
  color: $secondary-text-color;
  opacity: 0.8;

  & > td {
    .p-column-title {
      display: none;
      color: $secondary-text-color !important;
    }

    .p-column-title,
    .url > a,
    .drag-drop em {
      color: $secondary-text-color !important;
    }

    /* stylelint-disable no-descending-specificity */
    &.stacked {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: unset !important;

      .p-column-title {
        display: block;
      }
    }
  }
}

.cdk-drag-preview,
app-table .p-datatable .p-datatable-tbody > tr {
  &:last-child {
    border-bottom: 0;
  }

  & > td {
    padding: 0.75rem;

    .p-column-title {
      width: 16rem;
      color: $primary;
      padding-right: 0.2rem;

      @include respond-to(lower-than-small-screens) {
        width: 10rem;
      }
    }

    .default {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
      word-break: break-word;

      span {
        outline: 0;
      }
    }

    &.actions {
      justify-content: flex-end !important;
    }
  }
}

app-table {
  .p-datatable {
    height: 100%;
    display: flex;
    flex-direction: column;

    .p-datatable-thead {
      top: 0;
      z-index: 2;
      font-size: 20px;

      & > tr > th {
        position: relative;
        padding: 0.75rem;
        color: $primary;
        background-color: transparent;

        // Fix: don't opacity header when hover, because below it has data when scroll
        &.p-sortable-column {
          &:hover,
          &:focus {
            background: var(--surface-200);
          }
        }

        // Fix border of bottom th disappear when scroll
        &::after {
          content: "";
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -1px;
          border-bottom: inherit;
        }
      }
    }

    .p-datatable-tbody > tr {
      border-bottom: 1px solid $tr-border-color;

      &:last-child {
        border-bottom: 0;
      }

      & > td {
        padding: 0.75rem;

        .p-column-title {
          width: 16rem;
          color: $primary;
          padding-right: 0.2rem;

          @include respond-to(lower-than-small-screens) {
            width: 10rem;
          }
        }

        .default {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
          word-break: break-word;

          span {
            outline: 0;
          }
        }

        &.actions {
          justify-content: flex-end !important;
        }
      }
    }

    &.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover {
      background: var(--surface-200);
    }
  }

  .sticky-header {
    .p-datatable-thead {
      @include sticky;
    }
  }

  .p-column-filter-row p-columnfilterformelement {
    flex: 1 1 auto;
    width: 100%;
  }

  .p-datatable-wrapper {
    overflow-x: auto;
  }

  .header-actions {
    color: $primary;

    &.stacked {
      position: relative;

      .button-title {
        right: 2.2rem;
        bottom: 0.6rem;
      }
    }

    .button-title {
      white-space: nowrap;
      position: absolute;
      right: 3.2rem;
      bottom: 1.2rem;
    }

    .p-button {
      margin-left: auto;

      .p-button-icon {
        font-size: 1.3rem;
        text-align: right;
      }

      &.p-button-icon-only.p-button-rounded {
        display: flex;
        width: unset;
        height: unset;
      }
    }
  }
}

.drag-drop-heading {
  width: 6rem;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.table-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
