@import "theme-variables";

p-fileupload {
  .p-button {
    display: flex;
    align-items: center;
    color: $primary;
    background-color: unset;
  }

  span.p-button-icon {
    font-size: 2rem;
  }

  .p-fileupload-choose:not(.p-disabled):hover {
    background: unset;
    color: unset;
  }

  .p-messages {
    display: none;
  }
}
